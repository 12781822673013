import styled, { css } from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Button from "src/components/Button/Button";
import EventFinished from "src/components/EventFinished";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import DiningCarousel from "src/components/Restaurants/DiningCarousel";
import React from "react";
import TitleSection from "src/components/TitleSection/TitleSection";

const Valentines2025 = () => {
  const { t, i18n } = useTranslation();

  const restaurantSlides = [
    {
      id: 9,
      title: "DD2",
      image:
        "entertainment/variety/valentines_2025/carousel-DD-Nigiri-733x1200.jpg",
      url: t("diningAndLounges.dining.drunkenDragon.link"),
    },
    {
      id: 9,
      title: "DD",
      image:
        "entertainment/variety/valentines_2025/carousel-DD-Meal-733x1200.jpg",
      url: t("diningAndLounges.dining.drunkenDragon.link"),
    },
    {
      id: 11,
      title: "slide1",
      image:
        "entertainment/variety/valentines_2025/carousel-Dessert-733x1200.jpg",
      url: t("diningAndLounges.dining.drunkenDragon.link"),
    },
    {
      id: 33,
      title: "slide3",
      image: "entertainment/variety/valentines_2025/carousel-wine-733x1200.jpg",
      url: t("diningAndLounges.dining.etc.link"),
    },
    {
      id: 10,
      title: "ETC",
      image:
        "entertainment/variety/valentines_2025/carousel-filet-mignon-733x1200.jpg",
      url: t("diningAndLounges.dining.etc.link"),
    },
    {
      id: 10,
      title: "ETC2",
      image:
        "entertainment/variety/valentines_2025/carousel-heart-tartlette-733x1200.jpg",
      url: t("diningAndLounges.dining.etc.link"),
    },
  ];

  return (
    <Div>
      <Hero
        bgImage="entertainment/variety/valentines_2025/PG-Valentines-day-header-2400x800.jpg"
        title={t("valentines2025.title")}
        fluidImage
      />
      <EventFinished
        scheduleKeyName="valentines2025"
        eventType="entertainment"
      />

      <MainContentDiv>
        <container-div>
          <ResponsiveImage
            src={t("valentines2025.logo")}
            alt="valentines-logo-2024"
            customStyles={{ width: "500px", margin: "0 auto", height: "220px" }}
          />
          <TitleSection
            title={t("valentines2025.content.title")}
            text={t("valentines2025.content.date")}
            borderColor="#E19691"
            dark={false}
          />
          <Row className="m-0 p-0 d-flex justify-content-center">
            <Row xs={1} sm={2} className="p-0 mb-4 box">
              <Col className="m-0 p-4 d-flex flex-column justify-content-center">
                <ResponsiveImage
                  className="radius-mask"
                  src={
                    "entertainment/variety/valentines_2025/Elvis-Chapel-508x318.jpg"
                  }
                  alt={"Drunken Dragon"}
                />
              </Col>
              <Col className="m-0 p-4 d-flex flex-column justify-content-center">
                <Row className="m-2">
                  <SubTitleSection
                    subTitle={t("valentines2025.content.elvis.chapel.title")}
                    text={t("valentines2025.content.elvis.chapel.text")}
                    borderColor="#E19691"
                  />
                </Row>
              </Col>
            </Row>
            <Row
              xs={1}
              sm={2}
              className="m-0 mb-4 p-0 d-flex justify-content-center box"
            >
              <Col className="m-0 p-4 d-flex flex-column justify-content-center">
                <Row className="m-2">
                  <SubTitleSection
                    subTitle={t("valentines2025.content.elvis.egd.title")}
                    text={t("valentines2025.content.elvis.egd.text")}
                    borderColor="#E19691"
                  />
                </Row>
              </Col>
              <Col className="m-0 p-4 d-flex flex-column justify-content-center">
                <ResponsiveImage
                  className="radius-mask"
                  src={
                    "entertainment/variety/valentines_2025/Elvis-EGD-508x318.jpg"
                  }
                  alt={"Drunken Dragon"}
                />
              </Col>
            </Row>
            <TitleSection
              as="h3"
              title={t("valentines2025.content.carousel.title")}
              text={t("valentines2025.content.carousel.text")}
              borderColor="#E19691"
              dark={false}
            />
            <div className="radius-mask">
              <DiningCarousel slides={restaurantSlides} />
            </div>
          </Row>
          <TitleSection
            as="h2"
            title={t("valentines2025.content.table.title")}
            text={t("valentines2025.content.table.text")}
            borderColor="#E19691"
            dark={false}
          />
          <Row
            xs={1}
            sm={2}
            className="m-0 mb-4 p-0 d-flex justify-content-center box"
          >
            <Col className="m-0 p-4 d-flex flex-column justify-content-center">
              <ResponsiveImage
                className="radius-mask"
                src={
                  "entertainment/variety/valentines_2025/Drunken-dragon-508x318.jpg"
                }
                alt={"Drunken Dragon"}
              />
            </Col>
            <Col className="m-0 p-4 d-flex flex-column justify-content-center">
              <Row className="m-2">
                <SubTitleSection
                  subTitle={t(
                    "valentines2025.content.table.menu.dd.nigiri.title"
                  )}
                  text={t("valentines2025.content.table.menu.dd.nigiri.text")}
                  borderColor="#E19691"
                />
                <SubTitleSection
                  subTitle={t(
                    "valentines2025.content.table.menu.dd.fillet.title"
                  )}
                  text={t("valentines2025.content.table.menu.dd.fillet.text")}
                  borderColor="#E19691"
                />
                <Button
                  bgColor="var(--white)"
                  color="var(--red)"
                  hoverBgColor="var(--black)"
                  hoverColor="var(--white)"
                  href={t(
                    "diningAndLounges.dining.drunkenDragon.buttons.reserve.link"
                  )}
                  label={t("button.reserve-now")}
                  width="fluid"
                  size="medium"
                />
              </Row>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={2}
            className="m-0 mb-4 p-0 d-flex justify-content-center d-reverse box"
          >
            <Col className="m-0 p-4 d-flex flex-column justify-content-center">
              <ResponsiveImage
                className="radius-mask"
                src={"entertainment/variety/valentines_2025/etc-508x318.jpg"}
                alt={"etc"}
              />
            </Col>
            <Col className="m-0 p-4 d-flex flex-column justify-content-center">
              <Row className="m-0">
                <SubTitleSection
                  subTitle={t(
                    "valentines2025.content.table.menu.etc.tartlet.title"
                  )}
                  text={t("valentines2025.content.table.menu.etc.tartlet.text")}
                  borderColor="#E19691"
                />
              </Row>
              <Row className="m-0">
                <SubTitleSection
                  subTitle={t(
                    "valentines2025.content.table.menu.etc.fillet.title"
                  )}
                  text={t("valentines2025.content.table.menu.etc.fillet.text")}
                  borderColor="#E19691"
                />
                <Button
                  bgColor="var(--white)"
                  color="var(--red)"
                  hoverBgColor="var(--black)"
                  hoverColor="var(--white)"
                  href={t("diningAndLounges.dining.etc.buttons.reserve.link")}
                  label={t("button.reserve-now")}
                  width="fluid"
                  size="medium"
                />
              </Row>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={2}
            className="m-0 p-0 mb-4 d-flex justify-content-center box"
          >
            <Col className="m-0 p-4 d-flex flex-column justify-content-center">
              <ResponsiveImage
                className="radius-mask"
                src={
                  "entertainment/variety/valentines_2025/dessert-508x318.jpg"
                }
                alt={"deserts"}
              />
            </Col>
            <Col className="m-0 p-4 d-flex flex-column justify-content-center">
              <ReactMarkdown>
                {t("valentines2025.content.table.menu.dessert.titleTopText")}
              </ReactMarkdown>
              <SubTitleSection
                subTitle={t("valentines2025.content.table.menu.dessert.title")}
                text={t("valentines2025.content.table.menu.dessert.text")}
                borderColor="#E19691"
              />
            </Col>
          </Row>
        </container-div>
      </MainContentDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: auto;
  color: var(--white);
  h2 {
    font-size: 1.2rem;
  }
  .hero_container {
    min-height: 400px;
  }
  .subtitle-section {
    padding: 0;
    h2 {
      font-size: 1.2rem;
    }
    p {
      padding: 0;
      font-size: 1rem;
    }
  }
  .radius-mask {
    border-radius: 16px;
    & > div {
      padding-top: 0;
    }
  }
  .slick-track {
    border-radius: 24px;
    overflow: hidden;
  }
  .hero_text_container {
    display: none;
  }
  .box {
    background-color: rgb(160, 46, 51);
    border-radius: 24px;
    padding: 0.5rem;
  }
  .title-section {
    ul {
      li {
        text-align: left;
      }
    }
  }
  @media (min-width: 768px) {
    .d-reverse {
      flex-direction: row-reverse;
    }
  }
`;

const MainContentDiv = styled.div`
  /* background: ${(props) => `url(${props.bgImage})`}; */

  /* @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  text-align: left; */
`;

export default Valentines2025;
