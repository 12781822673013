import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import Button from "../components/Button/Button";
import Hero from "../components/Hero/Hero";
import SubTitleSection from "../components/SubTitleSection/SubTitleSection";
import { Row, Col, Collapse } from "react-bootstrap";
import ResponsiveImage from "../components/ImageWrapper/ResponsiveImage";
import PgCard from '../components/Card/PgCard';
import useResponsiveImageUrl from "../utils/useResponsiveImageUrl";
import ReactMarkdown from "react-markdown";

const PlayersGuide = () => {
  const { t } = useTranslation();
  const [more, setMore] = useState("");
  const handleMoreFn = (action) => {
    more !== action ? setMore(action) : setMore("");
  };
  const containerRef = useRef(null);
  const responsiveBgImage = useResponsiveImageUrl("players_guide/players-guide.jpg");

  return (
  <PlayersGuideDiv
    ref={containerRef}
    // bgImage={useResponsiveImageUrl("players_guide/players-guide.jpg")}
  >
    <Hero
      bgColor=""
      bgImage={"players_guide/player-guide-hero-bg.jpg"}
      buttonLink=""
      buttonText=""
      description=""
      descriptionBgColor=""
      image=""
      imageAlt=""
      mainHeroText={t("players-guide.header-one")}
      title=""
    >
      <SubTitleSection subTitle={t("players-guide.header-two")} />
      <container-div>
        <ReactMarkdown>
          {t("players-guide.header-text-one")}
        </ReactMarkdown>
      </container-div>
    </Hero>

    <container-div>
      <ReactMarkdown className="white text-center mt-5">
        {t("players-guide.header-text-two")}
      </ReactMarkdown>
      <Row xs={1} md={2} lg={3} className="g-4 mt-4 mb-5">
        <Col>
          <Link to="#parkingguide">
            <PgCard
              href="#parkingguide"
              image="players_guide/valet_2.jpg"
              imageAlt="Playground Parking"
              title={
                <>
                  {t("players-guide.parking.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>

        <Col>
          <Link to="#playerservicesguide">
            <PgCard
              href="#playerservicesguide"
              image="players_guide/player-services.jpg"
              imageAlt="Playground Player Services"
              title={
                <>
                  {t("players-guide.player-services.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>

        {/* <Col>
          <Link to="#cashierguide">
            <PgCard
              href="#cashierguide"
              image="players_guide/cashier.jpg"
              imageAlt="Playground Cashier"
              title={
                <>
                  {t("players-guide.cashier")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col> */}

        <Col>
          <Link to="#pokerguide">
            <PgCard
              href="#pokerguide"
              image="players_guide/poker.jpg"
              imageAlt="Playground Poker"
              title={
                <>
                  {t("players-guide.poker.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>

        <Col>
          <Link to="#playground-gaming-machines-guide">
            <PgCard
              href="#playground-gaming-machines-guide"
              image="players_guide/gaming-machines.jpg"
              imageAlt="Playground Gaming Machines"
              title={
                <>
                  {t("players-guide.gaming-machines.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>

        <Col>
          <Link to="#restaurant-and-lounges-guide">
            <PgCard
              href="#restaurant-and-lounges-guide"
              image="players_guide/dining.jpg"
              imageAlt="Playground restaurant and lounges"
              title={
                <>
                  {t("players-guide.restaurant-and-lounges.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>

        <Col>
          <Link to="#perks-guide">
            <PgCard
              href="#perks-guide"
              image="players_guide/elevate_2.jpg"
              imageAlt="Player receiving a drink"
              title={
                <>
                  {t("players-guide.perks.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>

        <Col>
          <Link to="#smoking-sections-guide">
            <PgCard
              href="#smoking-sections-guide"
              image="players_guide/smoking-room-3.jpg"
              imageAlt="Smoking section"
              title={
                <>
                  {t("players-guide.smoking-sections.panel-title")} <FaRegArrowAltCircleDown />
                </>
              }
            />
          </Link>
        </Col>
      </Row>
    </container-div>

    <MainContentDiv bgImage={responsiveBgImage}>
      <Row xs={1} md={2} className="g-4" id="parkingguide">
        <Col>
          <ResponsiveImage src="players_guide/valet_2.jpg" alt="Playground Parking" />
        </Col>
        <Col>
          <SubTitleSection subTitle={t("players-guide.parking.title")} />
          <ReactMarkdown>
            {t("players-guide.parking.text")}
          </ReactMarkdown>
        </Col>
      </Row>

      <Row className="g-4" id="playerservicesguide">
        <Col xs={12} md={6}>
          <ResponsiveImage src="players_guide/player-services.jpg" alt="Player Services" />
        </Col>
        <Col xs={12} md={6}>
          <SubTitleSection subTitle={t("players-guide.player-services.title")} />
          <SubTitleSection as={'h3'} subTitle={t("players-guide.player-services.sub-title-1")} />
          <ReactMarkdown>{t("players-guide.player-services.text-1")}</ReactMarkdown>
        </Col>
        <Col xs={12} className="mt-0">
          <SubTitleSection as={'h3'} subTitle={t("players-guide.player-services.sub-title-2")} />
          <ReactMarkdown>{t("players-guide.player-services.text-2")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.player-services.text-3")}</ReactMarkdown>
        </Col>
      </Row>

      <Row className="g-4" id="pokerguide">
        <Col xs={12} md={6}>
          <ResponsiveImage
            src="players_guide/poker.jpg"
            alt="Poker"
          />
        </Col>
        <Col xs={12} md={6}>
          <SubTitleSection subTitle={t("players-guide.poker.title")} />
          <SubTitleSection as={'h3'} subTitle={t("players-guide.poker.subtitle-1")} />
          <ReactMarkdown>{t("players-guide.poker.text-1")}</ReactMarkdown>
        </Col>
        <Col xs={12}>
          <ReactMarkdown>{t("players-guide.poker.text-2")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.poker.text-3")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.poker.text-4")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.poker.text-5")}</ReactMarkdown>
          <SubTitleSection as={'h3'} subTitle={t("players-guide.poker.subtitle-2")} />
          <ReactMarkdown>{t("players-guide.poker.text-6")}</ReactMarkdown>
        </Col>
      </Row>

      <Row className="g-4" id="playground-gaming-machines-guide">
        <Col xs={12} md={6}>
          <ResponsiveImage
            src="players_guide/gaming-machines.jpg"
            alt="Gaming Machines"
          />
        </Col>
        <Col xs={12} md={6}>
          <SubTitleSection subTitle={t("players-guide.gaming-machines.title")} />
          <ReactMarkdown>{t("players-guide.gaming-machines.text-1")}</ReactMarkdown>
        </Col>
        <Col xs={12}>
          <ReactMarkdown>{t("players-guide.gaming-machines.text-2")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.gaming-machines.text-3")}</ReactMarkdown>
        </Col>
      </Row>

      <Row className="g-4" id="restaurant-and-lounges-guide">
        <Col xs={12} md={6}>
          <ResponsiveImage
            src="players_guide/dining.jpg"
            alt="Playground restaurant and lounges"
          />
        </Col>
        <Col xs={12} md={6}>
          <SubTitleSection subTitle={t("players-guide.restaurant-and-lounges.title")} />
          <ReactMarkdown>{t("players-guide.restaurant-and-lounges.text-1")}</ReactMarkdown>
        </Col>
        <Col xs={12}>
          <ReactMarkdown>{t("players-guide.restaurant-and-lounges.text-2")}</ReactMarkdown>
        </Col>
      </Row>

      <Row className="g-4" id="perks-guide">
        <Col xs={12} md={6}>
          <ResponsiveImage
            src="players_guide/elevate_2.jpg"
            alt="Player being served a drink"
          />
        </Col>
        <Col xs={12} md={6}>
          <SubTitleSection subTitle={t("players-guide.perks.title")} />
          <ReactMarkdown>{t("players-guide.perks.text-1")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.perks.text-2")}</ReactMarkdown>
          <ReactMarkdown>{t("players-guide.perks.text-3")}</ReactMarkdown>
        </Col>
      </Row>

      <Row className="g-4" id="smoking-sections-guide">
        <Col xs={12} md={6}>
          <ResponsiveImage
            src="players_guide/smoking-room-3.jpg"
            alt="Designated smoking room"
          />
        </Col>
        <Col xs={12} md={6}>
          <SubTitleSection subTitle={t("players-guide.smoking-sections.title")} />
          <ReactMarkdown>{t("players-guide.smoking-sections.text-1")}</ReactMarkdown>
        </Col>
      </Row>
    </MainContentDiv>
  </PlayersGuideDiv>
  );
};

const PlayersGuideDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: var(--black);
  .card-header {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: var(--red);
      color: var(--white);
      border-bottom-color: var(--white);
      svg {
        color: var(--white);
      }
    }
    svg {
      transition: color 0.3s;
      position: relative;
      bottom: 2px;
      color: var(--red);
    }
  }
`;

const MainContentDiv = styled.div`
  @media (min-width: 1200px) {
    padding-bottom: 3rem;
  }
  height: auto;
  width: 100%;
  color: var(--white);
  background: #000 url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
  background-size: cover;
  .row {
    padding: 3rem 2rem;
    display: flex;
    max-width: 1150px;
    margin: 0 auto;
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.5);
      flex-direction: row-reverse;
    }
    .col {
      > .subtitle-section {
        &:first-of-type {
          @media (min-width: 768px) {
            padding-top: 0;
          }
        }
      }
      button {
        margin: 1.2rem 0;
      }
    }
  }
`;

export default PlayersGuide;
