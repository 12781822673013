import { useReducer, useMemo, useContext, useEffect } from "react";
import { homepageReducer } from "./homepage.reducer";
import { homepageActionTypes as actions } from "./homepage.actions";
import {
  primary_bbj,
  omaha_bbj,
  high_stakes_bbj,
  gaming_machine_jackpots,
} from "../../api/jackpots";
import { live_cash_games, tournaments } from "../../api/poker";
import { HomepageState } from "./homepage.state";

const HOMEPAGE_STATE = {
  menuBarToggle: false,
  circleButton: 0,
  isReadMore: true,
  homeOfPlayground: false,
  homeOfPlaygroundCardImg: "chineseNewYear2025",
  isEnglish: true,
  darkTheme: "",
  pastEventsNav: new Date().getFullYear().toString(),
  pastEventsData: [],
  bbjData: {},
  omahaData: {},
  highstakesData: {},
  gamingMachineJackpotData: {},
  liveGamesData: [],
  tournamentsData: null,
  sectionCategory: "",
  sectionDescription: "",
  tournamentsDetails: null,
  tournamentsSubtabs: 1,
};

export const HomepageStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(homepageReducer, HOMEPAGE_STATE);

  const value = useMemo(
    () => ({
      ...state,
      setMenuBarToggle: (value) => {
        dispatch({ type: actions.MENU_BAR_TOGGLE, payload: value });
      },
      circleButtonFn: (value) => {
        dispatch({ type: actions.CIRCLE_BTN, payload: value });
      },
      isReadMoreFn: () => {
        dispatch({ type: actions.READ_MORE });
      },
      homeOfPlaygroundFn: () => {
        dispatch({ type: actions.HOME_OF_PLAYGROUND });
      },
      homeOfPlaygroundCardImgFn: (value) => {
        dispatch({ type: actions.HOME_OF_PLAYGROUND_CART_IMG, payload: value });
      },
      darkThemeFn: (value) => {
        dispatch({ type: actions.DARK_THEME, payload: value });
      },
      pastEventsNavFn: (value) => {
        dispatch({ type: actions.PAST_EVENTS_NAV, payload: value });
      },
      pastEventsDataFn: (value) => {
        dispatch({ type: actions.PAST_EVENTS_DATA, payload: value });
      },
      bbjDataFn: (value) => {
        dispatch({ type: actions.BBJ_DATA, payload: value });
      },
      omahaDataFn: (value) => {
        dispatch({ type: actions.OMAHA_DATA, payload: value });
      },
      highstakesDataFn: (value) => {
        dispatch({ type: actions.HIGHSTAKES_DATA, payload: value });
      },
      gamingMachineJackpotDataFn: (value) => {
        dispatch({ type: actions.GAMING_MACHINE_JACKPOT_DATA, payload: value });
      },
      liveGamesDataFn: (value) => {
        dispatch({ type: actions.LIVE_GAMES_DATA, payload: value });
      },
      tournamentDataFn: async (start_date, end_date, event_keyword, locale) => {
        try {
          // Function to fetch tournament data
          let data;
          if (start_date && end_date) {
            data = await tournaments(start_date, end_date, event_keyword);
          } else {
            data = null;
          }
          const formattedData = data?.map((event) => ({
            ...event,
            title: event[`name_${locale}`] || event.name_en,
            start: event.start_at,
            classNames: [`type_${event.type}`],
          }));
          dispatch({ type: actions.TOURNAMENTS_DATA, payload: formattedData });
        } catch (error) {
          console.error("Error fetching tournament data:", error);
        }
      },
      tornamentsDetailsFn: (value) => {
        dispatch({ type: actions.TOURNAMENTS_DETAILS, payload: value });
      },
      tournamentsSubtabsFn: (value) => {
        dispatch({ type: actions.TOURNAMENTS_SUBTABS, payload: value });
      },
      sectionCategoryFn: (value) => {
        dispatch({ type: actions.SECTION_CATEGORY, payload: value });
      },
      sectionDescriptionFn: (value) => {
        dispatch({ type: actions.SECTION_DESCRIPTION, payload: value });
      },
    }),
    [state]
  );

  useEffect(() => {
    // Fetch all BBJ-related data
    const fetchBbjData = () => {
      primary_bbj()
        .then((data) => {
          value.bbjDataFn(data);
        })
        .catch((error) => {
          console.error("Error fetching primary BBJ data:", error);
        });
      omaha_bbj()
        .then((data) => {
          value.omahaDataFn(data);
        })
        .catch((error) => {
          console.error("Error fetching Omaha BBJ data:", error);
        });
      high_stakes_bbj()
        .then((data) => {
          value.highstakesDataFn(data);
        })
        .catch((error) => {
          console.error("Error fetching high-stakes BBJ data:", error);
        });
    };

    // Call fetchBbjData immediately on component mount
    fetchBbjData();

    // Fetch BBJ data every 20 minutes (1200000 ms)
    const intervalId = setInterval(fetchBbjData, 1200000);

    // Fetch live cash games data
    live_cash_games()
      .then((data) => {
        value.liveGamesDataFn(data);
      })
      .catch((error) => {
        console.error("Error fetching live cash games data:", error);
      });

    gaming_machine_jackpots()
      .then((data) => {
        value.gamingMachineJackpotDataFn(data);
      })
      .catch((error) => {
        console.error("Error fetching gaming machines jackpot data:", error);
      });

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomepageState.Provider value={value}>{children}</HomepageState.Provider>
  );
};

export const useHomepage = () => useContext(HomepageState);
