import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";


import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const GongXiFaCai = () => {
  const [isActive, setIsActive] = useState("Golden Ticket");
  const { i18n } = useTranslation();
  const lng = i18n.language;
  const goldenTicketRef = useRef(null);
  const handleIsActive = (value) => {
    setIsActive(value);
  };

  useEffect(() => {
    // Check if there is a hash in the URL and update the active tab accordingly
    const hash = window.location.hash.substring(1); // Remove the '#' symbol
    if (hash) {
      handleIsActive("Golden Ticket");
    }
    // Scroll to the Golden Ticket section when the component mounts
    if (goldenTicketRef.current && hash !== "") {
      goldenTicketRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <MainDiv lng={i18n.language}>
      <div></div>
      <div>
        <div>
          <h1>
            {i18n.language === "en"
              ? "Win up to $25,888 in cash every Sunday!"
              : "Gagnez jusqu'à 25 888$ en argent chaque dimanche!"}
          </h1>
          {i18n.language === "en" ? (
            <p>
              Join us{" "}
              <strong>every Sunday, from January 1 to February 25</strong> as we
              say goodbye to the year of the Rabbit and start the{" "}
              <strong>year of the Dragon</strong> by offering all our members a
              chance at wealth and prosperity with{" "}
              <strong>cash prizes ranging from $888 to $25,888</strong> - Gōng
              Xǐ Fā Cái!
            </p>
          ) : (
            <p>
              Rejoignez-nous{" "}
              <strong>tous les dimanches, du 1 janvier au 25 février,</strong>{" "}
              pour dire au revoir à l'année du Lapin et commencer{" "}
              <strong>l'année du Dragon</strong> en offrant à tous nos membres
              une chance de trouver richesse et prospérité avec{" "}
              <strong>des prix en argent allant de 888$ à 25 888$</strong> -
              Gōng Xǐ Fā Cái!
            </p>
          )}
        </div>
      </div>
      <div>
        <div>
          <h2>
            <strong>
              {i18n.language === "en"
                ? "DRAWS EVERY SUNDAY FOR 8 WEEKS!"
                : "TIRAGES CHAQUE DIMANCHE"}
            </strong>
            <br />
            <strong>
              {i18n.language === "en"
                ? "FROM JANUARY 1 - FEBRUARY 18"
                : "DU 1 JANVIER AU 18 FÉVRIER"}
            </strong>
            <br />
            <strong>
              {i18n.language === "en"
                ? "2:00PM - 3:30PM - 5:00PM - 6:30PM - 8:00PM"
                : "14H00 - 15H30 - 17H00 - 18H30 - 20H00"}
            </strong>
          </h2>
        </div>
        <div>
          <ResponsiveImage
            src={
              i18n.language === "en"
                ? "promotions/chinese-new-year-lp_03.png"
                : "promotions/chinese-new-year-lp_03_fr.png"
            }
            alt="chinese-new-year-lp_03"
          />
          <ResponsiveImage
            src={
              i18n.language === "en"
                ? "promotions/chinese-new-year-lp_05.png"
                : "promotions/chinese-new-year-lp_05_fr.png"
            }
            alt="chinese-new-year-lp_05"
          />
          <ResponsiveImage
            src={`promotions/105-winner-gxfc-${lng}.png`}
            alt="105-winner-gxfc-en"
          />
        </div>
        <h2>
          {i18n.language === "en"
            ? "MATCH 3 LUCKY SYMBOLS AND WIN CASH!"
            : "FAITES CORRESPONDRE 3 SYMBOLES ET GAGNEZ DE L'ARGENT!"}
        </h2>
        <div>
          <div>
            <ResponsiveImage
              src="promotions/Cards-7x5-dragon.jpg"
              alt="Cards-7x5-dragon"
            />
            {i18n.language === "en" ? (
              <span>$25,888</span>
            ) : (
              <span>25,888$</span>
            )}
          </div>
          <div>
            <ResponsiveImage
              src="promotions/Cards-7x5-gold-coin.png"
              alt="Cards-7x5-gold-coin"
            />
            {i18n.language === "en" ? (
              <span>$25,888</span>
            ) : (
              <span>25,888$</span>
            )}
          </div>
          <div>
            <ResponsiveImage
              src="promotions/Cards-7x5-laughing-bouddha.png"
              alt="Cards-7x5-laughing-bouddha"
            />
            {i18n.language === "en" ? (
              <span>$25,888</span>
            ) : (
              <span>25,888$</span>
            )}
          </div>
          <div>
            <ResponsiveImage
              src="promotions/Cards-7x5-orange-tree.png"
              alt="Cards-7x5-orange-tree"
            />
            {i18n.language === "en" ? <span>$8,888</span> : <span>8,888$</span>}
          </div>
          <div>
            <ResponsiveImage
              src="promotions/Cards-7x5-eight.png"
              alt="Cards-7x5-eight"
            />
            {i18n.language === "en" ? <span>$2,888</span> : <span>2,888$</span>}
          </div>
          <div>
            <ResponsiveImage
              src="promotions/Cards-7x5-fu.png"
              alt="Cards-7x5-fu"
            />
            {i18n.language === "en" ? <span>$888</span> : <span>888$</span>}
          </div>
        </div>
        {i18n.language === "en" ? (
          <div>
            <h1>
              Gōng Xǐ Fā Cái Finale <br />
              <span>Sunday, February 25</span>
            </h1>
            <p>
              25 winners with{" "}
              <strong>$28,888 in guaranteed cash prizes!</strong>
            </p>
            <p>
              All <strong>Golden Ticket holders</strong> will take part in the
              final raffle. Draws will be conducted{" "}
              <strong>every 30 minutes</strong> between{" "}
              <strong>1:00pm and 9:00pm</strong>, for a total of 17 winners.
            </p>
            <p>
              We’ll also conduct <strong>1 Hot Seat draw an hour</strong> from{" "}
              <strong>1:30pm - 8:30pm</strong>, for an additional *8 winners.
            </p>
            <p>
              *Included in the above mentioned Hot Seat draws are 3 Black tier
              specific draws at 1:30pm, 4:30pm and 8:30pm.
            </p>
            <p>
              Once the final raffle and Hot Seat draws are done at 9:00pm,{" "}
              <strong>
                all 25 winners will make their way to the Gōng Xǐ Fā Cái game
                board
              </strong>
              , where they each select one of 25 envelopes. Envelopes get
              selected one at a time,{" "}
              <strong>
                in the same order names were called throughout the day
              </strong>
              . Winners will then open their envelopes to reveal their prize.
              Each contains one of the following prizes:
            </p>
            <div>
              <h3>
                13X <br />
                <span>$188</span>
              </h3>
              <h3>
                2X <br />
                <span>$782</span>
              </h3>
              <h3>
                8X <br />
                <span>$888</span>
              </h3>
              <h3>
                2X <br />
                <span>$8,888</span>
              </h3>
            </div>
          </div>
        ) : (
          <div>
            <h1>
              La Grande Finale de Gōng Xǐ Fā Cái <br />
              <span>Dimanche, 25 février</span>
            </h1>
            <p>
              25 gagnants avec{" "}
              <strong>28 888$ de prix en argent garantis!</strong>
            </p>
            <p>
              Tous les <strong>détenteurs de Tickets d'Or</strong> participeront
              au tirage final. Les tirages ont lieu{" "}
              <strong>toutes les 30 minutes</strong> entre{" "}
              <strong>13h00 et 21h00</strong>, pour un total de 17 gagnants.
            </p>
            <p>
              Nous effectuerons également un{" "}
              <strong>tirage au sort par Siège Chanceux</strong> from{" "}
              <strong>13h30 et 20h30</strong>, pour *8 gagnants supplémentaires.
            </p>
            <p>
              *Parmi les tirages mentionnés ci-dessus, il y a 3 tirages
              spécifiquement destinés aux joueurs du niveau noir, à 13h30, 16h30
              et 20h30.
            </p>
            <p>
              Une fois les derniers tirages effectués à 21h00,{" "}
              <strong>
                les 25 gagnants se rendront sur le plateau de jeu du Gōng Xǐ Fā
                Cái
              </strong>
              , où ils choisiront chacun une des 25 enveloppes. Les enveloppes
              sont sélectionnées une par une,{" "}
              <strong>
                dans l'ordre où les noms ont été appelés tout au long de la
                journée
              </strong>
              . Les gagnants ouvriront ensuite leurs enveloppes pour révéler
              leur prix. Chacune contient l'un des prix suivants:
            </p>
            <div>
              <h3>
                13X <br />
                {i18n.language === "en" ? <span>$188</span> : <span>188$</span>}
              </h3>
              <h3>
                2X <br />
                {i18n.language === "en" ? <span>$782</span> : <span>782$</span>}
              </h3>
              <h3>
                8X <br />
                {i18n.language === "en" ? <span>$888</span> : <span>888$</span>}
              </h3>
              <h3>
                2X <br />
                {i18n.language === "en" ? (
                  <span>$8,888</span>
                ) : (
                  <span>8,888$</span>
                )}
              </h3>
            </div>
          </div>
        )}
      </div>
      <PartFour>
        <ul>
          <li
            onClick={() => handleIsActive("Golden Ticket")}
            className={isActive === "Golden Ticket" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Golden Ticket" : "Ticket d’Or"}
          </li>
          <li
            onClick={() => handleIsActive("How to Qualify")}
            className={isActive === "How to Qualify" ? "active" : undefined}
          >
            {i18n.language === "en" ? "How to Qualify" : "Comment se Qualifier"}
          </li>
          <li
            onClick={() => handleIsActive("Draw Process")}
            className={isActive === "Draw Process" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Draw Process" : "Tirages - Procédures"}
          </li>
          <li
            onClick={() => handleIsActive("Game Description")}
            className={isActive === "Game Description" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Game Description" : "Description du jeu"}
          </li>
          <li
            onClick={() => handleIsActive("Eligibility")}
            className={isActive === "Eligibility" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Eligibility" : "Admissibilité"}
          </li>
          <li
            onClick={() => handleIsActive("General Rules")}
            className={isActive === "General Rules" ? "active" : undefined}
          >
            {i18n.language === "en" ? "General Rules" : "Règles Générales"}
          </li>
        </ul>
        <div>
          {isActive === "Golden Ticket" ? (
            <GoldenTicket
              ref={goldenTicketRef}
              bgImage={useResponsiveImageUrl(
                `promotions/gxfc_2024_header_${lng}.jpg`
              )}
            >
              {i18n.language === "en" ? (
                <>
                  <ResponsiveImage
                    src="promotions/gxfc-gt-en.png"
                    alt="gxfc-gt-en"
                  />
                  <h2>
                    Golden tickets are your key to playing the $28,888
                    <br />
                    Gōng Xǐ Fā Cái Finale!
                  </h2>
                  <div>
                    <h3>How can you get your hands on one?</h3>
                    <ol>
                      Players can collect Golden Tickets the following ways:
                      <li>
                        Golden Tickets are awarded to all players who get called
                        to play Gōng Xǐ Fā Cái on any of the draw days.
                      </li>
                      <li>
                        Golden Tickets are awarded, along with $50 in Free Play
                        via Hot Seat draw, every Thursday from January 4 to
                        February 15, at a rate of 2 per hour from 11:00am to
                        11:00pm.
                      </li>
                      <li>
                        Golden Tickets are awarded via Hot Seat draw, every day
                        between February 19 and February 23, at a rate of 2 per
                        hour from 11:00am to 11:00pm.
                      </li>
                      <li>
                        Golden Tickets can also be randomly awarded by floor
                        staff.
                      </li>
                    </ol>
                  </div>
                </>
              ) : (
                <>
                  <ResponsiveImage
                    src="promotions/gxfc-gt-fr.png"
                    alt="gxfc-gt-fr"
                  />
                  <h2>
                    Les Tickets d’Or vous donneront la chance de participer à la
                    Finale à 28 888$ de
                    <br />
                    Gōng Xǐ Fā Cái.
                  </h2>
                  <div>
                    <h3>Comment pouvez-vous mettre la main sur l’un d’eux?</h3>
                    <ol>
                      Les joueurs peuvent en acquérir de quatre façons:
                      <li>
                        Un Ticket d’Or est décerné à chaque joueur sélectionné
                        pour jouer à Gōng Xǐ Fā Cái.
                      </li>
                      <li>
                        Des Tickets d’Or, ainsi que 50$ en crédits-jeu, sont
                        décernés par tirage Siège Chanceux, chaque jeudi entre
                        le 4 janvier et le 15 février, à raison de 2 par heure
                        de 11h00 à 23h00.
                      </li>
                      <li>
                        Des Tickets d’Or sont décernés par tirage Siège
                        Chanceux, tous les jours entre le 19 et le 23 février, à
                        raison de 2 par heure de 11h00 à 23h00.
                      </li>
                      <li>
                        Des Tickets d’Or peuvent être remis par le personnel de
                        façon aléatoire.
                      </li>
                    </ol>
                  </div>
                </>
              )}
            </GoldenTicket>
          ) : null}
          {isActive === "How to Qualify" ? (
            i18n.language === "en" ? (
              <div>
                <h2>How to Qualify</h2>
                <h3>Gōng Xǐ Fā Cái</h3>
                <p>
                  There are two (2) ways to participate in this promotion, as
                  detailed in rules 1 and 3.
                </p>
                <ol>
                  <li>
                    Players receive one (1) draw entry for every two hundred and
                    fifty (250) Rewards Points earned while playing gaming
                    machines.
                  </li>
                  <li>
                    After every gaming session, players must present themselves
                    to one of the cashiers, and request to have their entries
                    printed. Players then need to place their entries inside the
                    Gōng Xǐ Fā Cái raffle drum located on the gaming machine
                    gaming floor.
                  </li>
                  <li>
                    Red tier and Black tier members also received one (1)
                    electronic draw entry for every two hundred and fifty (250)
                    Rewards Points earned while playing gaming machines.
                  </li>
                  <li>
                    Players may also purchase entries for two hundred and fifty
                    (250) points.
                  </li>
                  <li>
                    Draw entries are only available for collection on the day
                    they were earned.
                  </li>
                  <li>
                    For every draw day, players have up to one (1) week to earn
                    raffle entries.
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Earning Period</th>
                            <th>Draw Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>January 1 at 5:00am - January 7 at 7:55pm</td>
                            <td>January 7 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>January 7 at 7:56pm - January 14 at 7:55pm</td>
                            <td>January 14 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>January 14 at 7:56pm - January 21 at 7:55pm</td>
                            <td>January 21 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>January 21 at 7:56pm - January 28 at 7:55pm</td>
                            <td>January 28 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>January 28 at 7:56pm - February 4 at 7:55pm</td>
                            <td>February 4 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>
                              February 4 at 7:56pm - February 11 at 7:55pm
                            </td>
                            <td>February 11 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>
                              February 11 at 7:56pm - February 18 at 7:55pm
                            </td>
                            <td>February 18 (15 draws)</td>
                          </tr>
                          <tr>
                            <td>
                              February 18 at 7:56pm - February 25 at 7:55pm
                            </td>
                            <td>February 25 (15 draws)</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                  <li>
                    To be part of a draw, a ballot must be deposited into the
                    designated draw drum by the participant before the
                    corresponding earning period ends.
                  </li>
                </ol>
                <h3>$28,888 Gōng Xǐ Fā Cái Finale</h3>
                <ol>
                  <li value={8}>
                    Players collect Golden Tickets the following ways:
                    <ol type="a">
                      <li>
                        Golden Tickets are awarded to all players who get called
                        to play gōng xǐ fā cái on any of the draw days.
                      </li>
                      <li>
                        Golden Tickets are awarded, along with $50 in Free Play
                        via Hot Seat draw, every Thursday from January 4 and
                        February 15, at a rate of 2 per hour from 11:00am to
                        11:00pm.
                      </li>
                      <li>
                        Golden Tickets are awarded via Hot Seat draw, every day
                        between February 19 and February 23, at a rate of 2 per
                        hour from 11:00am to 11:00pm.
                      </li>
                      <li>
                        Golden Tickets can also be randomly awarded by floor
                        staff.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Comment se Qualifier</h2>
                <h3>Gōng Xǐ Fā Cái</h3>
                <p>
                  Il y a deux (2) façons de participer à cette promotion, comme
                  stipulé dans les règles 1 et 3.
                </p>
                <ol>
                  <li>
                    Les joueurs reçoivent une (1) participation aux tirages pour
                    chaque deux cent cinquante (250) Points Récompenses
                    accumulés en jouant aux machine de jeux.
                  </li>
                  <li>
                    Après chaque session de jeu, les joueurs doivent se
                    présenter au comptoir des Récompenses aux Joueurs ou à la
                    caisse machine de jeux pour faire imprimer leurs bulletins
                    de participation. Ils doivent ensuite les placer eux-mêmes
                    dans le tambour de la tombola Gōng Xǐ Fā Cái situé à l'étage
                    des machines de jeu.
                  </li>
                  <li>
                    Les membres des niveaux Rouge et Noir reçoivent également
                    une (1) participation au tirage électronique pour chaque
                    tranche de deux cent cinquante (250) points de récompense
                    gagnés en jouant aux machines de jeu.
                  </li>
                  <li>
                    Les joueurs peuvent aussi acheter des bulletins de
                    participation au coût de deux cent cinquante (250) Points
                    Récompenses.
                  </li>
                  <li>
                    Les participations doivent être réclamées le jour même de la
                    session de jeu correspondante.
                  </li>
                  <li>
                    Pour chaque jour de tirage, les joueurs ont jusqu’à une (1)
                    semaine pour accumuler des participations.
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Période d’accumulation</th>
                            <th>Jour de tirage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1 janvier à 5h00 - 7 janvier à 19h55</td>
                            <td>7 janvier (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>7 janvier à 19h56 - 14 janvier à 19h55</td>
                            <td>14 janvier (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>14 janvier à 19h56 - 21 janvier à 19h55</td>
                            <td>21 janvier (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>21 janvier à 19h56 - 28 janvier à 19h55</td>
                            <td>28 janvier (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>28 janvier à 19h56 - 4 février à 19h55</td>
                            <td>4 février (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>4 février à 19h56 - 11 février à 19h55</td>
                            <td>11 février (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>11 février à 19h56 - 18 février à 19h55</td>
                            <td>18 février (15 tirages)</td>
                          </tr>
                          <tr>
                            <td>18 février à 19h56 - 25 février à 19h55</td>
                            <td>25 février (15 tirages)</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                  <li>
                    Pour faire partie d’un tirage, un bulletin de participation
                    doit être déposé dans le tambour de tirage désigné par le
                    participant avant que la période d’accumulation
                    correspondante ne soit terminée.
                  </li>
                </ol>
                <h3>Finale à 28 888$ de Gōng Xǐ Fā Cái</h3>
                <ol>
                  <li value={8}>
                    Les joueurs peuvent en acquérir de quatre façons:
                    <ol type="a">
                      <li>
                        Un Ticket d’Or est décerné à chaque joueur sélectionné
                        pour jouer à Gōng Xǐ Fā Cái.
                      </li>
                      <li>
                        Des Tickets d’Or, ainsi que 50$ en crédits-jeu, sont
                        décernés par tirage Siège Chanceux, chaque jeudi entre
                        le 4 janvier et le 15 février, à raison de 2 par heure
                        de 11h00 à 23h00.
                      </li>
                      <li>
                        Des Tickets d’Or sont décernés par tirage Siège
                        Chanceux, tous les jours entre le 19 et le 23 février, à
                        raison de 2 par heure de 11h00 à 23h00.
                      </li>
                      <li>
                        Des Tickets d’Or peuvent être remis par le personnel de
                        façon aléatoire.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "Draw Process" ? (
            i18n.language === "en" ? (
              <div>
                <h2>Draw Process</h2>
                <h3>Gōng Xǐ Fā Cái</h3>
                <ol>
                  <li>
                    There are a total of hundred and five (105) prizes available
                    to be won via this promotion.
                  </li>
                  <li>
                    Draws are conducted five times per draw day, at 2:00pm,
                    3:30pm, 5:00pm, 6:30pm and 8:00pm, on the following days:
                    <ol type="a">
                      <li>Sunday January 7, 2024</li>
                      <li>Sunday January 14, 2024</li>
                      <li>Sunday January 21, 2024</li>
                      <li>Sunday January 28, 2024</li>
                      <li>Sunday February 4, 2024</li>
                      <li>Sunday, February 11, 2024</li>
                      <li>Sunday, February 18, 2024</li>
                    </ol>
                  </li>
                  <li>
                    Three (3) contestants are picked at each draw time, for a
                    total of fifteen (15) contestants per draw day, via the
                    following methods:
                    <ol type="a">
                      <li>
                        Two (2) contestants will be selected from the raffle
                        drum, as per rules 1-4 in the How to Qualify section.
                      </li>
                      <li>
                        For the draws held at 2:00pm, 5:00pm and 8:00pm, one (1)
                        contestant will be selected by electronic raffle among{" "}
                        <strong>Black</strong> tier members.
                      </li>
                      <li>
                        For the draws held at 3:30pm and 6:30pm, one (1)
                        contestant will be selected by electronic raffle among{" "}
                        <strong>Red</strong> tier members.
                      </li>
                      <li>
                        To be eligible for their tier specific electronic draws,{" "}
                        <strong>Black and Red</strong> tier members are required
                        to check-in prior to the scheduled draw time. Check-in
                        is done by earning at least twenty five (25) Rewards
                        Points points, on any gaming machine, within the 30
                        minute window prior to the draw. The periods are as
                        follows: 1:30pm to 2:00pm, 3:00pm to 3:30 pm, 4:30pm to
                        5:00pm, 6:00pm to 6:30pm and 7:30pm to 8:00pm.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>$28,888 gōng xǐ fā cái Finale</h3>
                <ol>
                  <li value={4}>
                    There are a total of twenty five (25) prizes to be won via
                    this this promotion.
                  </li>
                  <li value={5}>
                    Draws will be conducted every thirty (30) minutes between
                    1:00pm and 9:00pm, from a drum containing all the Golden
                    Ticket winners, for a total of seventeen (17) winners.
                  </li>
                  <li value={6}>
                    Hot Seat draws will be conducted every hour between 1:30pm -
                    8:30pm for an additional eight (8) winners.
                  </li>
                  <li value={7}>
                    Hot Seat draws at 1:30pm, 4:30pm and 8:30pm will be held
                    exclusively for Black tier members.
                  </li>
                  <li value={8}>
                    If there are no Black tier members on site at the time of
                    their specific draws, a Hot Seat draw will be conducted from
                    all active gaming machines players.
                  </li>
                  <li value={9}>
                    The game will be played at 9:05pm on Sunday February 25,
                    2024.
                  </li>
                </ol>
                <h3>General</h3>
                <ol>
                  <li value={10}>
                    Winners must be in attendance at the time of the draw to
                    win. The selected names are announced over Playground’s
                    internal public address system throughout the gaming floors
                    in the Playground Complex. The selected names have two (2)
                    minutes from the time of the announcement to present
                    themselves at the promotions area to participate.
                  </li>
                  <li value={11}>
                    If, at the end of the above-mentioned time interval, an
                    eligible selectee has not presented themselves at the
                    designated area, a site representative draws one (1)
                    additional ballot from the same raffle drum. This process
                    continues until an eligible selectee is present.
                  </li>
                  <li value={12}>
                    At the February 25 Finale, all draw winners will be given
                    five (5) minutes to present themselves to the game area or
                    confirm their presence with a floor representative, before
                    making their way to the game area, after having their name
                    called. If they have not shown up within this period, they
                    shall be ineligible to play the Finale and we will select
                    two (2) winners at the following draw time, and so on
                    throughout the day.
                  </li>
                  <li value={13}>
                    In the event that a draw winner fails to present themselves
                    to the game area at the designated game time, a two (2)
                    minute window will be issued. If the draw winner still
                    cannot be found, a new name will be selected via Hot Seat
                    draw.
                  </li>
                  <li value={14}>
                    Guests are eligible to win once per draw day but still
                    receive a Golden Ticket for every time they are called up.
                  </li>
                  <li value={15}>
                    The chance of being selected in any draw is dependent on the
                    number of entries received for that draw.
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Processus de Tirage</h2>
                <h3>Gōng Xǐ Fā Cái</h3>
                <ol>
                  <li>
                    Cette promotion comporte cent cinq (105) prix à gagner au
                    total.
                  </li>
                  <li>
                    Les tirages ont lieu cinq (5) fois par jour de tirage, à
                    14h00, 15h30, 17h00, 18h30 et 20h00. Jours de tirage:
                    <ol type="a">
                      <li>Dimanche 7 janvier 2024</li>
                      <li>Dimanche 14 janvier 2024</li>
                      <li>Dimanche 21 janvier 2024</li>
                      <li>Dimanche 28 janvier 2024</li>
                      <li>Dimanche 4 février 2024</li>
                      <li>Dimanche 11 février 2024</li>
                      <li>Dimanche 18 février 2024</li>
                    </ol>
                  </li>
                  <li>
                    Trois (3) concurrents sont sélectionnés à chacune des heures
                    mentionnées ci-dessus, pour un total de quinze (15)
                    concurrents par jour de tirage, à l’aide des méthodes
                    suivantes:
                    <ol type="a">
                      <li>
                        Deux (2) concurrents seront pigés à partir du tambour de
                        la tombola, conformément aux règles 1 à 4 de la section
                        Comment se qualifier.
                      </li>
                      <li>
                        Pour les tirages au sort effectués à 14h00, 17h00 et
                        20h00, un (1) participant sera sélectionné par tirage au
                        sort électronique parmi les membres de niveau{" "}
                        <strong>Noir.</strong>
                      </li>
                      <li>
                        Pour les tirages au sort de 15h30 et 18h30, un (1)
                        participant sera sélectionné par tirage au sort
                        électronique parmi les membres du niveau{" "}
                        <strong>Rouge.</strong>
                      </li>
                      <li>
                        Pour être admissibles aux tirages électroniques
                        spécifiques à leur niveau, les membres des niveaux{" "}
                        <strong>Noir et Rouge</strong> doivent s'enregistrer
                        avant l'heure prévue du tirage. L'enregistrement
                        s'effectue en accumulant au moins vingt-cinq (25) points
                        de récompense, sur n'importe quel machine de jeux, dans
                        la période de 30 minutes précédant le tirage. Les
                        périodes sont les suivantes 13h30 à 14h00, 15h00 à
                        15h30, 16h30 à 17h00, 18h00 à 18h30 et 19h30 à 20h00.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h3>Finale à 28 888$ de Gōng Xǐ Fā Cái</h3>
                <ol>
                  <li value={4}>
                    Cette promotion prévoit un total de vingt-cinq (25) prix à
                    gagner.
                  </li>
                  <li value={5}>
                    Les tirages seront effectués toutes les trente (30) minutes
                    entre 13h00 et 21h00 à partir d’un tambour contenant les
                    noms de tous les gagnants de Tickets d’Or, pour un total de
                    dix-sept (17) gagnants.
                  </li>
                  <li value={6}>
                    Des tirages au sort par Siège Chanceux seront effectués
                    toutes les heures entre 13h30 et 20h30 pour huit (8)
                    gagnants supplémentaires.
                  </li>
                  <li value={7}>
                    Les tirages au sort par Siège Chanceux à 13h30, 16h30 et
                    20h30 seront exclusivement réservés aux membres du niveau
                    Noir.
                  </li>
                  <li value={8}>
                    S'il n'y a pas de membres du niveau Noir sur place au moment
                    de leur tirage au sort spécifique, un tirage par Siège
                    Chanceux sera effectué parmi tous les joueurs actifs de
                    machine de jeux.
                  </li>
                  <li value={9}>
                    La partie elle-même sera jouée le dimanche 25 février 2024 à
                    21h05.
                  </li>
                </ol>
                <h3>General</h3>
                <ol>
                  <li value={10}>
                    Les gagnants doivent être présents au moment du tirage pour
                    gagner. Les noms sélectionnés sont annoncés par le biais du
                    système de sonorisation dans l'ensemble des salles de jeu du
                    Complexe Playground. Afin de participer, le gagnant dispose
                    de deux (2) minutes à compter de l'annonce pour se présenter
                    à l'aire de jeu.
                  </li>
                  <li value={11}>
                    Si, à la fin de la période mentionnée ci-dessus, un gagnant
                    admissible sélectionné ne s'est pas présenté à l’aire de
                    jeu, un représentant pige un (1) bulletin de participation
                    supplémentaire du même tambour. Ce processus se poursuit
                    jusqu'à ce qu'un gagnant admissible soit présent.
                  </li>
                  <li value={12}>
                    À la finale du 25 février, tous les joueurs sélectionnés
                    disposent de cinq (5) minutes pour se présenter à l'aire de
                    jeu ou confirmer leur présence auprès d'un représentant de
                    l'étage, avant de se rendre dans l'aire de jeu. Si, à la fin
                    de cette période, un joueur sélectionné ne s’est toujours
                    pas présenté, ce joueur ne pourra pas participer et le
                    personnel sélectionne deux (2) noms à l’heure de tirage
                    suivante, et ainsi de suite tout au long de la journée.
                  </li>
                  <li value={13}>
                    Si un joueur sélectionné s’est bel et bien présenté au
                    bureau du Superviseur après que son nom ait été pigé, mais
                    ne se présente pas à l’aire de jeu à temps pour participer à
                    la Finale, un sursis de deux (2) minutes est accordé. Si ce
                    joueur ne s’est toujours pas présenté à la fin de cette
                    période, un tirage Siège Chanceux décidera de son
                    remplaçant.
                  </li>
                  <li value={14}>
                    Les joueurs ne peuvent gagner plus d’une fois par jour de
                    tirage, mais reçoivent tout de même un Ticket d’Or chaque
                    fois qu’ils sont appelés.
                  </li>
                  <li value={15}>
                    Pour chaque tirage, les chances d’être sélectionné dépendent
                    du nombre de participations accumulées.
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "Game Description" ? (
            i18n.language === "en" ? (
              <div>
                <h2>Game Description</h2>
                <ol>
                  <li>
                    The <strong>Gōng Xǐ Fā Cái</strong> game board contains
                    twenty five (25) red envelopes.
                  </li>
                  <li>
                    Each envelope contains a symbol, representing a specific
                    prize. The symbols are:
                    <ol type="a">
                      <li>3 x Dragons</li>
                      <li>3 x Gold coins</li>
                      <li>3 x Laughing Buddhas</li>
                      <li>4 x Orange trees</li>
                      <li>5 x Number 8</li>
                      <li>7 x 福 “fu”</li>
                    </ol>
                  </li>
                  <li>
                    For each game, the contestant will select five (5) of the
                    twenty five (25) envelopes from the game board, one at a
                    time, and the host will open the corresponding envelopes. If
                    the player uncovers three (3) matching symbols, he/she will
                    win the corresponding prize.
                  </li>
                  <li>
                    If the player does not uncover three (3) matching symbols,
                    he/she is given the following options:
                    <ol type="a">
                      <li>Return 2 symbols and select another envelope, or:</li>
                      <li>
                        Take $288 in free-play.{" "}
                        <strong>
                          (The offer is $488 if the player’s tier status is
                          Black)
                        </strong>
                      </li>
                    </ol>
                  </li>
                  <li>
                    If the player selects option 4a and now has three (3)
                    matching symbols, he/she will win the corresponding prize.
                    If the player still does not have three (3) matching symbols
                    he/she is given the following options:
                    <ol type="a">
                      <li>Select one final envelope, or:</li>
                      <li>
                        Take $188 in free-play{" "}
                        <strong>
                          (The offer is $288 if the player’s tier status is
                          Black)
                        </strong>
                      </li>
                    </ol>
                  </li>
                  <li>
                    If the player selects option 5a and now has three (3)
                    matching symbols, he/she will win the corresponding prize.
                    If the player still does not have three (3) matching
                    symbols, the game ends and the player walks away with $88 in
                    free-play.{" "}
                    <strong>($188 if the player’s tier status is Black)</strong>
                  </li>
                  <li>
                    Prizing is as follows:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>3 matching symbols</th>
                            <th>Prize</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Dragon</td>
                            <td>$25,888</td>
                          </tr>
                          <tr>
                            <td>Gold Coins</td>
                            <td>$25,888</td>
                          </tr>
                          <tr>
                            <td>Laughing Buddha</td>
                            <td>$25,888</td>
                          </tr>
                          <tr>
                            <td>Orange tree</td>
                            <td>$8,888</td>
                          </tr>
                          <tr>
                            <td>Number “8”</td>
                            <td>$2,588</td>
                          </tr>
                          <tr>
                            <td>“fú” (福)</td>
                            <td>$888</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
                <h3>$28,888 Gōng Xǐ Fā Cái Finale</h3>
                <ol start="8">
                  <li>
                    The Gōng Xǐ Fā Cái Finale game board contains twenty five
                    (25) red envelopes.
                  </li>
                  <li>Each envelope contains 1 of 4 prizes, listed below.</li>
                  <li>
                    For this game, the 25 Golden Ticket draw winners will
                    present themselves at the Gōng Xǐ Fā Cái game board at
                    9:00pm, on Sunday February 25, 2024.
                  </li>
                  <li>
                    Each winner will select one of <strong>25 envelopes</strong>
                    . Envelopes will be selected one at a time,{" "}
                    <strong>
                      over the course of 5 rounds in groups of 5 winners, in the
                      same order names were drawn throughout the day, and then
                      the 5 selected envelopes are opened simultaneously.
                    </strong>{" "}
                    This process is then repeated until all winners have
                    selected and opened their envelopes. Each contains one of
                    the following:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Prize</th>
                            <th>Amount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>$188</td>
                            <td>12</td>
                            <td>$2,444</td>
                          </tr>
                          <tr>
                            <td>$782</td>
                            <td>2</td>
                            <td>$1,564</td>
                          </tr>
                          <tr>
                            <td>$888</td>
                            <td>8</td>
                            <td>$7,104</td>
                          </tr>
                          <tr>
                            <td>$8,888</td>
                            <td>2</td>
                            <td>$17,776</td>
                          </tr>
                          <tr className="tr-red">
                            <td>Total</td>
                            <td>25</td>
                            <td>$28,888</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Description du jeu</h2>
                <ol>
                  <li>
                    Le plateau de jeu de <strong>Gōng Xǐ Fā Cái</strong>{" "}
                    contient vingt-cinq (25) enveloppes rouge.
                  </li>
                  <li>
                    Chaque enveloppe contient un symbole, représentant un prix
                    bien spécifique. Les symboles sont:
                    <ol type="a">
                      <li>3 x Dragon</li>
                      <li>3 x Pièce d'or</li>
                      <li>3 x Bouddha rieur</li>
                      <li>4 x Oranger</li>
                      <li>5 x Numéro "8"</li>
                      <li>7 x “fú” (福)</li>
                    </ol>
                  </li>
                  <li>
                    Pour chaque jeu, le participant choisira cinq (5) parmi les
                    vingt-cinq (25) enveloppes sur le plateau de jeu, un à la
                    fois, et l'animateur ouvrira les enveloppes correspondantes.
                    Si le joueur découvre trois (3) symboles identiques, il
                    gagne le prix qui correspond.
                  </li>
                  <li>
                    Si le joueur ne découvre pas trois (3) symboles identiques,
                    il a les options suivantes:
                    <ol type="a">
                      <li>
                        Retournez 2 symboles et sélectionnez une autre
                        enveloppe, ou:
                      </li>
                      <li>
                        Choisir 288$ en crédits jeux.{" "}
                        <strong>
                          (Membres du niveau Noir: choisir 488$ en crédits jeux)
                        </strong>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Si le joueur choisit l'option 4a et a maintenant trois (3)
                    symboles identiques, il gagne le prix qui correspond. Si le
                    joueur n'a toujours pas trois (3) symboles identiques, il a
                    le choix entre les options suivantes:
                    <ol type="a">
                      <li>Sélectionnez une dernière enveloppe, ou:</li>
                      <li>
                        Choisir 188$ en crédits jeux.{" "}
                        <strong>
                          (Membres du niveau Noir: choisir 288$ en crédits jeux)
                        </strong>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Si le joueur choisit l'option 5a et a maintenant trois (3)
                    symboles identiques, il gagne le prix correspondant. Si le
                    joueur n'a toujours pas trois (3) symboles identiques, le
                    jeu se termine et le membre gagne 88$ en crédits jeux.{" "}
                    <strong>
                      (Les membres du niveau Noir 188$ en crédits jeux)
                    </strong>
                  </li>
                  <li>
                    Voici le tableau des prix:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>3 symboles identiques</th>
                            <th>Prix</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Dragon</td>
                            <td>25 888$</td>
                          </tr>
                          <tr>
                            <td>Pièce d'or</td>
                            <td>25 888$</td>
                          </tr>
                          <tr>
                            <td>Bouddha rieur</td>
                            <td>25 888$</td>
                          </tr>
                          <tr>
                            <td>Oranger</td>
                            <td>8888$</td>
                          </tr>
                          <tr>
                            <td>Numéro "8"</td>
                            <td>2588$</td>
                          </tr>
                          <tr>
                            <td>“fú” (福)</td>
                            <td>$888</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
                <h3>Finale à 28 888$ de Gōng Xǐ Fā Cái</h3>
                <ol start="8">
                  <li>
                    Le plateau de jeu de la Finale de Gōng Xǐ Fā Cái contient
                    vingt-cinq (25) enveloppes.
                  </li>
                  <li>
                    Chaque enveloppe contient 1 des 4 prix indiqués ci-dessous.
                  </li>
                  <li>
                    Pour ce jeu, les 25 gagnants des tirages au sort de Tickets
                    d'Or se présenteront au plateau de jeu de Gōng Xǐ Fā Cái à
                    21h00, le dimanche 25 février 2024.
                  </li>
                  <li>
                    Chaque gagnant choisira une des{" "}
                    <strong>25 enveloppes</strong>. Les enveloppes seront
                    sélectionnées une par une,{" "}
                    <strong>
                      au cours de 5 tours, par groupes de 5 gagnants, dans le
                      même ordre que celui dans lequel les noms ont été tirés au
                      sort au cours de la journée, puis les 5 enveloppes
                      sélectionnées seront ouvertes simultanément.
                    </strong>{" "}
                    Ce processus est ensuite répété jusqu'à ce que tous les
                    gagnants aient sélectionné et ouvert leurs enveloppes.
                    Chaque enveloppe contient l'un des éléments suivants:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Prix</th>
                            <th>Quantité</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>188$</td>
                            <td>12</td>
                            <td>2,444$</td>
                          </tr>
                          <tr>
                            <td>782$</td>
                            <td>2</td>
                            <td>1,564$</td>
                          </tr>
                          <tr>
                            <td>888$</td>
                            <td>8</td>
                            <td>7,104$</td>
                          </tr>
                          <tr>
                            <td>8,888$</td>
                            <td>2</td>
                            <td>17,776$</td>
                          </tr>
                          <tr className="tr-red">
                            <td>Total</td>
                            <td>25</td>
                            <td>28,888$</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "Eligibility" ? (
            i18n.language === "en" ? (
              <div>
                <h2>Eligibility</h2>
                <ol>
                  <li>The promotion is open to all Playground members.</li>
                  <li>The promotion takes place at Playground.</li>
                  <li>
                    Employees of Playground shall be ineligible to participate
                    in the promotion.
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Admissibilité</h2>
                <ol>
                  <li>
                    Cette promotion est ouverte à tous les membres du
                    Playground.
                  </li>
                  <li>
                    Tous les éléments de cette promotion se déploient au
                    Playground.
                  </li>
                  <li>
                    Les employés du Playground ne peuvent en aucun cas
                    participer à cette promotion.
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "General Rules" ? (
            i18n.language === "en" ? (
              <div>
                <h2>General Rules</h2>
                <ol>
                  <li>
                    When receiving their printed entries, it is the
                    participating patron’s responsibility to verify that the
                    correct number of ballots is issued.
                  </li>
                  <li>
                    It is the participating patron’s responsibility to make sure
                    they insert their ballot(s) into the Gōng Xǐ Fā Cái raffle
                    drum.
                  </li>
                  <li>
                    All participants must present a valid government-issued
                    photo identification upon request.
                  </li>
                  <li>
                    Playground reserves the right to change or alter this
                    promotion, as it deems appropriate, including, but not
                    limited to, making changes or alterations to these Rules and
                    Regulations relating to the accommodation of participants.
                  </li>
                  <li>
                    Patrons with self-excluded or banned status, as determined
                    by Playground, are not eligible for any of the gifts/prizes
                    associated with Playground contests, promotions, tournaments
                    or giveaways.
                  </li>
                  <li>
                    All references to times and dates reflect Eastern Time.
                  </li>
                  <li>
                    By participating in the promotion, winners consent to the
                    use of their names and photographs for promotional purposes
                    without additional compensation.
                  </li>
                </ol>
                <p>
                  <strong>
                    Questions relating to this promotion can be answered by
                    visiting the Player Rewards desk
                  </strong>
                </p>
              </div>
            ) : (
              <div>
                <h2>Règles Générales</h2>
                <ol>
                  <li>
                    Lorsque le participant demande de faire imprimer ses
                    bulletins de participation, il est de sa responsabilité de
                    vérifier que le nombre exact de bulletins est émis.
                  </li>
                  <li>
                    Il est de la responsabilité du participant de déposer ses
                    bulletins de participation dans le tambour de tirage Gōng Xǐ
                    Fā Cái - à défaut de quoi un participant pourrait se voir
                    disqualifié du jeu.
                  </li>
                  <li>
                    Tous les participants doivent présenter sur demande une
                    pièce d'identité avec photo émise par le gouvernement.
                  </li>
                  <li>
                    Playground se réserve le droit de changer ou de modifier
                    cette promotion à sa discrétion, y compris d’apporter des
                    changements ou des altérations aux détails du présent
                    Règlement.
                  </li>
                  <li>
                    Les clients dont le dossier affiche un statut
                    d'auto-exclusion ou d'interdiction d’accès, tel que
                    déterminé par Playground, ne sont pas admissibles aux
                    cadeaux/prix associés aux concours, promotions, tournois ou
                    cadeaux promotionnels du Playground.
                  </li>
                  <li>
                    Toutes les heures et dates mentionnées sont en heure de
                    l'Est.
                  </li>
                  <li>
                    En participant à la promotion, les gagnants consentent à
                    l'utilisation de leurs noms et photographies à des fins
                    promotionnelles sans compensation supplémentaire.
                  </li>
                </ol>
                <p>
                  <strong>
                    Toute question en relation à cette promotion peut être posée
                    au comptoir des Récompenses aux Joueurs
                  </strong>
                </p>
              </div>
            )
          ) : null}
        </div>
      </PartFour>
      
      
    </MainDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  z-index: 0;
  background: var(--white);
  & > div:first-child {
    z-index: 1;
    position: relative;
    border-bottom: 1px solid #222;
    float: left;
    width: 100%;
    background-image: url(${({ lng }) => lng === "en" ? "https://assets.playground.ca/pgfe/img/promotions/gxfc_2024_header_en.jpg" : "https://assets.playground.ca/pgfe/img/promotions/gxfc_2024_header_fr.jpg"});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 525px;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  & > div:nth-child(2) {
    display: flex;
    height: auto;
    width: 100%;
    padding: 40px 0;
    & > div {
      height: auto;
      width: 100%;
      margin: 0 auto;
      max-width: 900px;
      text-align: center;
      & > h1 {
        font-size: 40px;
        font-weight: 800;
        line-height: 1.15em;
        margin: 0px;
        margin-bottom: 10px;
      }
      & > p {
        font-size: 18px;
      }
    }
  }
  & > div:nth-child(3) {
    background: url("https://assets.playground.ca/pgfe/img/promotions/gxfc_bg_2024.jpg")
      50% 50%;
    padding: 60px 0px;
    background-position: top;
    background-size: cover;
    & > div:first-child {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      clear: both;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--white);
      & > h2 {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 33px;
        & > strong:nth-child(3) {
          color: #cc9a52;
        }
      }
    }
    & > div:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 80px;
      & > img {
        max-width: 30%;
      }
    }
    & > h2:nth-child(3) {
      color: var(--white);
      text-align: center;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 33px;
    }
    & > div:nth-child(4) {
      height: auto;
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 20px;
      & > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--white);
        border: 2px solid var(--white);
        & > img {
          width: 100%;
          height: auto;
        }
        & > span {
          padding: 10px;
          width: 100%;
          display: block;
          text-align: center;
          padding: 5px 0px;
          font-size: 22px;
          font-family: "Oswald";
          color: #970004;
        }
      }
    }
    & > div:nth-child(5) {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      height: auto;
      color: var(--white);
      display: block;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      & > h1 {
        font-weight: 700;
        font-size: 77px;
        line-height: 57px;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 0px;
        text-transform: uppercase;
        margin-bottom: 20px;
        & > span {
          font-size: 32px;
        }
      }
      & > p {
        font-size: 20px;
        text-align: center;
        font-weight: 300;
        max-width: 1000px;
        margin: 0 auto;
        padding-bottom: 15px;
      }
      & > p:nth-child(2) {
        font-size: 30px;
      }
      & > p:nth-child(5) {
        font-size: 13px;
      }
      & > div:last-child {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        & > h3 {
          width: 100%;
          height: auto;
          background: linear-gradient(
            to right,
            #b9752a,
            #c98d33,
            #ad752a,
            #c3842b,
            #f9d876
          );
          text-align: center;
          border: 1px solid var(--white);
          font-size: 32px;
          padding: 15px 0;
          margin: 0;
          & > span {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      background-image: url(${({ lng }) => lng === "en" ? "https://assets.playground.ca/pgfe/img/promotions/gxfc_2024_header_mobile_en.jpg" : "https://assets.playground.ca/pgfe/img/promotions/gxfc_2024_header_mobile_fr.jpg"});
      background-size: contain;
      background-position: top;
      margin-top: 80px;
      padding: 0;
      height: 200px;
    }
    & > div:nth-child(2) {
      padding: 40px 20px;
    }
    & > div:nth-child(3) {
      padding: 20px;
      & > div:first-child {
        & > h2 {
          font-size: 25px;
          line-height: 30px;
        }
      }
      & > div:nth-child(2) {
        flex-direction: column;
        gap: 20px;
        & > img {
          max-width: 100%;
        }
      }
      & > h2:nth-child(3) {
        font-size: 25px;
        line-height: 30px;
      }
      & > div:nth-child(4) {
        grid-template-columns: 1fr 1fr;
      }
      & > div:nth-child(5) {
        & > h1 {
          font-size: 60px;
          line-height: 50px;
          & > span {
            font-size: 28px;
          }
        }
        & > div:last-child {
          grid-template-columns: 1fr;
          & > h3 {
            padding: 10px 0;
            font-size: 28px;
            line-height: 28px;
          }
        }
      }
    }
    & > div:nth-child(4) {
      grid-template-columns: 1fr;
    }
  }
`;
const PartFour = styled.div`
  padding-bottom: 40px;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto;
  height: auto;
  position: relative;
  & > ul:first-child {
    margin-bottom: 20px;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    & > li {
      width: 100%;
      text-align: center;
      padding: 10px 0;
      background-color: #232426;
      color: #fff;
      transition: 500ms;
      font-weight: 700;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        background-color: #c39236;
      }
      &.active {
        background-color: #c39236;
      }
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    background: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    & > div {
      animation: ${fadeIn} 0.3s linear;
      width: 100%;
      height: fit-content;
      padding: 15px;
      & > h2 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
        color: #555;
      }
      & > p {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-style: italic;
        line-height: 28px;
        color: #555;
      }
      & > ol {
        font-size: 15px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 10px;
        & > li {
          color: #555;
        }
      }
      & > h3 {
        color: #555;
        margin: 20px 0 0;
        font-weight: 600;
        font-size: 19px;
        line-height: 27px;
      }
      & > p:last-child {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-style: italic;
        line-height: 28px;
        text-align: center;
        color: #555;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px;
    & > ul:first-child {
      flex-flow: wrap;
      justify-content: space-evenly;
      & > li {
        font-size: 14px;
        max-width: 45%;
      }
    }
    & > div:last-child {
      & > div {
        & > ol {
          font-size: 15px;
          padding-left: 20px;
        }
      }
    }
  }
`;
const GoldenTicket = styled.div`
  animation: ${fadeIn} 0.3s linear;
  background: url(${(props) => props.bgImage});
  background-size: cover;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: auto;
  & > img {
    margin: 0 auto;
    text-align: center;
    display: block;
    max-width: 100%;
    height: auto;
  }
  & > h2 {
    color: var(--black) !important;
    text-align: center;
    font-size: 32px;
    line-height: 33px;
  }
  & > div {
    background-color: #fff;
    margin: 15px;
    padding: 15px;
    & > h3 {
      margin: 20px 0 0;
      font-weight: 600;
      font-size: 19px;
      line-height: 27px;
    }
    & > ol {
      font-size: 15px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 10px;
      padding: 0;
      & > li {
        color: #555;
        margin-left: 40px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div {
      & > ol {
        padding-left: 20px;
        & > li {
          margin-left: 0;
        }
      }
    }
  }
`;
const TableDiv = styled.div`
  height: fit-content;
  width: 100%;
  height: fit-content;
  margin: 20px 0;
  & > table:first-child {
    height: fit-content;
    width: 100%;
    background: var(--white);
    border-spacing: 0;
    border-collapse: collapse;
    & > thead {
      & > tr {
        text-align: center;
        background: #131619;
        color: #fff;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        & > th {
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          border: 1px solid #ddd;
          border-bottom: none;
        }
      }
    }
    & > tbody {
      & > tr {
        height: 40px;
        color: var(--black);
        &:hover {
          background: #e8ebef;
        }
        & > td {
          border: 1px solid #ddd;
          font-weight: 400;
          text-align: center;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          font-size: 14px;
        }
      }
      .tr-red {
        background: var(--red);
        color: var(--white);
        &:hover {
          background: var(--red);
        }
      }
    }
  }
`;
export default GongXiFaCai;
