import React from "react";
import { useState } from "react";
import styled, { keyframes } from "styled-components";


import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const WalkOfFame2023 = () => {
  const { i18n } = useTranslation();
  const lng = i18n.language;
  const [isActive, setIsActive] = useState("Nomination Ticket");
  const handleisActive = (value) => {
    setIsActive(value);
  };
  return (
    <PromoDetailDiv>
      <PartOne>
        <ResponsiveImage
          src={`promotions/walk_of_fame_2023_header_desktop_${lng}.jpg`}
          alt="walk_of_fame_2023_header_desktop"
        />
        <ResponsiveImage
          src={`promotions/walk_of_fame_2023_header_mobile_${lng}.jpg`}
          alt="walk_of_fame_2023_header_mobile"
        />
      </PartOne>
      <PartTwo>
        <h1>$15,000 in cash prizes every Sunday!</h1>
        <p>
          Make sure to be in the gaming machine section{" "}
          <strong>every Sunday from May 8 to July 2</strong> for your chance to
          play Walk of Fame, the game where{" "}
          <strong>everyone can be a star!</strong>
        </p>
        <p>
          Earn entries <strong>every day of the week</strong> and return{" "}
          <strong>every Sunday</strong>, where you’ll want to be one of the 18
          players selected to play.
        </p>
        <p>
          On game day, selected players choose a celebrity star (between 1 and
          32), and take that spot in the game area. When your celebrity is
          picked, you win the corresponding prize. It’s that easy!
        </p>
        <p>
          <strong>Weekly prize breakdown:</strong>
        </p>
        <TableDiv>
          <table>
            <thead>
              <tr>
                <th>Number of prizes</th>
                <th>Prize amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>5</td>
                <td>$300</td>
              </tr>
              <tr>
                <td>5</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>4</td>
                <td>$750</td>
              </tr>
              <tr>
                <td>3</td>
                <td>$1,000</td>
              </tr>
              <tr>
                <td>1</td>
                <td>$5,000</td>
              </tr>
            </tbody>
          </table>
        </TableDiv>
        <p>
          Draws are held from 12:00pm to 8:30pm and the game starts at 8:45pm.
        </p>
        <p>
          If you get to play Walk of Fame then your luck may take you even
          further because all participants are{" "}
          <strong>
            guaranteed a cash prize AND a Walk of Fame Nomination Ticket
          </strong>{" "}
          for both $30,000 Grand Finales.
        </p>
      </PartTwo>
      <PartThree bgImage={useResponsiveImageUrl("promotions/walk_of_fame_2023_finale.jpg")}>
        <div>
          <ResponsiveImage
            src={`promotions/walk_of_fame_2023_finale_${lng}.png`}
            alt="walk_of_fame_2023_finale"
          />
          <h2>
            On both June 25 and July 2, someone will walk away with $10,000!
          </h2>
          <p>
            All <strong>Walk of Fame Nomination Ticket</strong> holders will
            take part in the final raffles. There will be{" "}
            <strong>32 winners</strong> at each Finale, each{" "}
            <strong>guaranteed a cash prize</strong>, but only one, per finale
            will be lucky enough to claim the grand prize. And the winner is…
          </p>
        </div>
      </PartThree>
      <PartFour>
        <ul>
          <li
            onClick={() => handleisActive("Nomination Ticket")}
            className={isActive === "Nomination Ticket" ? "active" : undefined}
          >
            Nomination Ticket
          </li>
          <li
            onClick={() => handleisActive("How to Qualify")}
            className={isActive === "How to Qualify" ? "active" : undefined}
          >
            How to Qualify
          </li>
          <li
            onClick={() => handleisActive("Draw Process")}
            className={isActive === "Draw Process" ? "active" : undefined}
          >
            Draw Process
          </li>
          <li
            onClick={() => handleisActive("Game Description")}
            className={isActive === "Game Description" ? "active" : undefined}
          >
            Game Description
          </li>
          <li
            onClick={() => handleisActive("Eligibility")}
            className={isActive === "Eligibility" ? "active" : undefined}
          >
            Eligibility
          </li>
          <li
            onClick={() => handleisActive("General Rules")}
            className={isActive === "General Rules" ? "active" : undefined}
          >
            General Rules
          </li>
        </ul>
        <div>
          {isActive === "Nomination Ticket" ? (
            <NominatioTicket bgImage={useResponsiveImageUrl("promotions/nomination_ticket_bg.jpg")}>
              <ResponsiveImage
                src={`promotions/nomination_ticket_${lng}.png`}
                alt="nomination_ticket"
              />
              <h2>
                Earn Nomination Tickets for a chance to play in the $30,000 Walk
                of Fame Grand Finales!
              </h2>
              <div>
                <h3>How can you get your hands on a Nomination Ticket?</h3>
                <ol>
                  <li>
                    Only players that have collected Walk of Fame Nomination
                    Tickets between May 8 and July 2, 2023, are eligible for the
                    Walk of Fame Final draws on June 25 and July 2.
                  </li>
                  <li>
                    Players can collect them the following ways:
                    <ol type="a">
                      <li>
                        Nomination Tickets are awarded to all players who get
                        called to play Walk of Fame on any of the draw days.
                        Players can only play Walk of Fame once per draw day,
                        but still receive a Nomination Tickets for every time
                        they are called up.
                      </li>
                      <li>
                        Nomination Tickets are awarded, along with $50 in Free
                        Play via{" "}
                        <strong>
                          Hot Seat draw, every Thursday between May 11 and June
                          22,
                        </strong>{" "}
                        at a rate of 2 per hour from 11:00am to 11:00pm.
                      </li>
                      <li>
                        Nomination Tickets are awarded via{" "}
                        <strong>
                          Hot Seat draw, every day between June 26 to 30,
                        </strong>{" "}
                        at a rate of 2 per hour from 11:00am to 11:00pm.
                      </li>
                      <li>
                        Nomination Tickets can also be randomly awarded by floor
                        staff.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </NominatioTicket>
          ) : null}
          {isActive === "How to Qualify" ? (
            <div>
              <h2>How to Qualify</h2>
              <p>
                There are two (2) ways to participate in this promotion, as
                detailed in rules 1, 2 and 4
              </p>
              <ol>
                <li>
                  Players receive one (1) draw entry for every two hundred and
                  fifty (250) Rewards Points earned while playing gaming
                  machines.
                </li>
                <li>
                  After every gaming session, players must present themselves to
                  the gaming machine cashier and request to have their entries
                  printed. Players then need to place their entries inside their
                  tier specific raffle drum located on the gaming machine gaming
                  floor. Failure to do so could result in disqualification if
                  their name is selected.
                </li>
                <li>
                  Players may also purchase entries for two hundred and fifty
                  (250) points.
                </li>
                <li>
                  Players may redeem a maximum of twenty-five (25) draw entries
                  per day.
                </li>
                <li>
                  Draw entries are only available for collection on the day they
                  were earned.
                </li>
                <li>
                  For every draw day, players have up to one (1) week to earn
                  raffle entries.
                  <TableDiv>
                    <table>
                      <thead>
                        <tr>
                          <th>Earning Period</th>
                          <th>Draw Day</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>May 8 at 9:00am - May 14 at 8:25pm</td>
                          <td>May 14 (18 draws)</td>
                        </tr>
                        <tr>
                          <td>May 14 at 8:26pm - May 21 at 8:25pm</td>
                          <td>May 21 (18 draws)</td>
                        </tr>
                        <tr>
                          <td>May 21 at 8:26pm - May 28 at 8:25pm</td>
                          <td>May 28 (18 draws)</td>
                        </tr>
                        <tr>
                          <td>May 28 at 8:26pm - June 4 at 8:25pm</td>
                          <td>June 4 (18 draws)</td>
                        </tr>
                        <tr>
                          <td>June 4 at 8:26pm - June 11 at 8:25pm</td>
                          <td>June 11 (18 draws)</td>
                        </tr>
                        <tr>
                          <td>June 11 at 8:26pm - June 18 at 8:25pm</td>
                          <td>June 18 (18 draws)</td>
                        </tr>
                      </tbody>
                    </table>
                  </TableDiv>
                </li>
                <li>
                  To be part of a draw, a ballot must be deposited by the
                  participant into the draw drum corresponding to their Status
                  Level before the ongoing earning period ends.
                </li>
              </ol>
              <h3>$30,000 Grand Finales</h3>
              <ol>
                <li>
                  Only players that have collected Nomination Tickets between
                  May 8 and July 2, 2023 are eligible to play.
                </li>
                <li>
                  Players collect Nomination Tickets the following ways:
                  <ol type="a">
                    <li>
                      Nomination Tickets are awarded to all players who get
                      called to play Walk of Fame on any of the draw days.
                      Players can only play Walk of Fame once per draw day, but
                      still receive a Nomination Tickets for every time they are
                      called up.
                    </li>
                    <li>
                      Nomination Tickets are awarded, along with $50 in Free
                      Play via{" "}
                      <strong>
                        Hot Seat draw, every Thursday between May 11 and June
                        22,
                      </strong>{" "}
                      at a rate of 2 per hour from 11:00am to 11:00pm.
                    </li>
                    <li>
                      Nomination Tickets are awarded via{" "}
                      <strong>
                        Hot Seat draw, every day between June 26 to 30,
                      </strong>{" "}
                      at a rate of 2 per hour from 11:00am to 11:00pm.
                    </li>
                    <li>
                      Nomination Tickets can also be randomly awarded by floor
                      staff.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          ) : null}
          {isActive === "Draw Process" ? (
            <div>
              <h2>Draw Process</h2>
              <ol>
                <li>
                  There are a total of one hundred and eight (108) prizes
                  available to be won via this promotion.
                </li>
                <li>
                  Draws are conducted eighteen (18) times per draw day, at 30
                  minute intervals between 12:00pm - 8:30pm on the following
                  days:
                  <ol type="a">
                    <li>Sunday May 14, 2023</li>
                    <li>Sunday May 21, 2023</li>
                    <li>Sunday May 28, 2023</li>
                    <li>Sunday June 4, 2023</li>
                    <li>Sunday June 11, 2023</li>
                    <li>Sunday June 18, 2023</li>
                  </ol>
                </li>
                <li>
                  Draws are done from Playground Rewards status-specific drums
                  in the following order:
                  <ol type="a">
                    <li>
                      Black status: 12:00pm, 12:30pm, 2:00pm, 2:30pm, 5:00pm
                    </li>
                    <li>Red status: 1:00pm, 1:30pm, 3:00pm, 3:30pm, 5:30pm</li>
                    <li>Silver and Gold: 4:00pm, 4:30pm</li>
                    <li>
                      All Status combined: 6:00pm, 6:30pm, 7:00pm, 7:30pm,
                      8:00pm, 8:30pm.
                      <ol type="i">
                        <li>
                          Prior to the 6:00 pm draw, all ballots will be mixed
                          into 1 single drum.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  As players are drawn they will select a celebrity (between 1
                  and 32), from the remaining available celebrities, which will
                  be their corresponding star when the game is played later that
                  day.
                </li>
              </ol>
              <h3>$30,000 Finales</h3>
              <ol>
                <li>
                  There are a total of sixty-four (64) prizes available to be
                  won via this promotion.
                </li>
                <li>
                  Draws will be conducted every twenty (20) minutes between
                  12:20 and 19:40, from a drum containing all the Walk of Fame
                  Nomination Ticket winners, for a total of 23 winners.
                </li>
                <li>
                  Hot Seat draws will be conducted every hour between 12:00pm -
                  8:00pm for an additional 9 winners.
                </li>
                <li>
                  The game will be played at 8:15pm on Sunday June 25, 2023 and
                  Sunday, July 2, 2023.
                </li>
              </ol>
              <h3>General</h3>
              <ol>
                <li>
                  Winners must be in attendance at the time of the draw to win.
                  The selected names are announced over Playground’s internal
                  public address system on the gaming floor. The selected names
                  have five (5) minutes from the time of the announcement to
                  present themselves at the promotions area to participate.
                </li>
                <li>
                  If, at the end of the above-mentioned time interval, an
                  eligible selectee has not presented themselves at the
                  designated area, a site representative draws one (1)
                  additional ballot from the same raffle drum. This process
                  continues until an eligible selectee is present.
                </li>
                <li>
                  At the <strong>June 25 and July 2 Finales,</strong> all draw
                  winners will be given 5 minutes to present themselves to the
                  promotions area after having their name called. If they have
                  not shown up within this period, they shall be ineligible to
                  play the Finale and we will select two (2) winners at the
                  following draw time, and so on throughout the day.
                </li>
                <li>
                  In the event that a draw winner fails to present themselves to
                  the game area at the designated game time, a 5 minute window
                  will be issued. If the draw winner still cannot be found, a
                  new name will be selected via Hot Seat draw.
                </li>
                <li>Guests are eligible to win once per draw day.</li>
                <li>
                  The chance of being selected in any draw is dependent on the
                  number of entries received for that draw.
                </li>
              </ol>
            </div>
          ) : null}
          {isActive === "Game Description" ? (
            <div>
              <h2>Game Description</h2>
              <ol>
                <li>
                  The Walk of Fame game set contains thirty-two (32) celebrity
                  stars.
                </li>
                <li>
                  For each game, the selected contestants all come to the game
                  area at the same time and stand on the celebrity star they
                  previously selected.
                </li>
                <li>
                  Once the game begins, our game host will start to randomly
                  select envelopes from the raffle drum. The envelopes inside
                  the raffle drum contains all the celebrity stars that were
                  previously selected by the players.
                </li>
                <li>
                  Total prizing per week is $15,000 broken down as follows:
                  <TableDiv>
                    <table>
                      <thead>
                        <tr>
                          <th>Number of prizes</th>
                          <th>Prize amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>5</td>
                          <td>$300</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>$500</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>$750</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>$1000</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>$5000</td>
                        </tr>
                      </tbody>
                    </table>
                  </TableDiv>
                </li>
                <li>
                  Prizes are awarded from smallest to largest until only two (2)
                  players remain. At that point, one (1) more draw is conducted,
                  and the player associated with the selected number is awarded
                  the top prize of $5,000.
                </li>
              </ol>
              <h3>$30,000 Finales</h3>
              <ol start="6">
                <li>The game is played as per rules 2, 3 and 5 above.</li>
                <li>
                  Total prizing of $30,000 is broken down as follows:
                  <TableDiv>
                    <table>
                      <thead>
                        <tr>
                          <th>Number of Prices</th>
                          <th>Prize amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>9</td>
                          <td>$400</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>$500</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>$700</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>$1000</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>$2000</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>$10000</td>
                        </tr>
                      </tbody>
                    </table>
                  </TableDiv>
                </li>
              </ol>
            </div>
          ) : null}
          {isActive === "Eligibility" ? (
            <div>
              <h2>Eligibility</h2>
              <ol>
                <li>The promotion is open to all Playground members.</li>
                <li>The promotion takes place at Playground.</li>
                <li>
                  Employees of Playground shall be ineligible to participate in
                  the promotion.
                </li>
              </ol>
            </div>
          ) : null}
          {isActive === "General Rules" ? (
            <div>
              <h2>General Rules</h2>
              <ol>
                <li>
                  When receiving their printed entries, it is the participating
                  patron’s responsibility to verify that the correct number of
                  ballots is issued.
                </li>
                <li>
                  It is the participating patron’s responsibility to make sure
                  they insert their ballot into the correct raffle drum,
                  identified by their Playground Rewards status; Black, Red,
                  Gold or Silver. Failure to do so could result in
                  disqualification if their name is selected.
                </li>
                <li>
                  All participants must present a valid government-issued photo
                  identification upon request.
                </li>
                <li>
                  Playground reserves the right to change or alter this
                  promotion, as it deems appropriate, including, but not limited
                  to, making changes or alterations to these Rules and
                  Regulations relating to the accommodation of participants.
                </li>
                <li>
                  Patrons with self-excluded or banned status, as determined by
                  Playground, are not eligible for any of the gifts/prizes
                  associated with Playground contests, promotions, tournaments
                  or giveaways.
                </li>
                <li>All references to times and dates reflect Eastern Time.</li>
                <li>
                  By participating in the promotion, winners consent to the use
                  of their names and photographs for promotional purposes
                  without additional compensation.
                </li>
              </ol>
              <p>
                <strong>
                  Questions relating to this promotion can be answered by
                  visiting the Player Rewards desk
                </strong>
              </p>
            </div>
          ) : null}
        </div>
      </PartFour>
      
      
    </PromoDetailDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: #e8ebef;
  padding-bottom: 40px;
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
const PartOne = styled.div`
  height: 100%;
  width: 100%;
  &::after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > img:first-child {
      display: block;
    }
    & > img:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      display: block;
      padding-top: 80px;
    }
  }
`;
const PartTwo = styled.div`
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 60px auto;
  height: fit-content;
  & > h1:first-child {
    font-size: 40px;
    font-weight: 800;
    line-height: 1.15em;
  }
  & > p {
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
    color: #555;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    margin: 0;
    padding: 40px 20px;
    & > p {
      font-size: 16px;
    }
  }
`;
const PartThree = styled.div`
  background: url(${(props) => props.bgImage}) no-repeat 50% 50%;
  background-size: cover;
  padding: 80px 20px;
  width: 100%;
  height: fit-content;
  & > div {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    & > img {
      max-width: 600px;
      width: 100%;
      padding-bottom: 10px;
      margin: 0 auto;
      vertical-align: middle;
    }
    & > h2 {
      color: #fff;
      text-shadow: 0px 0px 20px #000;
      font-size: 34px;
      text-transform: inherit;
      font-weight: 700;
      margin: 0;
      margin-bottom: 25px;
      line-height: 38px;
    }
    & > p {
      background-color: #fff;
      color: #000;
      font-size: 20px;
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
      padding: 15px;
      line-height: 1.6;
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div {
      padding: 0 15px;
    }
  }
`;
const PartFour = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 40px auto;
  height: fit-content;
  position: relative;
  & > ul:first-child {
    margin-bottom: 20px;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    & > li {
      width: 100%;
      text-align: center;
      padding: 10px 0;
      background-color: #232426;
      color: #fff;
      transition: 500ms;
      font-weight: 700;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        background-color: #c39236;
      }
      &.active {
        background-color: #c39236;
      }
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    background: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    & > div {
      animation: ${fadeIn} 0.3s linear;
      width: 100%;
      height: fit-content;
      padding: 15px;
      & > h2 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
        color: #555;
      }
      & > p {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-style: italic;
        line-height: 28px;
        color: #555;
      }
      & > ol {
        font-size: 15px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 10px;
        & > li {
          color: #555;
        }
      }
      & > h3 {
        color: #555;
        margin: 20px 0 0;
        font-weight: 600;
        font-size: 19px;
        line-height: 27px;
      }
      & > p:last-child {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-style: italic;
        line-height: 28px;
        text-align: center;
        color: #555;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px;
    & > ul:first-child {
      flex-flow: wrap;
      justify-content: space-evenly;
      & > li {
        font-size: 14px;
        max-width: 45%;
      }
    }
    & > div:last-child {
      & > div {
        & > ol {
          font-size: 15px;
          padding-left: 20px;
        }
      }
    }
  }
`;
const NominatioTicket = styled.div`
  animation: ${fadeIn} 0.3s linear;
  background: #000 url(${props => props.bgImage}) 50% 50%;
  background-size: cover;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: fit-content;
  & > img {
    margin: 0 auto;
    text-align: center;
    display: block;
    max-width: 100%;
    height: auto;
  }
  & > h2 {
    text-align: center;
    color: #fff !important;
    text-shadow: 0px 0px 10px #000;
  }
  & > div {
    background-color: #fff;
    margin: 15px;
    padding: 15px;
    & > h3 {
      margin: 20px 0 0;
      font-weight: 600;
      font-size: 19px;
      color: #555;
      line-height: 27px;
    }
    & > ol {
      font-size: 15px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 10px;
      & > li {
        color: #555;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div {
      & > ol {
        padding-left: 20px;
      }
    }
  }
`;
const TableDiv = styled.div`
  height: fit-content;
  width: 100%;
  height: fit-content;
  margin: 20px 0;
  & > table:first-child {
    height: fit-content;
    width: 100%;
    background: var(--white);
    border-spacing: 0;
    border-collapse: collapse;
    & > thead {
      & > tr {
        text-align: center;
        background: #131619;
        color: #fff;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        & > th {
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          border: 1px solid #ddd;
          border-bottom: none;
        }
      }
    }
    & > tbody {
      & > tr {
        height: 40px;
        color: var(--black);
        &:hover {
          background: #e8ebef;
        }
        & > td {
          border: 1px solid #ddd;
          font-weight: 400;
          text-align: center;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          font-size: 14px;
        }
      }
    }
  }
`;
export default WalkOfFame2023;
