import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from "styled-components";
import Button from "src/components/Button/Button";
import { FaCalendarXmark } from "react-icons/fa6";
import { getScheduleDates } from 'src/utils/publishUtils';
import { useTranslation } from 'react-i18next';

const EventFinished = ({ scheduleKeyName, eventType = "event" }) => {
  const [showModal, setShowModal] = useState(false);
  const [showInfoBar, setShowInfoBar] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Fetch the publish/unpublish dates for the given key
    const { unpublish_at } = getScheduleDates(scheduleKeyName);

    // Determine if today is >= the unpublish_at date
    if (unpublish_at) {
      const today = new Date();
      const unpublishDate = new Date(unpublish_at);

      if (today >= unpublishDate) {
        setShowModal(true);
      }
    }
  }, [scheduleKeyName]);

  const handleClose = () => {
    setShowModal(false);
    setShowInfoBar(true);
  };

  return (
    <>
      {/* Modal */}
      <StyledModal show={showModal} onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="event-finished-modal"
      >
        <Modal.Header>
          <Modal.Title><FaCalendarXmark />
            {eventType === "promotion" ? t("eventFinished.promo.title") : t("eventFinished.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p onClick={handleClose}>
            {eventType === "promotion" ? t("eventFinished.promo.seeAnyway") : t("eventFinished.seeAnyway")}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            href={eventType === "promotion" ? t("routes.promotions") : t("routes.entertainment")}
            bgColor="var(--red)"
            color="var(--white)"
            hoverColor="var(--white)"
            hoverBgColor="var(--black)"
            label={eventType === "promotion" ? t("eventFinished.promo.upcomingEvents") : t("eventFinished.upcomingEvents")}
          />
        </Modal.Footer>
      </StyledModal>

      {/* Info Bar */}
      {showInfoBar && (
        <InfoBar className="p-4">
          <p>
            <FaCalendarXmark /> {eventType === "promotion" ? t("eventFinished.promo.title") : t("eventFinished.title")}
          </p>
          <Button
            className={"mt-0 mb-2"}
            href={eventType === "promotion" ? t("routes.promotions") : t("routes.entertainment")}
            bgColor="var(--white)"
            color="var(--black)"
            hoverColor="var(--black)"
            hoverBgColor="var(--white)"
            label={eventType === "promotion" ? t("eventFinished.promo.upcomingEvents") : t("eventFinished.upcomingEvents")}
            size="small"
          />
        </InfoBar>
      )}
    </>
  );
};

// Styled Modal
const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-header {
    background-color: var(--red);
    .modal-title {
      color: var(--white);
      font-weight: 700;
      text-align: center;
      svg {
        position: relative;
        bottom: 3px;
        margin-right: 0.8rem;
      }
    }
  }
  .modal-content, .modal-header {
    border-radius: 0;
    p  {
      color: var(--bluelinklight);
      cursor: pointer;
      margin: 0;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .modal-footer {
    button {
      margin: 0;
    }
  }
`;

// Info Bar Style
const InfoBar = styled.div`
  background-color: var(--red);
  color: var(--white);
  text-align: center;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  z-index: 9999;
  svg {
    position: relative;
    bottom: 3px;
  }
`;

export default EventFinished;
