import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FiPhone } from "react-icons/fi";
import { FaRegCalendarCheck, FaCar, FaBus, FaParking } from "react-icons/fa";
import Hero from "../components/Hero/Hero";
import SubTitleSection from "../components/SubTitleSection/SubTitleSection";
import { Row, Col } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';

import TheHostHotel  from "../components/Hotels/TheHostHotel";
import VogueHotel    from "../components/Hotels/VogueHotel";
import MarriottHotel from "../components/Hotels/MarriottHotel";

import { IoLocationSharp } from "react-icons/io5";
import { RiHotelBedFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "../components/ImageWrapper/ResponsiveImage";

const VisitUs = () => {
  const { t, i18n } = useTranslation();
  const [collapse, setColapse] = useState("");
  const handleCollapse = (value) => {
    collapse !== value ? setColapse(value) : setColapse("");
  };

  return (
    <VisitUsDiv>
      <Hero
        bgColor=""
        bgImage={"visit-us/visit-us-hero-bg-3.jpg"}
        buttonLink=""
        buttonText=""
        description=""
        descriptionBgColor=""
        image=""
        imageAlt=""
        mainHeroText={t("visit-us.header-1")}
        title=""
      >
        <IconSection xs={1} sm={3} className="g-4">
          <Col>
          <i>
            <FiPhone />
          </i>
            <p>{t("visit-us.nav-1-top")}<br/>
              <a href="tel:14506357653">(450) 635-7653</a><br/>
              <a href="mailto:info@playground.ca">info@playground.ca</a>
            </p>
          </Col>
          <Col>
            <i>
              <IoLocationSharp />
            </i>
            <p>{t("visit-us.nav-4-top")}<br/>
            <Link to={`#${t("nav.map")}`}>
              {i18n.language === "en" ? (
                <>
                  1500 Route 138,<br/>
                  Kahnawake, QC J0L 1B0
                </>
              ) : (
                <>
                  1500 Route 138,<br/>
                  Kahnawake, QC J0L 1B0
                </>
              )}
            </Link>
            </p>
          </Col>
          <Col>
            <i>
              <FaRegCalendarCheck />
            </i>
            <p>
              {t("visit-us.nav-2-top")}<br/>
              <Link to={t("routes.diningLounges")}>
                {t("visit-us.nav-2-bottom")}
              </Link>
          
            </p>
          </Col>
        </IconSection>
      </Hero>
      <MainContentDiv>
        <div className="how-to-visit">
          <SubTitleSection subTitle={t("visit-us.content-title")} />
          <p>{t("visit-us.content-text")}</p>
          <div className="accordian-section">
            <div onClick={() => handleCollapse("car")}>
              <div className="accordian-title">
                <FaCar />
                <SubTitleSection as={'h3'} subTitle={t("visit-us.by-car-title")} />
              </div>
              <div className={`accordian-content ${collapse === "car" ? "div-active" : undefined}`}>
                <SubTitleSection as={'h4'} subTitle={t("visit-us.by-car-text-1-part-1")} />
                <p>
                  {t("visit-us.by-car-text-1-part-2")}
                </p>
                  <SubTitleSection as={'h4'} subTitle={t("visit-us.by-car-text-2-part-1")} />
                <p>
                  {t("visit-us.by-car-text-2-part-2")}
                </p>
                  <SubTitleSection as={'h4'} subTitle={t("visit-us.by-car-text-3-part-1")} />
                <p>
                  {t("visit-us.by-car-text-3-part-2")}
                </p>
              </div>
            </div>
            <div onClick={() => handleCollapse("bus")}>
              <div className="accordian-title">
                <FaBus />
                <SubTitleSection as={'h3'} subTitle={t("visit-us.by-bus-title")} />
              </div>
              <div className={`accordian-content ${collapse === "bus" ? "div-active" : undefined}`}>
                <p>{t("visit-us.by-bus-text")}</p>
                <ul>
                  <li>{t("visit-us.by-bus-li-1")}</li>
                  <li>
                    {t("visit-us.by-bus-li-2-part-1")}{" "}
                    <a
                      href="https://exo.quebec/en/trip-planner/bus/CITSO"
                      target="_blanc"
                    >
                      EXO
                    </a>{" "}
                    {t("visit-us.by-bus-li-2-part-2")}
                  </li>
                  <li>{t("visit-us.by-bus-li-3")}</li>
                </ul>
              </div>
            </div>
            <div onClick={() => handleCollapse("parking")}>
              <div className="accordian-title">
                <FaParking />
                <SubTitleSection as={'h3'} subTitle={t("visit-us.parking-title")} />
              </div>
              <div className={`accordian-content ${collapse === "parking" ? "div-active" : undefined}`}>
                <ReactMarkdown>{t("visit-us.parking-text")}</ReactMarkdown>
              </div>
            </div>
            <div onClick={() => handleCollapse("stay")}>
              <div className="accordian-title">
                <RiHotelBedFill />
                <SubTitleSection as={'h3'} subTitle={t("visit-us.stay-title")} />
              </div>
              <div className={`accordian-content ${collapse === "stay" ? "div-active" : undefined}`}>
                <TheHostHotel headerAs='h4' />
                <VogueHotel headerAs='h4' />
                <MarriottHotel headerAs='h4' />
              </div>
            </div>
          </div>
          <SubTitleSection subTitle={t("visit-us.mercier-title")} />
          <p>{t("visit-us.mercier-text")}</p>
          <ContentSectionLink to={t("routes.mercierBridgeTraffic")}>
            <div>
              <span>{t("visit-us.mercier-btn")}</span>
            </div>
          </ContentSectionLink>
        </div>
        <div id={t("nav.map")}>
          <iframe
            title="pg-location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44834.51740728588!2d-73.742319158988!3d45.38599686610208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91bfa372112f3%3A0x84343f2ce23c89ba!2sPlayground+Poker+Club!5e0!3m2!1sen!2sca!4v1537882911051"
            style={{ border: "0", width: "100%", height: "100%" }}
            allowFullScreen=""
          ></iframe>
        </div>
      </MainContentDiv>
    </VisitUsDiv>
  );
};
const VisitUsDiv = styled.div`
  width: 100%;
  height: auto;
  background: var(--black);
`;

const IconSection = styled(Row)`
  .col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  i {
    background: var(--red);
    color: var(--white);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      font-size: 25px;
      margin: 0;
      padding: 0;
    }
    & > img {
      width: 32px;
    }
  }
  p {
    max-width: 200px;
    color: var(--white);
    margin: 1rem auto;
    font-weight: 500;
    font-size: 1rem;
  }
  a {
    color: var(--bluelinkdark)
  }
`;

const MainContentDiv = styled.div`
  color: var(--white);
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  div.how-to-visit {
    height: fit-content;
    width: 41%;
    padding-left: 20px;
    div.accordian-section {
      margin-top: 1rem;
      div.accordian-title {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        background: var(--lightblack);
        margin-bottom: 10px;
        padding: 10px;
        border-left: 4px solid var(--red);
        svg {
          font-size: 25px;
        }
        .subtitle-section {
          padding: 0;
          h3 {
            margin: 0;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            border-left: none;
            padding-left: 0%;
          }
        }
      }
      div.accordian-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        padding: 0 10px;
        .subtitle-section {
          padding: 0.5rem 0 0.25rem 0;
        }
        img {
          margin: 1rem 0;
        }
        &.div-active {
          max-height: 2050px;
        }
        ul {
          padding-left: 1rem;
          li {
            padding: 3px 0;
          }
        }
        & > div {
          height: fit-content;
          display: flex;
          align-items: flex-start;
          & > img {
            width: 100%;
            max-width: 180px;
          }
          ul {
            padding: 0;
            padding-left: 30px;
            margin: 0;
            & > li {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  & > div:last-child {
    width: 58%;
    height: auto;
    margin-bottom: 20px;
    & > iframe {
      width: 100%;
      min-height: 600px;
      margin: 0px 0px 0px 0px;
      position: relative;
      z-index: 0;
      background-size: cover;
      padding: 0px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    display: block;
    & > div:first-child {
      width: 100%;
      padding: 20px;
      & > div {
        & > div {
          & > div:last-child {
            & > div {
              display: block;
              & > img {
                max-width: unset;
              }
              & > ul {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
    & > div:last-child {
      width: 100%;
      position: relative;
    }
  }
`;

const ContentSectionLink = styled(Link)`
  line-height: 29px;
  height: 50px;
  text-align: center;
  display: block;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 0.5s;
  position: relative;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 20px 0;
  font-weight: 600;
  font-size: 20px;
  color: var(--white);
  &:hover {
    color: var(--red);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.5s;
    color: var(--white);
    background: var(--red);
    border-radius: 5px;
  }
  &:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.5s;
    border: 2px solid var(--red);
    transform: scale(1.2);
    border-radius: 5px;
  }
  &:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
`;
export default VisitUs;
