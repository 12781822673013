import React, { useEffect, useState } from "react";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { subscription } from "../../api/accounts";
import { subscribe as subscribeApi } from "../../api/accounts";
import { useTranslation } from "react-i18next";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { useAccount } from "src/context/account/account.provider";
import { useToast } from "src/context/toast/toast.provider";
import Spinner from "src/components/Spinner";


const NewsletterSection = ({ setField, myAccount }) => {
  const [preferences, setPreferences] = useState({
    opt_in_email: false,
    opt_in_sms: false,
    opt_in_phone: false,
    opt_in_mail: false,
  });
  const [loading, setLoading] = useState(false);
  const { access_token } = useAccount();
  const { showToastFn } = useToast();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");

        if (accessToken) {
          const result = await subscription(accessToken);
          if (result.success) {
            // Map API response to the expected form field names
            var optInData = {};
            if (myAccount) {
              optInData = {
                opt_in_email: result.data.opt_in_email,
                opt_in_sms: result.data.opt_in_sms,
                opt_in_phone: result.data.opt_in_phone,
                opt_in_mail: result.data.opt_in_mail,
              };
            }

            setPreferences(optInData);

            // Set fields in the parent form
            !myAccount &&
              Object.entries(optInData).forEach(([key, value]) => {
                setField(key, value);
              });
          }
        } else if (!accessToken && myAccount) {
          throw new Error("Access token is missing");
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchSubscription();
  }, [setPreferences]);

  const handlePreferenceChange = async (field, newValue) => {
    // If not logged in, update the parent form field and return
    // No need to hit the API
    if (!access_token) {
      setPreferences((prev) => ({
        ...prev,
        [field]: newValue,
      }));
      return;
    }

    setLoading(true);

    // Store the original value before updating
    const originalValue = preferences[field];

    // Update UI optimistically
    setPreferences((prev) => ({
      ...prev,
      [field]: newValue,
    }));

    const method = field.replace("opt_in_", "");
    const result = myAccount
      ? await subscribeApi(access_token, newValue, method)
      : false;

    if (result.success) {
      showToastFn({
        show: true,
        title: t("toast.general.success.title"),
        message: t("auth.optIn.success", { channel: t(`auth.optIn.${field}`) }),
        variant: "success"
      });
    } else {
      showToastFn({
        show: true,
        title: t("toast.general.error.message"),
        message: t("auth.optIn.error", { channel: t(`auth.optIn.${field}`) }),
        variant: "danger"
      });
      console.error("Error updating opt in preference: ", result);
       // Revert to the original value if the API call failed
      setPreferences((prev) => ({
        ...prev,
        [field]: originalValue,
      }));
    }
    setLoading(false);
  };

  return (
    <Div>
      <SubTitleSection
        subTitle={t("auth.register.subscribeTitle")}
        text={t("auth.register.subscribeInstructions")}
      />
      <CustomRadioWrapper
        className="mb-3"
        checkmark={useResponsiveImageUrl("svg/egd-no-check.svg")}
      >
        {loading && (
          <Overlay>
            <Spinner variant = "dark" />
          </Overlay>
        )}
        <Form.Check
          inline
          label={t("auth.register.optInEmail") || "optInSms"}
          id="opt_in_email"
          type="switch"
          name="opt_in_email"
          checked={!!preferences?.opt_in_email}
          onChange={() =>
            handlePreferenceChange("opt_in_email", !preferences.opt_in_email)
          }
        />
        <Form.Check
          inline
          label={t("auth.register.optInMail")}
          id="opt_in_mail"
          type="switch"
          name="opt_in_mail"
          checked={!!preferences?.opt_in_mail}
          onChange={() =>
            handlePreferenceChange("opt_in_mail", !preferences.opt_in_mail)
          }
        />
        <Form.Check
          inline
          label={t("auth.register.optInSms")}
          id="opt_in_sms"
          type="switch"
          name="opt_in_sms"
          checked={!!preferences?.opt_in_sms}
          onChange={() =>
            handlePreferenceChange("opt_in_sms", !preferences.opt_in_sms)
          }
        />
        <Form.Check
          inline
          label={t("auth.register.optInPhone")}
          id="opt_in_phone"
          type="switch"
          name="opt_in_phone"
          checked={!!preferences?.opt_in_phone}
          onChange={() =>
            handlePreferenceChange("opt_in_phone", !preferences.opt_in_phone)
          }
        />
      </CustomRadioWrapper>

      <section className="smaller mt-4 pt-2">
        <p className="mb-0">{t("auth.register.conditions.title")}</p>
        <ReactMarkdown
          className="terms-list"
          components={{
            a: ({ node, href, ...props }) => (
              <Link to={href} {...props} />
            ),
          }}
        >
          {t("auth.register.conditions.text", {
            privacyLink: t("routes.privacyPolicy"),
            termsLink: t("routes.smsTermsConditions")
          })}
        </ReactMarkdown>
      </section>
    </Div>
  );
};
const Div = styled.div`
  .subtitle-section {
    padding: 0;
  }
  .terms-list {
    ol {
      padding-left: 1rem;
      padding-top: 0.5rem;
      strong {
        font-weight: normal;
        white-space: nowrap;
      }
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.517);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  pointer-events: none;
`;

export const CustomRadioWrapper = styled.div`
  position: relative;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    .form-check-label {
      line-height: normal;
    }
    input {
      cursor: pointer;
      &.form-check-input {
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
        background-color: var(--white);
      }
      &.form-check-input:focus {
        box-shadow: none;
        border: none;
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
      }
      &.form-check-input:checked {
        border: var(--red);
        background-color: var(--green);
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      }
    }
  }
  .form-check-input {
    margin-top: 0;
  }
`;

export default NewsletterSection;
