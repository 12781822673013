import i18n from "src/middleware/i18n";
//change DEV to PROD for prod api for user auth
const BASE_API = process.env.REACT_APP_BASE_URL;
import Cookies from 'js-cookie';

export const payment = async (paymentData) => {
  try {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      console.error("Access token is missing");
      return { success: false, message: "Access token is missing" };
    }

    const response = await fetch(`${BASE_API}/cart/payment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: accessToken,
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
      },
      body: JSON.stringify(paymentData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const { message } = await response.json();
      if (response.ok) {
        return { success: true, message };
      } else {
        return { success: false, message };
      }
    } else {
      console.error("Response is not JSON:", await response.text());
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error making payment:", error);
    throw new Error("Failed to make payment");
  }
};

export const remove_item = async (item_gid) => {
  try {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      console.error("Access token is missing");
      return { success: false, message: "Access token is missing" };
    }

    const response = await fetch(
      `${BASE_API}/cart/remove_item?gid=${item_gid}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: accessToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const { message } = await response.json();
      if (response.ok) {
        return { success: true, message };
      } else {
        return { success: false, message };
      }
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    throw new Error("Failed to remove item from cart");
  }
};

export const add_item = async (item_gid) => {
  try {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      console.error("Access token is missing");
      return { success: false, message: "Access token is missing" };
    }

    const response = await fetch(`${BASE_API}/cart/add_item`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        item_gid: item_gid,
        quantity: 1,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const { message } = await response.json();
      if (response.ok) {
        return { success: true, message };
      } else {
        return { success: false, message };
      }
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    throw new Error("Failed to add item to cart");
  }
};

export const cart = async () => {
  try {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      throw new Error("Access token is missing");
    }

    const response = await fetch(`${BASE_API}/cart`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: accessToken,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || `HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      return data;
    } else {
      const text = await response.text();
      console.error("Response is not JSON:", text);
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

