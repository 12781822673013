import { Link,  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button/Button";
import { StyledForm } from "../../GlobalStyle";
import SubTitleSection from "../SubTitleSection/SubTitleSection";

const SignInSection = () => {
  const { i18n, t } = useTranslation();

  return (
    <StyledForm>
      <SubTitleSection
        subTitle={t("auth.register.alreadyHaveAccount")}
        // text={t("button.sign-in")}
        className="py-0"
      />

      <Button
        bgColor="var(--white)"
        color="var(--black)"
        hoverColor="var(--white)"
        hoverBgColor="var(--red)"
        fluid
        href={t("routes.login")}
        label={t("button.sign-in")}
      />

      <Link to={t("routes.homepage")} className="link">
        {t("button.return-back-home")}
      </Link>
    </StyledForm>
  );
};


export default SignInSection;
