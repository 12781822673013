import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { useTranslation } from "react-i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import Hero from "src/components/Hero/Hero";
import EventFinished from "src/components/EventFinished";

const IrishOpen2025 = () => {
  const { t } = useTranslation();
  return (
    <MainDiv>
      <EventFinished scheduleKeyName="irishOpen2024" eventType="promotion" />
      <Hero
        arrowBounce="true"
        bgImage="events/irish-open-2025/Irish-Open_Carousels-Bg.jpg"
        image="events/irish-open-2025/Irish-Open_Hero-Carousel_Logo.png"
        imageAlt={t("poker.irishOpen2025.headerImgAlt")}
      />
      <container-div>
        <TitleSection
          title={t("poker.irishOpen2025.title")}
          text={t("poker.irishOpen2025.qualifyDescription")}
          dark
          borderColor="#116c01"
            />
        {/* <h3>{t("poker.irishOpen2025.dateRange")}</h3> */}
        <ReactMarkdown>{t("poker.irishOpen2025.description1")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.irishOpen2025.description2")}</ReactMarkdown>
        <TitleSection
          title={t("poker.irishOpen2025.playgroundQualifyHeader")}
          as="h2"
          dark
          borderColor="#116c01"
          className="pb-0"
        />
        {/* <h4>{t("poker.irishOpen2025.playgroundQualifyHeader")}</h4> */}
        <Row xs={1} md={2} className="g-2">
          <Col>
            <StepContainer>
              <TitleSection
                title={t("poker.irishOpen2025.step1.title")}
                text={t("poker.irishOpen2025.step1.price")}
                as="h4"
                borderColor="#fff"
                className="py-0"
              />
              <ReactMarkdown>
                {t("poker.irishOpen2025.step1.details")}
              </ReactMarkdown>
              <p>{t("poker.irishOpen2025.step1.advance")}</p>
            </StepContainer>
          </Col>
          <Col>
            <StepContainer>
              <TitleSection
                title={t("poker.irishOpen2025.step2.title")}
                text={t("poker.irishOpen2025.step2.price")}
                as="h4"
                borderColor="#fff"
                className="py-0"
                />
              <ReactMarkdown>
                {t("poker.irishOpen2025.step2.details")}
              </ReactMarkdown>
              <p>{t("poker.irishOpen2025.step2.win")}</p>
              </StepContainer>
          </Col>
        </Row>
        <ReactMarkdown className="my-4">{t("poker.irishOpen2025.packageDetails")}</ReactMarkdown>
      </container-div>
    </MainDiv>
  );
};


const StepContainer = styled(Col)`
  background-color: #006601;
  border-radius: 15px;
  padding: 15px;
  color: var(--white);
  text-align: center;
  .title-section {
    h4 {
      font-weight: 700;
    }
    span {
      margin-bottom: 10px;
    }
    p {
      font-weight: 700;
    }

  }
`;

const MainDiv = styled.div`
  background: var(--white);
  text-align: center;
  h1, h1 {
    color: #032d05;
  }
  .hero_image_container img {
    padding: 0 6rem;
    margin: -2rem 0;
  }
  .hero_text_container {
    display: none;
  }
`;



export default IrishOpen2025;
