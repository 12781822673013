import React, { useState, useEffect, useMemo } from "react";
import { useHomepage } from "src/context/homepage/homepage.provider";
import styled from "styled-components";
import { Row, Spinner } from "react-bootstrap";
import { useErs } from "src/context/ers/ers.provider";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GameListHero from "src/components/ElectronicGamingDevices/GameListHero";
import TitleSection from "src/components/TitleSection/TitleSection";
import SectionalTabs from "src/components/SectionalTabs/SectionalTabs"; // Import SectionalTabs
import GameListCard from "src/components/ElectronicGamingDevices/GameListCard";
import {
  getHighestJackpotForGame,
  getJackpotGames,
  getTotalJackpotAmount,
} from "src/utils/gameListUtils";

const EgdGameList = () => {
  const { i18n, t } = useTranslation();
  const { gamingMachineJackpotData } = useHomepage();
  const { data, fetchData } = useErs();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const imgDir = "egd/game-list/";
  // Fetch categories from the pseudoRoutes file
  const routeCategory = "egdGameList"; // Define the routeCategory
  const categories = require(`../locales/${i18n.language}/pseudoRoutes.json`);
  const categoryArr = useMemo(
    () => Object.values(categories.pseudoRoutes[routeCategory] || {}),
    [i18n.language]
  );

  // Manage selected category and active path
  const [selectedCategory, setSelectedCategory] = useState({
    title: categoryArr[0]?.title || "",
    path: categoryArr[0]?.path || "",
  });
  const [updatedPath, setUpdatedPath] = useState(location.pathname?.includes(t("routes.egdGameList")) ? true : false);
  // useEffect(() => {
  //   const currentTab = categoryArr.find((el) => el.path === location.pathname);

  //   // If the user lands on `/egdGameList`, show the first tab content and mark it as active without navigating
  //   if (location.pathname === t("routes.egdGameList") && categoryArr[0] && selectedCategory.path !== categoryArr[0].path) {
  //     setSelectedCategory({
  //       title: categoryArr[0].title,
  //       path: categoryArr[0].path
  //     });
  //   }

  //   // If a valid tab is in the URL, set it as selectedCategory
  //   if (currentTab && currentTab.path !== selectedCategory.path) {
  //     setSelectedCategory({
  //       title: currentTab.title,
  //       path: currentTab.path
  //     });
  //   }
  // }, [location.pathname, categoryArr, selectedCategory.path]);

  useEffect(() => {
    // Only navigate to the base `/egdGameList` path if the current path isn't already a valid tab path
    const currentTab = categoryArr.find((el) => el.path === location.pathname);
    // If there's no valid tab in the current URL, navigate to `/egdGameList`
    if (!currentTab) {
      navigate(t("routes.egdGameList"), { replace: true });
    }
    if (currentTab && currentTab.title !== selectedCategory.title) {
      setSelectedCategory({ path: currentTab.path, title: currentTab?.title });
    } else if (!currentTab && location.pathname !== t("routes.egdGameList") || !updatedPath) {
      navigate(t("routes.error"));
    } else {
      setSelectedCategory({
        path: categoryArr[0]?.path,
        title: categoryArr[0]?.title,
      });
    }
  }, [i18n.language, location.pathname]);

  // Fetch both game and jackpot data
  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchData("egd-game-list"),
      fetchData("egd-progressive-jackpots"),
    ])
      .then(([gameData, jackpotData]) => {
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || t("error.somethingWentWrong"));
        console.error("Error fetching data:", err);
      });
  }, [fetchData, t]);

  const gameList = data?.["egd-game-list"];
  const jackpotList = data?.["egd-progressive-jackpots"];

  return (
    <>
      <JackpotPage>
        <GameListHero jackpotData={gamingMachineJackpotData} />
        <container-wide>
          {/* Sectional Tabs */}
          <SectionalTabs
            className="mt-4 mb-4"
            routeData={{
              route: t(`routes.egdGameList`),
              routeCategory,
              sectionTitle: categoryArr[0]?.title || "",
            }}
            isDynamic={true}
            selectedCategory={selectedCategory.title} // Active category
            bgColor="var(--darkmustard)"
            textColor="var(--black)"
            activeBgColor="var(--mustard)"
            activeTextColor="var(--black)"
          />

          {/* Dynamic Content */}
          <TabContent>
            {selectedCategory.title ===
              t("pseudoRoutes.egdGameList.newAndPopular.title") && (
              <>
                <TitleSection
                  as={"h2"}
                  title={t("egd.egdGameList.newAndPopular.title")}
                  text={t("egd.egdGameList.newAndPopular.description")}
                />
                {loading ? (
                  <SpinnerHolder>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">{t("loading")}</span>
                    </Spinner>
                  </SpinnerHolder>
                ) : error ? (
                  <div className="text-center text-danger">
                    <p>
                      {t("error.somethingWentWrong")}: {error}
                    </p>
                  </div>
                ) : (
                  <Row className="g-4 gx-3" xs={1} md={2} lg={3}>
                    {gameList?.map((game, index) => {
                      const highestJackpot = getHighestJackpotForGame(
                        game,
                        jackpotList
                      );

                      return (
                        game.status === "new" && (
                          <GameListCard
                            key={game.game_name}
                            game={game}
                            jackpot={highestJackpot}
                          />
                        )
                      );
                    })}
                  </Row>
                )}
              </>
            )}

            {selectedCategory.title ===
              t("pseudoRoutes.egdGameList.progressiveJackpots.title") && (
              <>
                <TitleSection
                  as={"h2"}
                  title={t("egd.egdGameList.progressiveJackpots.title")}
                  text={t("egd.egdGameList.progressiveJackpots.description")}
                />
                {loading ? (
                  <SpinnerHolder>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">{t("loading")}</span>
                    </Spinner>
                  </SpinnerHolder>
                ) : error ? (
                  <div className="text-center text-danger">
                    <p>
                      {t("error.somethingWentWrong")}: {error}
                    </p>
                  </div>
                ) : (
                  <Row className="g-4 gx-3" xs={1} md={2} lg={3}>
                    {getJackpotGames(jackpotList, gameList)?.map(
                      (jackpot, index) => (
                        <GameListCard
                          key={jackpot.game.game_name}
                          game={jackpot.game}
                          jackpot={jackpot}
                        />
                      )
                    )}
                  </Row>
                )}
              </>
            )}
          </TabContent>
          <Disclaimer className="text-center">
            *{t("egd.egdGameList.progressive_note_1")}
          </Disclaimer>
        </container-wide>
      </JackpotPage>
    </>
  );
};

const JackpotPage = styled.div`
  background-color: var(--black);
`;

const Disclaimer = styled.p`
  color: var(--mustard);
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const TabContent = styled.div`
  background: var(--lightblack);
  padding: 0 1rem 1rem;
  margin-bottom: 2rem;
`;

const SpinnerHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px;
`;

export default EgdGameList;
