/**
 * ScrollHandler component handles the scrolling behavior based on the current route (`pathname`)
 * and the scroll position state (`scrollPosition`). It uses the `ScrollPositionContext` to determine
 * whether to scroll to the top, a specific element, or a Y-coordinate.
 *
 * The component also ensures that scrolling is reset to the default after scrolling has completed.
 *
 * This scroll behavior is triggered whenever the `pathname` changes, making it perfect for handling
 * page navigations or dynamic tab interactions.
 *
 * @component
 * @example
 * // Scroll to top of the page after route changes
 * <ScrollHandler />
 *
 * @example
 * // Prevent scrolling when a dynamic nav tab is clicked:
 * // Inside the dynamic nav tab component, set scrollPosition to false
 * setScrollPosition(false);
 *
 * @example
 * // Scroll to a specific element by setting the scrollPosition to the element's ID:
 * setScrollPosition('my-element-id');
 *
 * @example
 * // Scroll to a specific Y position (e.g., 500px from the top):
 * setScrollPosition(500);
 */
import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ScrollPositionContext } from "../context/scrollPosition/scrollPosition.provider";

const ScrollHandler = () => {
  const { scrollPosition, setScrollPosition } = useContext(
    ScrollPositionContext
  );
  const { pathname, hash } = useLocation();

  // Helper function to wait for all images to load
  const waitForImagesToLoad = () => {
    const images = document.querySelectorAll("img");
    return Promise.all(
      Array.from(images).map((img) =>
        img.complete
          ? Promise.resolve()
          : new Promise((resolve) => img.addEventListener("load", resolve))
      )
    );
  };

  // Helper function to calculate Y-position with the navbar offset and wait for images
  const calculateYPositionWithNav = async (element) => {
    await waitForImagesToLoad(); // Wait for images to load
    const navElement = document.getElementById("header-navbar");
    const navOffset = navElement?.offsetHeight || 0;
    return element.getBoundingClientRect().top + window.scrollY - navOffset;
  };

  // Scroll to hash element
  const scrollToHash = async () => {
    if (hash) {
      const elementId = hash.slice(1);
      const element = document.getElementById(elementId);
      if (element) {
        const yPosition = await calculateYPositionWithNav(element);
        window.scrollTo({
          top: yPosition,
          behavior: "smooth",
        });
      }
    }
  };

  // Run handleScroll when pathname or hash changes
  useEffect(() => {
    const handleScroll = async () => {
      if (scrollPosition === false) return;

      if (hash) {
        await scrollToHash();
      return;
    }

    if (scrollPosition === "top" || scrollPosition === true) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (typeof scrollPosition === "number") {
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    };

    handleScroll();
  }, [pathname, hash]);

  // Handle clicks on links with the same hash
  useEffect(() => {
    const handleClick = (event) => {
      const anchor = event.target.closest("a[href]");
      if (!anchor) return;

      const url = new URL(anchor.href);
      if (url.hash && url.pathname === window.location.pathname && url.hash === window.location.hash) {
        event.preventDefault();
        scrollToHash(); // Manually trigger scroll for same hash
      }
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  // Reset scrollPosition to true (only when needed)
  useEffect(() => {
    if (scrollPosition !== true) {
    setScrollPosition(true);
    }
  }, [scrollPosition]);

  return null;
};

export default ScrollHandler;
