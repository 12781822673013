import AuthServiceInfo from "src/components/AuthServiceInfo";
import styled from "styled-components";

import TopLogo from "src/components/Auth/TopLogo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterFAQs from "./RegisterFAQs";
import RegisterForm from "src/components/Auth/RegisterForm";
import SignInSection from "src/components/Auth/SignInSection";

const Register = () => {

  return (
    <auth-page>
      <TopLogo />
      <Row xs={1} md={2}>
        <Col className="px-2">
          <RegisterForm />
          <SignInSection />
        </Col>

        <MaxWidthCol className="d-flex flex-column align-items-center justify-content-center">
          <RegisterFAQs />
          <AuthServiceInfo />
        </MaxWidthCol>
      </Row>
    </auth-page>
  );
};

const MaxWidthCol = styled(Col)`
  max-width: 500px;
`;

export default Register;
