import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useErs } from "src/context/ers/ers.provider";
import { useHomepage } from "src/context/homepage/homepage.provider";
import styled from "styled-components/macro";
import GameListHero from "src/components/ElectronicGamingDevices/GameListHero";
import TitleSection from "../components/TitleSection/TitleSection";
import HeroCarousel from "../components/Carousel/HeroCarousel";
import Hero from "../components/Hero/Hero";
import Jackpots from "../components/ElectronicGamingDevices/Jackpots";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import { Tab } from "react-bootstrap";
import Button from "../components/Button/Button";
import HashTabs from "../components/Tabbed/HashTabs";

const Egd = () => {
  const { t, i18n } = useTranslation();
  const { data, fetchData } = useErs();
  const { gamingMachineJackpotData } = useHomepage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const bgImage = "egd/promo_bg.jpg";
  const seeTheGamesButton = {
    label: t("egd.egdGameList.buttonText"),
    href: t("routes.egdGameList"),
    size: "medium",
    width: "xwide",
    specialHover: true,
    className: "mb-5 mt-0",
  };

  // Fetch the carousel data when the component mounts or language changes
  useEffect(() => {
    fetchData(`carousel-egd-${i18n.language}`)
      .then(() => {
        setError(null);
      })
      .catch((err) => {
        setError(true);
        console.error("Error fetching carousel data:", err);
      });
  }, [fetchData, i18n.language]);

  return (
    <EgdDiv>
      <switcheroo-holder>
        <TitleSection
          title={t("egd.section-title")}
          text={t("egd.section-title-text")}
        />
        <HeroCarousel
          carouselData={
            data[`carousel-egd-${i18n.language}`] &&
            data[`carousel-egd-${i18n.language}`].length > 0
              ? data[`carousel-egd-${i18n.language}`]
              : [t("defaultHeroCarousel.loading", { returnObjects: true })]
          }
        />
      </switcheroo-holder>

      <center-content>
        <Button {...seeTheGamesButton} />
      </center-content>

      <GameListHero jackpotData={gamingMachineJackpotData} compact />

      <RecentJackpotSection
        bgImage={useResponsiveImageUrl(bgImage)}
        className="pb-5"
      >
        <container-div>
          <TitleSection
            className="pb-0"
            title={t("egd.30-day-jackpots")}
            text={t("egd.30-day-jackpots-text")}
          />
          <HashTabs
            defaultActiveKey={t("egd.recentJackpots.hash")}
            id="egd-jackpot-tabs"
            tabs={[t("egd.recentJackpots.hash"), t("egd.topJackpots.hash")]}
          >
            <Tab
              eventKey={t("egd.recentJackpots.hash")}
              title={t("egd.recentJackpots.tab")}
            >
              <TabContent>
                <Jackpots
                  jackpotType={"recentJackpots"}
                  jackpotData={gamingMachineJackpotData}
                />
              </TabContent>
            </Tab>
            <Tab
              eventKey={t("egd.topJackpots.hash")}
              title={t("egd.topJackpots.tab")}
            >
              <TabContent>
                <Jackpots
                  jackpotType={"topJackpots"}
                  jackpotData={gamingMachineJackpotData}
                />
              </TabContent>
            </Tab>
          </HashTabs>
        </container-div>
      </RecentJackpotSection>

      <Hero
        className="inline-hero mt-5 promo-hero"
        mainHeroText={t("egd.promo-hero.main-hero-text")}
        darkenBg={0.4}
        bgImage="home/offers-header.jpg"
        descriptionBgColor="#0000004d"
        overlayVideoSrc="/videos/bbj-overlay.mp4"
        buttonSettings={{
          label: t("egd.promo-hero.buttonSettings.label"),
          href: t("routes.egdPromotions"),
          size: "large",
          width: "xwide",
          specialHover: true,
        }}
      />
    </EgdDiv>
  );
};

const EgdDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--black);
  .inline-hero {
    // TODO: Move this to variant="inline" on the component
    padding-top: 0;
  }
  .promo-hero {
    @media (max-width: 768px) {
      h2 {
        font-size: 2rem;
      }
    }
  }
  .game-list-container {
    .hero_text_container {
      display: none;
    }
  }
`;

const RecentJackpotSection = styled.div`
  background-color: var(--black);
  padding: 20px;
  position: relative;
  container-div {
    padding: 0;
    z-index: 2;
    position: relative;
    > .title-section {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  .title-section,
  .sectional-tabs {
    z-index: 2;
    position: relative;
  }
`;

const TabContent = styled.div`
  background-color: var(--lightblack);
  /* background-color: rgba(255, 255, 255, 0.5); */
`;

export default Egd;
