import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner } from 'react-bootstrap'; // Added Spinner for loading state
import { shouldPublish } from "src/utils/publishUtils";
import { useErs } from "src/context/ers/ers.provider"; // Assuming you have useErs context

import Hero from "src/components/Hero/Hero";
import MoreThanShows from "src/components/HomePage/MoreThanShows";
import PgCard from "src/components/Card/PgCard";
import SectionalTabs from "src/components/SectionalTabs/SectionalTabs";
import TitleSection from "src/components/TitleSection/TitleSection";

const Entertainment = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const { sectionCategory, sectionDescription } = useHomepage();
  const { data, fetchData } = useErs(); // Fetch API data

  const [loading, setLoading] = useState(true); // State for loading spinner
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching
    fetchData(`entertainment-${i18n.language}`)
      .then((response) => {
        setLoading(false); // Set loading to false after successful fetch
        setError(null); // Clear any previous error
      })
      .catch((err) => {
        setLoading(false); // Set loading to false on error
        setError(err.message || t("error.somethingWentWrong")); // Set error message
        console.error("Error fetching entertainment data:", err);
      });
  }, [fetchData, i18n.language]);

  const entertainmentDataArr = data[`entertainment-${i18n.language}`];

  return (
    <EntertainmentPage>
      <Hero
        bgColor=""
        bgImage={"entertainment/entertainment-hero-bg.jpg"}
        buttonLink=""
        buttonText=""
        descriptionBgColor=""
        image=""
        imageAlt=""
        mainHeroText={t("entertainment.header.title")}
        title=""
      >
        <AnnouncementDiv className="announcement">
          <ReactMarkdown>{t("entertainment.header.liveBands")}</ReactMarkdown>
          <ReactMarkdown>{t("entertainment.header.liveDJ")}</ReactMarkdown>
        </AnnouncementDiv>

        <SectionalTabs
          routeData={{
            route: t("routes.entertainment"),
            routeCategory: "entertainment",
            sectionTitle: t("entertainment.liveEvents"),
            selectCategory: t("entertainment.selectEvent"),
            selectAll: t("entertainment.selectAll"),
          }}
          isDynamic={true}
        />
      </Hero>

      <div>
        <TitleSection
          title={
            sectionCategory && sectionDescription ? sectionCategory : t("entertainment.forEveryone")
          }
          text={
            sectionDescription
              ? sectionDescription
              : t("entertainment.allEntertainmentText")
          }
        />
      </div>

      <container-wide>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{t("loading")}</span>
            </Spinner>
          </div>
        ) : error ? (
            <div className="text-center text-danger">
              <p>{t("error.somethingWentWrong")}: {error}</p>
            </div>
        ) : (
          <Row className="g-4 gx-3" xs={1} md={2} lg={3}>
            {entertainmentDataArr && entertainmentDataArr?.map((el, ind) => {
              // Check if the card should render based on the publish/unpublish logic
              const shouldRender = shouldPublish(el);

              // Additional condition for rendering based on category
              const meetsCategoryCondition = sectionCategory && params.category
                ? el.special_note === sectionCategory || el.category === sectionCategory
                : true;

              // Combine all conditions to decide whether to render the card
              if (shouldRender && meetsCategoryCondition) {
                // Determine button settings based on event_url and ticket_url
                const buttonSettings = el.event_url || el.ticket_url ? (
                  el.event_url && el.ticket_url
                  // If both URLs are present, render two buttons
                    ? [
                        {
                          href: el.event_url,
                          label: t("button.read-more")
                        },
                        {
                          href: el.ticket_url,
                          label: t("entertainment.buyTickets")
                        }
                      ]
                    : {
                      // Render a single button if only one URL is present
                      href: el.event_url || el.ticket_url,
                      label: el.event_url ? t("button.read-more") : t("entertainment.buyTickets")
                    }
                ) : null;

                return (
                  <Col key={el.name + ind}>
                    <PgCard
                      image={el.image}
                      imageAlt={el.imageAlt || el.name}
                      title={el.name}
                      subtitle={el.event_info}
                      textTitle={el.title}
                      text={el.text}
                      buttonSettings={buttonSettings}
                    />
                  </Col>
                );
              }

              return null;
            })}
          </Row>
        )}
      </container-wide>
      <EntertainmentFooter>
        <TitleSection
          title={t("entertainment.moreThanShows")}
          as="h2"
          text={t("entertainment.moreThanShowsText")}
        />
        <MoreThanShows />
      </EntertainmentFooter>
    </EntertainmentPage>
  );
};

const EntertainmentPage = styled.div`
  .pg-card {
    .card-header {
      flex-grow: 0;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .card-text {
        flex-grow: 1;
      }
    }
  }
`;

const AnnouncementDiv = styled.div`
  &.announcement{
    width: 100%;
    max-width: 700px;
    margin: 0 auto 1.5rem;
    border-radius: 5px;
    background-color: #e7da4b;
    padding: 1rem;
    h2, p {
      padding: 10px;
      color: var(--black);
      text-shadow: none;
      margin: 0;
      padding: 0;
    }
    h2 {
      font-family: 'Poppins', sans-serif;
      font-weight: 900;
      font-size: 20px;
      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }
    p {
      font-size: 17px;
      margin-bottom: 0.8rem;
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
`;

const EntertainmentFooter = styled.div`
  background-color: var(--lightblack);
  margin-top: 2rem;
`;

export default Entertainment;

