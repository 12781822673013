import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Button from "src/components/Button/Button";
import EventFinished from "src/components/EventFinished";
import TitleSection from "src/components/TitleSection/TitleSection";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const ChineseNewYear2025 = () => {
  const { t, i18n } = useTranslation();

  return (
    <Div>
      <Hero
        bgImage="promotions/cny-2025/2560x1440_CNY_Carousels-Bg.jpg"
        buttonSettings={null}
        image={`promotions/cny-2025/1600x800_CNY_Hero-Carousel_Logo_v3_${i18n.language}.png`}
        description={t("chineseNewYear2025.title")}
        descriptionBgColor="#ddbc8b"
      >
        {/* <p>For just $19</p> */}
      </Hero>
      <EventFinished
        scheduleKeyName="chineseNewYear2025"
        eventType="promotion"
      />
      <MainContentDiv
        bgImage={useResponsiveImageUrl(
          "entertainment/variety/new-years-eve-2025/nye-2024-background.jpg"
        )}
      >
        <container-div>
          <TitleSection
            as={"h1"}
            title={t("chineseNewYear2025.content.title")}
            text={t("chineseNewYear2025.content.description")}
            dark={false}
            borderColor="#c54132"
          />
          <Row className="g-4 mb-5" id="">
            <Col xs={12} md={6}>
              <ResponsiveImage
                src={"promotions/cny-2025/low-angle-asian-paper-lanterns-v2.jpg"}
                alt={t(
                  "entertainment.newYearsEve2025.content.part2.partyPeople.title"
                )}
              />
            </Col>
            <Col xs={12} md={6}>
              <SubTitleSection
                subTitle={t("chineseNewYear2025.content.eventHighlights.title")}
                text={t("chineseNewYear2025.content.eventHighlights.text")}
                borderColor="#c54132"
              />
              <Button
                bgColor="#c54132"
                color="var(--white)"
                hoverColor="var(--black)"
                hoverBgColor="#ddbc8b"
                href={t("routes.chineseNewYear2025Games")}
                label={t("chineseNewYear2025.content.eventHighlights.link")}
                tabIndex="-1"
              />
            </Col>
          </Row>
          <Row className="g-4 mb-4" id="">
            <Col xs={12} md={6}>
              <ResponsiveImage
                src={"promotions/cny-2025/1600x1000_lion-dance.jpg"}
                alt="Get Your Party Bracelet – Access All Floors and Perks!"
              />
            </Col>
            <Col xs={12} md={6}>
              <SubTitleSection
                subTitle={t("chineseNewYear2025.content.lionDance.title")}
                borderColor="#c54132"
              />
              <p>
                {t("chineseNewYear2025.content.lionDance.text")}{" "}
                <Link to={t("routes.weeklyJackpotParty")}>
                  {t("chineseNewYear2025.content.lionDance.link")}
                </Link>
              </p>
              <ReactMarkdown>
                {t("chineseNewYear2025.content.lionDance.dates")}
              </ReactMarkdown>
              <ReactMarkdown>
                {t("chineseNewYear2025.content.lionDance.times")}
              </ReactMarkdown>
            </Col>
          </Row>
          <Row className="g-4 mb-5" id="">
            <Col xs={12} md={6}>
              <ResponsiveImage
                src={"promotions/cny-2025/1600x1000_band.jpg"}
                alt={t(
                  "entertainment.newYearsEve2025.content.part2.partyPeople.title"
                )}
              />
            </Col>
            <Col xs={12} md={6}>
              <SubTitleSection
                subTitle={t("chineseNewYear2025.content.chineaseMusic.title")}
                text={t("chineseNewYear2025.content.chineaseMusic.text")}
                borderColor="#c54132"
              />
            </Col>
          </Row>
          <Row className="g-4 mb-5" id="">
            <Col xs={12} md={6}>
              <ResponsiveImage
                src={"promotions/cny-2025/1600x1000_Celebration.jpg"}
                alt={t(
                  "entertainment.newYearsEve2025.content.part2.partyPeople.title"
                )}
              />
            </Col>
            <Col xs={12} md={6}>
              <SubTitleSection
                subTitle={t("chineseNewYear2025.content.snakeWithUs.title")}
                text={t("chineseNewYear2025.content.snakeWithUs.text")}
                borderColor="#c54132"
              />
            </Col>
          </Row>
        </container-div>
      </MainContentDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: auto;
  .title-section {
    p {
      padding: 0;
      font-size: 20px;
    }
  }
`;

const MainContentDiv = styled.div`
  /* background: ${(props) => `url(${props.bgImage})`}; */
  background-color: #c54132;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  container-div {
    background: rgba(0, 0, 0, 0.5);
  }
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  color: var(--white);
  text-align: left;
  .row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    &:nth-child(3), &:nth-child(5) {
      flex-direction: row-reverse;
    }
    .col-12 {
      .subtitle-section {
        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
    }
  }
`;

export default ChineseNewYear2025;
