const BASE_API = process.env.REACT_APP_BASE_URL;
import Cookies from 'js-cookie';

export async function fetchErs(slug) {
  const url = `${BASE_API}/ers/${slug}`;
  // Retrieve the access token from localStorage
  const accessToken = Cookies.get("access_token");

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      console.log(message);
      throw new Error(message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
