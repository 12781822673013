import styled from "styled-components";
import { useHomepage } from "src/context/homepage/homepage.provider";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import ImageLink from "../ImageWrapper/ImageLink";
import { useIsLgScreenUp } from "src/utils/windowUtils";

const sliderConfig = [
  {
    key: "chineseNewYear2025",
    image: "promotions/cny-2025/cny-2025-carousel.jpg",
  },
  {
    key: "surfAndTurf2024",
    image: "promotions/dining/surf-and-turf/surf-and-turf-main.jpg",
  },
  {
    key: "wcc2025",
    getImage: (language) =>
      `events/wcc_2025/WCC-homepage-carousel-${language}.jpg`,
  },
  {
    key: "valentines2025",
    image: "entertainment/variety/valentines_2025/Elvis-EGD-508x318.jpg",
  },
  {
    key: "poker",
    image: "home/poker-slider.jpg",
  },
  {
    key: "egd",
    image: "home/egd-slider.jpg",
  },
  // {
  //   key: "restaurants",
  //   image: "home/restaurants-slider.jpg",
  // },
  // {
  //   key: "loungeBars",
  //   image: "home/lounge-slider.jpg",
  // },
  // {
  //   key: "entertainment",
  //   image: "home/entertainment-slider.jpg",
  // },
];

const MoreThanShows = () => {
  const { t, i18n } = useTranslation();
  const isLgScreenUp = useIsLgScreenUp();
  const { homeOfPlaygroundCardImg, homeOfPlaygroundCardImgFn } = useHomepage();

  const settings = {
    className: "",
    centerMode: true,
    centerPadding: 20,
    dots: false,
    infinite: true,
    lazyload: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
  };

  return (
    <SliderContainer>
      {isLgScreenUp ? (
        <SecondPartMain>
          {sliderConfig.map((el) => {
            const image =
              typeof el.getImage === "function"
                ? el.getImage(i18n.language)
                : el.image;

            return (
              <div
                key={el.key}
                onMouseOver={() => homeOfPlaygroundCardImgFn(el.key)}
                className={
                  el.key === homeOfPlaygroundCardImg ? "active-card" : undefined
                }
              >
                <ImageLink
                  to={t(`moreThanShows.${el.key}.url`)}
                  imgSrc={image}
                  alt={t(`moreThanShows.${el.key}.image_alt`)}
                  className={el?.position === "left" ? "slide-header-image left" : "slide-header-image"}
                />
                <section>
                  <h5>{t(`moreThanShows.${el.key}.title`)}</h5>
                  <div
                    className={
                      el.key === homeOfPlaygroundCardImg
                        ? "slide-content-active"
                        : "slide-content"
                    }
                  >
                    <p>{t(`moreThanShows.${el.key}.content`)}</p>
                    <Button
                      bgColor="var(--red)"
                      color="var(--white)"
                      hoverColor="var(--white)"
                      hoverBgColor="var(--black)"
                      size="small"
                      fluid
                      href={t(`moreThanShows.${el.key}.url`)}
                      label={t("button.check-it-out")}
                    />
                  </div>
                </section>
              </div>
            );
          })}
        </SecondPartMain>
      ) : (
        <SecondPartMainMobile>
          <Slider {...settings}>
            {sliderConfig.map((el) => {
              const image =
                typeof el.getImage === "function"
                  ? el.getImage(i18n.language)
                  : el.image;

              return (
                <div key={el.key}>
                  <ImageLink
                    to={t(`moreThanShows.${el.key}.url`)}
                    imgSrc={image}
                    alt={t(`moreThanShows.${el.key}.image_alt`)}
                    className="slide-header-image"
                  />
                  <section>
                    <h5>{t(`moreThanShows.${el.key}.title`)}</h5>
                    <div>
                      <p>{t(`moreThanShows.${el.key}.content`)}</p>
                      <Button
                        bgColor="var(--red)"
                        color="var(--white)"
                        hoverColor="var(--white)"
                        hoverBgColor="var(--black)"
                        fluid
                        href={t(`moreThanShows.${el.key}.url`)}
                        label={t("button.check-it-out")}
                        tabIndex="-1"
                      />
                    </div>
                  </section>
                </div>
              );
            })}
          </Slider>
        </SecondPartMainMobile>
      )}
    </SliderContainer>
  );
};

const SliderContainer = styled.section`
  height: auto;
  width: 100%;
  a[type="button"] {
    margin-bottom: 0;
  }
`;

const SecondPartMain = styled.main`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  padding: 2% 1%;
  box-sizing: border-box;
  gap: 10px;
  height: 100%;
  min-height: 880px;

  & > div {
    background: #fff;
    flex: 1;
    overflow: hidden;
    transition:
      height 0.2s ease-out,
      transform 0.2s ease-out;
    margin: 0 5px;
    padding: 0;
    color: var(--black);
    border-radius: 15px;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--black);

    .slide-header-image {
      flex-shrink: 0;
      width: 100%;
      height: auto;
      overflow: hidden;
      & > img {
        min-height: 350px;
        width: auto;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &.left {
        & > img {
          object-position: left;
        }
      }
    }

    & > section {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      min-height: 80px;
      & > h5 {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      & > div {
        max-height: 0;
        opacity: 0;

        &.slide-content-active {
          max-height: 100%;
          opacity: 1;
        }
        & > p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          width: auto;
          text-align: left;
          margin: 0px;
          padding: 0px;
        }
        & > a {
          display: block;
        }
      }
    }
    &.active-card {
      flex: 2.2;
      transform: scale(1.05);
      transition: flex 0.2s ease-out;
      z-index: 1;
      border: 2px solid var(--black);
      & > section {
        display: block;
        & > h5 {
          text-align: left;
        }
      }
      .left {
        & > img {
          object-position: center;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1255px) {
    & > div {
      & > section {
        & > h5 {
          font-size: 1rem;
        }
      }
    }
  }
`;

const SecondPartMainMobile = styled.div`
  @media screen and (max-width: 991px) {
    width: calc(100% - 20px);
    overflow-x: hidden;
    padding: 0 20px;
    margin: 0 auto;
    height: 100% !important;

    .slick-slider {
      height: 100% !important;
      .slick-list {
        height: 100% !important;
      }
      .slick-track {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100% !important;
      }
      .slick-slide {
        margin: 0 5px;
        height: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }

    div:nth-child(1) {
      div {
        margin: 0 auto;
        padding: 0 !important;
        outline: none !important;
        height: 100% !important;

        img {
          height: 60%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
        }

        section {
          height: 100%;
          min-height: 200px;
          width: 100%;
          z-index: 100;
          background: var(--white);
          color: var(--black);
          padding: 10px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          h5 {
            margin-top: 0;
          }

          & > div {
            display: block;
            p {
              font-weight: 400;
              text-align: left;
              padding: 0;
              margin: 0;
              font-size: 14px;
              width: 100%;
              letter-spacing: 0;
            }
          }
        }
      }

      .slick-arrow {
        display: none !important;
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
  @media screen and (min-width: 715px) and (max-width: 991px) {
    div:nth-child(1) {
      div {
        section {
          min-height: 210px;
        }
      }
    }
  }
  @media (max-width: 714px) {
    div:nth-child(1) {
      div {
        section {
          min-height: initial;
        }
      }
    }
  }
`;
export default MoreThanShows;
