import { accountActionTypes as actions } from "./account.actions";

export const accountReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.LOGIN:
      return {
        ...state,
        access_token: payload,
      };
    case actions.LOGOUT:
      return {
        ...state,
        access_token: payload,
      };
    case actions.ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: payload,
      };
    case actions.ACCOUNT:
      return {
        ...state,
        account: payload,
      };
    case actions.ACCOUNT_CART:
      return {
        ...state,
        accountCart: payload,
      };
    case actions.TOKEN_EXPIRY:
      return {
        ...state,
        tokenExpiry: payload,
      };
    default:
      return state;
  }
};
