import styled from "styled-components";
// import HomePageHero from "../components/HomePage/HomePageHero";
import VideoComponent from "../components/HomePage/VideoComponent";
import JoinTheClub from "../components/HomePage/JoinTheClub";
// import Merch from "../components/Shop/Merch";
import TitleSection from "../components/TitleSection/TitleSection";
import MoreThanShows from "../components/HomePage/MoreThanShows";
import BbjHero from "../components/Poker/BbjHero";
import { useHomepage } from "../context/homepage/homepage.provider";
import { t } from "i18next";
// import ReactMarkdown from "react-markdown";

const HomePage = () => {
  const { bbjData } = useHomepage();
  return (
    <HomePageDiv>
      {/* <HomePageHero /> */}
        <VideoComponent
          desktop="/videos/Landing-Page-Header-Best-of-2024-Graphics-HD-Compressed.mp4"
          mobile="/videos/Landing-Page-Header-Best-of-2024-Graphics-4x3-Compressed.mp4"
          soundControl={false}
        />
      {/* <VideoWrapper>
        <ReactMarkdown>{t("homepage.bracelet-message")}</ReactMarkdown>
      </VideoWrapper> */}
      {/* <VideoComponent
        desktop="/videos/PG-CommercialHD-Poker-focused.mp4"
        mobile="/videos/PG-CommercialHD-Poker-focused-mobile4x3-30s.mp4"
      /> */}
      <TitleSection
        title={t("homepage.welcome-to-pg")}
        text={t("homepage.welcome-to-pg-text")}
      />
      <MoreThanShows />
      <BbjHero bbjData={bbjData} compact />
      <JoinTheClub />
      {/* <Merch /> */}
    </HomePageDiv>
  );
};

const HomePageDiv = styled.div`
  width: 100%;
`;
// const VideoWrapper = styled.div`
//   position: relative;
//   & > p {
//     position: absolute;
//     width: 100%;
//     bottom: 0;
//     margin: 0;
//     color: var(--white);
//     background-color: rgba(255, 0, 0, 0.5);
//     backdrop-filter: blur(3px) brightness(70%) grayscale(0%);
//     text-align: center;
//     padding: 20px;
//     font-size: 22px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     z-index: 5;
//     text-transform: uppercase;
//   }
//   @media screen and (max-width: 991px) {
//     & > p {
//       font-size: 15px;
//     }
//   }
// `;
export default HomePage;
