import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import { FaArrowCircleDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const HotSeatThursday2023 = () => {
  const [viewBtn, setViewBtn] = useState(false);
  const { i18n } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl(
    "promotions/hot-seat-thursday-2023-bg.jpg"
  );
  return (
    <PromoDetailDiv bgImage={responsiveBgImage}>
      <div>
        {i18n.language === "en" ? (
          <ResponsiveImage
            src="promotions/hot-seat-thursday-2023-logo-en.png"
            alt="cigahot-seat-thursday-2023-logo-en"
          />
        ) : (
          <ResponsiveImage
            src="promotions/hot-seat-thursday-2023-logo-fr.png"
            alt="cigahot-seat-thursday-2023-logo-fr"
          />
        )}
        <div>
          <div>
            <p>
              {i18n.language === "en"
                ? "From 11:00am to 11:00pm"
                : "De 11h00 à 23h00"}
            </p>
            <h2>
              {i18n.language === "en"
                ? "26 winners every Thursday"
                : "26 gagnants chaque jeudi"}
            </h2>
            <FaArrowCircleDown />
          </div>
        </div>
      </div>
      <div>
        {i18n.language === "en" ? (
          <div>
            <p>
              <strong>
                Playground’s latest Hot Seat promotion offers 2 players a chance
                to win $50 in free play every hour!
              </strong>
            </p>
            <p>
              <strong>From 11:00am to 11:00pm</strong>, two winners will be
              randomly selected every 60 minutes from all our active gaming
              machines and table games players and they will be presented with a{" "}
              <strong>$50 free play voucher!</strong> There will be{" "}
              <strong>26 winners every Thursday</strong>, and you could be one
              of them!
            </p>
            <button onClick={() => setViewBtn(!viewBtn)}>
              View Terms and Conditions
              <span></span>
            </button>
            <div className={viewBtn === true ? "ul-active" : undefined}>
              <ul>
                <li>
                  In order to be eligible players must be active on either an
                  gaming machines or table machines.
                </li>
                <li>
                  2 Hot Seat draws will be held every 60 minutes from 11:00am to
                  11:00pm.
                </li>
                <li>
                  The first Hot Seat draw will be held at 11:00am and the last
                  Hot Seat draw will be held at 11:00pm.
                </li>
                <li>
                  Each Hot Seat draw winner will receive a $50 Free Play
                  voucher.
                </li>
                <li>
                  There are a total of 26 winners every Thursday during this
                  promotion.
                </li>
                <li>
                  Free play vouchers are valid on all gaming machines and table
                  games.
                </li>
                <li>
                  All participants must present a valid government-issued photo
                  identification upon request.
                </li>
                <li>
                  Playground reserves the right to change or alter this
                  promotion, as it deems appropriate, including, but not limited
                  to, making changes or alterations to these Rules and
                  Regulations relating to the accommodation of participants.
                </li>
                <li>
                  Patrons with self-excluded or banned status, as determined by
                  Playground, are not eligible for any of the gifts/prizes
                  associated with Playground contests, promotions, tournaments
                  or giveaways.
                </li>
                <li>The promotion is open to all Playground members.</li>
                <li>The promotion takes place at Playground.</li>
                <li>
                  Employees of Playground shall be ineligible to participate in
                  the promotion.
                </li>
                <li>All references to times and dates reflect Eastern Time.</li>
                <li>
                  By participating in the promotion, winners consent to the use
                  of their names and photographs for promotional purposes
                  without additional compensation.
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <p>
              <strong>
                La plus récente promotion Sièges Chanceux du Playground offre
                aux joueurs une chance de gagner 50$ en crédits-jeu toutes les
                60 minutes!
              </strong>
            </p>
            <p>
              <strong>From 11:00am to 11:00pm</strong>, deux gagnants seront
              sélectionnés au hasard toutes les 60 minutes parmi tous nos
              joueurs de machine de jeux et de table de jeux actifs et recevront
              un bon de <strong>50$ en crédits-jeu!</strong> Il y aura{" "}
              <strong>26 gagnants chaque jeudi</strong>, et vous pourrez être
              l'un d'entre eux!
            </p>
            <button onClick={() => setViewBtn(!viewBtn)}>
              Voir les termes et conditions
              <span></span>
            </button>
            <div className={viewBtn === true ? "ul-active" : undefined}>
              <ul>
                <li>
                  Pour être éligible, les joueurs doivent être actifs sur un
                  machine de jeux ou un Jeu de Table Électronique.
                </li>
                <li>
                  2 tirages au sort auront lieu toutes les 60 minutes de 11h00 à
                  23h00.
                </li>
                <li>
                  Le premier tirage au sort aura lieu à 11h00 et le dernier
                  tirage au sort aura lieu à 23h00.
                </li>
                <li>
                  Chaque gagnant du tirage au sort recevra un bon de 50$ en
                  crédits-jeu.
                </li>
                <li>
                  Il y a un total de 26 gagnants chaque jeudi pendant cette
                  promotion.
                </li>
                <li>
                  Les bons de crédits-jeu sont valables sur tous les machines de
                  jeux et Jeux de Table Électroniques.
                </li>
                <li>
                  Tous les participants doivent présenter une pièce d'identité
                  valide avec photo émise par le gouvernement sur demande.
                </li>
                <li>
                  Playground se réserve le droit de modifier ou d'altérer cette
                  promotion, comme il le juge approprié, y compris, mais sans
                  s'y limiter, en apportant des modifications ou des altérations
                  à ces Règles et Règlements relatifs à l'encadrement des
                  participants.
                </li>
                <li>
                  Patrons with self-excluded or banned status, as determined by
                  Playground, are not eligible for any of the gifts/prizes
                  associated with Playground contests, promotions, tournaments
                  or giveaways.
                </li>
                <li>
                  Les clients ayant un statut d'auto-exclusion ou de
                  bannissement, tel que déterminé par Playground, ne sont pas
                  admissibles aux cadeaux/prix associés aux concours, aux
                  promotions, aux tournois ou aux cadeaux promotionnels de
                  Playground.
                </li>
                <li>The promotion takes place at Playground.</li>
                <li>
                  La promotion est ouverte à tous les membres de Playground.
                </li>
                <li>La promotion se déroule au Playground.</li>
                <li>
                  Les employés de Playground ne sont pas autorisés à participer
                  à la promotion.
                </li>
                <li>
                  Toutes les heures et dates mentionnées sont en fonction de
                  l'heure de l'Est.
                </li>
                <li>
                  En participant à la promotion, les gagnants consentent à
                  l'utilisation de leur nom et de leur photo à des fins
                  promotionnelles sans compensation supplémentaire.
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <NavbarSemiTransparentBg></NavbarSemiTransparentBg>
      
    </PromoDetailDiv>
  );
};
const bounce = keyframes`0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-25px);}
	60% {transform: translateY(-10px);}`;
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #e8ebef;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 50% 10% / cover no-repeat fixed;
    z-index: 0;
    height: fit-content;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > img {
      text-align: center;
      margin: 0 auto;
      max-width: 800px;
      width: 100%;
      vertical-align: middle;
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 40px 0;
      & > div {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        color: var(--white);
        & > p {
          font-size: 28px;
          line-height: 30px;
          margin: 10px 0;
        }
        & > h2 {
          margin: 0;
          font-size: 32px;
          line-height: 40px;
          padding: 0 10px;
        }
        & > svg {
          animation: ${bounce} 2s ease infinite;
          width: 50px;
          height: 50px;
          margin-top: 20px;
        }
      }
    }
  }
  & > div:nth-child(2) {
    height: fit-content;
    width: 100%;
    margin-bottom: 20px;
    & > div {
      background: #fff;
      max-width: 1440px;
      width: 100%;
      margin: 40px auto;
      height: 100%;
      padding: 40px;
      & > p:first-child {
        font-size: 18px;
        text-align: left;
        color: #555;
        margin: 0 0 10px;
      }
      & > p:nth-child(2) {
        font-size: 18px;
        text-align: left;
        margin: 0 0 20px;
        color: #555;
      }

      & > button:nth-child(3) {
        width: 100%;
        max-width: 300px;
        border: none;
        height: 40px;
        background-color: #ddd;
        outline: 0;
        color: var(--black);
        margin: 0 auto;
        border-radius: 0;
        padding: 0;
        & > span {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 5px;
          vertical-align: middle;
          border-top: 4px dashed;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
      & > div:nth-child(4) {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        & > ul {
          padding: 20px;
          margin: 20px 0 10px 40px;
          list-style-type: disc;
          & > li {
            padding: 3px;
            font-size: 14px;
            color: #555;
          }
        }
        &.ul-active {
          max-height: 1000px;
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div:first-child {
      background: url(${(props) => props.bgImage}) 50% 10% / cover no-repeat fixed;
      & > div:nth-child(2) {
        padding: 40px 20px;
        & > div {
          & > h2 {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }
    }
    & > div:nth-child(2) {
      & > div {
        padding: 20px 20px 40px 20px;
        & > small:nth-child(5) {
          font-size: 12px;
        }
      }
    }
  }
`;
const NavbarSemiTransparentBg = styled.div`
  top: 0;
  position: absolute;
  height: 144px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
`;
export default HotSeatThursday2023;
