import { useEffect, Suspense } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import { useHomepage } from "./context/homepage/homepage.provider";
import styled from "styled-components/macro";
import smoothscroll from "smoothscroll-polyfill";
import { useAccount } from "./context/account/account.provider";
import { t } from "i18next";

import { setReferrer }        from "./utils/navigation";
import useCheckTokenExpiration from "./utils/useCheckTokenExpiration";

// import rotatePhoneIcon from "./images/icons/mobile-rotate-rotation-icon.svg";
// import { useLayoutEffect } from "react";

// import Navbar                from "./components/Navbar/Navbar";
import HeaderNav                from "./components/Nav/HeaderNav";
import Footer                   from "./components/Footer";
import CookieConsentBanner      from "./components/Auth/CookieConsentBanner";
import FraudWarningModal        from "./components/FraudWarningModal";
import ToastComponent           from "./components/Toast/Toast";
import HoverCart                from "./components/Cart/HoverCart";
import ScrollHandler            from "./components/ScrollHandler";
import EmailConfirmationBanner  from "./components/EmailConfirmationBanner";
import TheRail                  from "./components/Restaurants/TheRail";

import AboutUs                from "./pages/AboutUs";
import Blog                   from "./pages/Blog";
import Careers                from "./pages/Careers";
import CareersFair            from "./pages/careers/CareersFair";
import Checkout               from "./pages/Checkout";
import DiningAndLounges       from "./pages/DiningAndLounges";
import Egd                    from "./pages/Egd";
import EgdGameList            from "./pages/EgdGameList";
import ElectronicTableGames   from "./pages/ElectronicTableGames";
import Entertainment          from "./pages/Entertainment";
import HomePage               from "./pages/HomePage";
import Hotels                 from "./pages/Hotels";
import Legal                  from "./pages/Legal";
import MercierBridgeTraffic   from "./pages/MercierBridgeTraffic";
import MyAccount              from "./pages/MyAccount";
import Page404                from "./pages/Page404";
import Promotions             from "./pages/Promotions";
import PlaygroundCares        from "./pages/PlaygroundCares";
import PlaygroundCaresDetails from "./pages/playgroundCares/PlaygroundCaresDetails";
import PlayersGuide           from "./pages/PlayersGuide";
import PrivacyPolicy          from "./pages/PrivacyPolicy";
import ResponsibleGaming      from "./pages/ResponsibleGaming";
import Rewards                from "./pages/Rewards";
import SmsTermsConditions     from "./pages/SmsTermsConditions";
import VisitUs                from "./pages/VisitUs";
import Unsubscribed            from "./pages/Unsubscribed";

import ForgotPassword     from "./pages/auth/ForgotPassword";
import Login              from "./pages/auth/Login";
import Register           from "./pages/auth/Register";
import ResetPassword      from "./pages/auth/ResetPassword";
import SignUpExistingUser from "./pages/auth/SignUpExistingUser";

import BbjHits                from "./pages/blog/BbjHits";
import KahnawakePowWow        from "./pages/blog/KahnawakePowWow";
import AugustMillionFestival  from "./pages/blog/AugustMillionFestival";
import DiscoverReasonsWsop    from "./pages/blog/DiscoverReasonsWsop";

import MusicalMonday            from "./pages/entertainment/MusicalMonday";
import GirlsNightOut            from "./pages/entertainment/GirlsNightOut";
import GameDay2024              from "./pages/entertainment/GameDay2024";
import HockeyNightAtTheRail2024 from "./pages/entertainment/HockeyNightAtTheRail2024";
import Halloween2024            from "./pages/entertainment/Halloween2024";
import NewYearsEve2025          from "./pages/entertainment/NewYearsEve2025";
import Valentines2025           from "./pages/entertainment/Valentines2025";

import PeaceLoveAndMoney        from "./pages/promotions/egd/PeaceLoveAndMoney";
import WelcomeBackBonus         from "./pages/promotions/egd/WelcomeBackBonus";
import HappyBirthday            from "./pages/promotions/egd/HappyBirthday";
import FollowForFreePlay        from "./pages/promotions/egd/FollowForFreePlay";
import OptInAndWin              from "./pages/promotions/egd/OptInAndWin";
import BoomingWednesday         from "./pages/promotions/egd/BoomingWednesday";
import FreePlayFriday           from "./pages/promotions/egd/FreePlayFriday";
import HollywoodNights          from "./pages/promotions/egd/HollywoodNights";
import JackpotParties           from "./pages/promotions/egd/JackpotParties";
import WalkOfFame2023           from "./pages/promotions/egd/WalkOfFame2023";
import SnackAndSpin             from "./pages/promotions/egd/SnackAndSpin";
import GongXiFaCai              from "./pages/promotions/egd/GongXiFaCai";
import JackpotParties2024       from "./pages/promotions/egd/JackpotParties2024";
import HotSeatThursday2023      from "./pages/promotions/egd/HotSeatThursday2023";
import BigGameBash2024          from "./pages/promotions/egd/BigGameBash2024";
import LeapAndLoot2024          from "./pages/promotions/egd/LeapAndLoot2024";
import InstaWin2024             from "./pages/promotions/egd/InstaWin2024";
import FeteJackpotHebdomadaire  from "./pages/promotions/egd/FeteJackpotHebdomadaire";
import BonVoyage2024            from "./pages/promotions/egd/BonVoyage2024";
import ChaseTheAce2024          from "./pages/promotions/egd/ChaseTheAce2024";
import ChineseNewYear2025       from "./pages/promotions/egd/ChineseNewYear2025";
import ChineseNewYear2025Games  from "./pages/promotions/egd/ChineseNewYear2025Games";
import ChineseNewYear2025Promo  from "./pages/promotions/egd/ChineseNewYear2025Promo";
import ThursdayGiftInventory    from "./pages/promotions/egd/ThursdayGiftInventory";
import ThursdayGiftInventoryTV  from "./pages/promotions/egd/ThursdayGiftInventoryTV";

import BadBeatJackpots        from "./pages/promotions/poker/BadBeatJackpots";
import BadBeatJackpotRules    from "./pages/promotions/poker/BadBeatJackpotRules";
import HighHands              from "./pages/promotions/poker/HighHands";
import TournamentLeaderboard  from "./pages/promotions/poker/TournamentLeaderboard";
import MonterCarlo2024        from "./pages/promotions/poker/MonterCarlo2024";
import IrishOpen2024          from "./pages/promotions/poker/IrishOpen2024";
import IrishOpen2025          from "./pages/promotions/poker/IrishOpen2025";
import MysteryHighHand        from "./pages/promotions/poker/MysteryHighHand";
import MegaMysteryHighHand    from "./pages/promotions/poker/MegaMysteryHighHand";
import MysteryHighHandOctober2024    from "./pages/promotions/poker/MysteryHighHandOctober2024";
import MysteryHighHandNovember2024    from "./pages/promotions/poker/MysteryHighHandNovember2024";
import HighHandDecember2024    from "./pages/promotions/poker/HighHandDecember2024";
import HighHandJanuary2025    from "./pages/promotions/poker/HighHandJanuary2025";
import PokerNewYearsEve2025    from "./pages/promotions/poker/PokerNewYearsEve2025";
import TitanTuesday2025    from "./pages/promotions/poker/TitanTuesday2025";

import ClassicAmericanBreakfast from "./pages/promotions/dining/ClassicAmericanBreakfast";
import PlaygroundBuffets        from "./pages/promotions/dining/PlaygroundBuffets";
import SweetSaturdays           from "./pages/promotions/dining/SweetSaturdays";
import SurfAndTurf2024          from "./pages/promotions/dining/SurfAndTurf2024";

import Poker                    from "./pages/Poker";
import Calendar                 from "./pages/poker/Calendar";
import CashGames                from "./pages/poker/CashGames";
import PastEvents               from "./pages/poker/PastEvents";
import PastEventWinners         from "./pages/poker/PastEventWinners";
import TournamentList           from "./pages/poker/TournamentList"; // Temp Listing Page
import EatAndPlay               from "./pages/poker/EatAndPlay";
import BadBeatJackpotsTv        from "./pages/poker/BadBeatJackpotsTv";
import HighStakesBBJTv          from "./pages/poker/HighStakesBBJTv";
import PokerPlayer              from "./pages/poker/PokerPlayer";
import PokerTableFilmingPolicy  from "./pages/poker/PokerTableFilmingPolicy";


import SuccesfullPayment  from "./pages/confirmation/SuccesfullPayment";
import RequestPassword    from "./pages/confirmation/RequestPassword";
import ExistingUser       from "./pages/confirmation/ExistingUser";
import NewUser            from "./pages/confirmation/NewUser";
import EmailConfirmation  from "./pages/confirmation/EmailConfirmation";


import WorldCupOfCards2021  from "./pages/poker/tournaments/WorldCupOfCards2021/WorldCupOfCards2021";
import TournamentDetails    from "./pages/poker/tournaments/TournamentDetails";
import HowToRedeem          from "./components/Rewards/HowToRedeem";
import TokenExpiryModal     from "./components/TokenExpiryModal";




// import ElectronicGamingDevices from "./pages/ElectronicGamingDevices";

const PrivateRoute = ({ children, access_token }) => {
  return access_token ? (
    children
  ) : (
    <Navigate to={t("routes.homepage")} replace />
  );
};

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { menuBarToggle } = useHomepage();
  const { access_token, tokenExpiry } = useAccount();
  useCheckTokenExpiration();

  // smoothscroll.polyfill();
  //let me know if we actually need this :))
  window.__forceSmoothScrollPolyfill__ = true;
  window.addEventListener("storage", (event) => {
    if (event.key === "access_token") {
      // Force reload the page in the current tab
      window.location.reload();
    }
  });

  useEffect(() => {
    setReferrer(location.pathname, t);
  }, [location.pathname]);

  // const goToTop = () => {
  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
  // };

  // useLayoutEffect(() => {
  //   goToTop();
  // }, []);

  const tvPaths = [
    "/poker/bad-beat-jackpot-tv",
    "/poker/high-stakes-bad-beat-jackpot-tv",
    "/promotions/gaming-machines/gift-inventory-tv"
  ];

  const headerFooterExcludedPaths = [
    ...tvPaths,
    t("routes.register"),
    t("routes.login"),
    t("routes.forgotPassword"),
    t("routes.resetPassword"),
    t("routes.signUpExistingUser"),
  ];

  const hoverCartExcludedPaths = [
    ...headerFooterExcludedPaths,
    t("routes.checkout")
  ];

  const renderPrivateRoute = (element) => (
    <PrivateRoute access_token={access_token}>{element}</PrivateRoute>
  );
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <MainDiv blur={menuBarToggle} className="root">
          <ToastComponent />
          {!tvPaths.includes(location.pathname) && <CookieConsentBanner />}
          {!tvPaths.includes(location.pathname) && <FraudWarningModal />}
          {tokenExpiry && <TokenExpiryModal />}
          {!hoverCartExcludedPaths.includes(location.pathname) && <HoverCart />}
          {!headerFooterExcludedPaths.includes(location.pathname) && <HeaderNav />}
          {access_token && <EmailConfirmationBanner />}
          <ScrollHandler />
          <Routes>
            <Route
              path={t("routes.homepage")}
              element={<HomePage />}
            />
            <Route
              path="/logout"
              element={<Navigate replace to={t("routes.homepage")} />}
            />
            <Route
              path={t("routes.pokerTournamentCalendar")}
              element={<Calendar />}
            />
            <Route
              path={t("routes.diningLounges")}
              element={<DiningAndLounges />}
            />
            <Route path={t("routes.promotions")} element={<Promotions />} />
            <Route
              path={t("routes.promotionsCategory")}
              element={<Promotions />}
            />
            <Route
              path={t("routes.entertainment")}
              element={<Entertainment />}
            />
            <Route
              path={t("routes.entertainmentCategory")}
              element={<Entertainment />}
            />
            <Route path={t("routes.aboutUs")} element={<AboutUs />} />
            <Route path={t("routes.rewards")} element={<Rewards />} />
            <Route path={t("routes.rewardsTab")} element={<Rewards />} />
            <Route path={t("routes.howToRedeem")} element={<Rewards />}>
              <Route path=":tabId" element={<HowToRedeem />} />
            </Route>
            {/* if access token missing redirect those pages to homepage */}
            <Route
              path={t("routes.myAccount")}
              element={renderPrivateRoute(<MyAccount />)}
            />

            <Route
              path={t("routes.checkout")}
              element={renderPrivateRoute(<Checkout />)}
            />
            {/* ---------------------------------------------------------- */}
            <Route
              path={t("routes.playgroundCares")}
              element={<PlaygroundCares />}
            />
            <Route
              path={t("routes.privacyPolicy")}
              element={<PrivacyPolicy />}
            />
            <Route
              path={t("routes.smsTermsConditions")}
              element={<SmsTermsConditions />}
            />
            <Route path={t("routes.legal")} element={<Legal />} />
            <Route
              path={t("routes.responsibleGaming")}
              element={<ResponsibleGaming />}
            />
            <Route path={t("routes.careers")} element={<Careers />} />
            <Route path={t("routes.careersFair")} element={<CareersFair />} />
            <Route path={t("routes.visitUs")} element={<VisitUs />} />
            <Route path={t("routes.hotels")} element={<Hotels />} />
            <Route
              path={t("routes.mercierBridgeTraffic")}
              element={<MercierBridgeTraffic />}
            />
            <Route
              path={t("routes.playgroundCaresDetails")}
              element={<PlaygroundCaresDetails />}
            />
            <Route path={t("routes.electronicTableGames")} element={<ElectronicTableGames />} />
            <Route path={t("routes.electronicTableGameTabs")} element={<ElectronicTableGames />} />
            <Route path={t("routes.playersGuide")} element={<PlayersGuide />} />
            {/* <Route path="/egd" element={<ElectronicGamingDevices />} /> */}
            <Route path={t("routes.register")} element={<Register />} />
            <Route path={t("routes.login")} element={<Login />} />
            {/* egd */}
            <Route path={t("routes.egd")} element={<Egd />} />
            {/* <Route path={t("routes.egdTab")} element={<Egd />} /> */}
            <Route path={t("routes.egdGameList")} element={<EgdGameList />} />
            <Route path={t("routes.egdGameListTab")} element={<EgdGameList />} />
            <Route path={t("routes.snackSpin")} element={<SnackAndSpin />} />
            <Route
              path={t("routes.peaceLoveMoney")}
              element={<PeaceLoveAndMoney />}
            />
            <Route
              path={t("routes.welcomeBackBonus")}
              element={<WelcomeBackBonus />}
            />
            <Route
              path={t("routes.happyBirthday")}
              element={<HappyBirthday />}
            />
            <Route
              path={t("routes.followForFreePlay")}
              element={<FollowForFreePlay />}
            />
            <Route path={t("routes.optInAnWin")} element={<OptInAndWin />} />
            <Route
              path={t("routes.boomingWednesday")}
              element={<BoomingWednesday />}
            />
            <Route
              path={t("routes.sweetSaturdays")}
              element={<SweetSaturdays />}
            />
            <Route
              path={t("routes.surfAndTurf2024")}
              element={<SurfAndTurf2024 />}
            />
            <Route
              path={t("routes.classicAmericanBreakfast")}
              element={<ClassicAmericanBreakfast />}
            />
            <Route
              path={t("routes.playgroundBuffets")}
              element={<PlaygroundBuffets />}
            />
            <Route
              path={t("routes.freePlayFriday")}
              element={<FreePlayFriday />}
            />
            <Route
              path={t("routes.hollywoodNights2023")}
              element={<HollywoodNights />}
            />
            <Route
              path={t("routes.jackpotPartyMay2023")}
              element={<JackpotParties />}
            />
            <Route
              path={t("routes.jackpotPartyFebruary2024")}
              element={<JackpotParties2024 />}
            />
            <Route
              path={t("routes.walkOfFame2023")}
              element={<WalkOfFame2023 />}
            />
            <Route
              path={t("routes.hotSeatThursday2023")}
              element={<HotSeatThursday2023 />}
            />
            <Route
              path={t("routes.gongXiFaCai2024")}
              element={<GongXiFaCai />}
            />
            <Route
              path={t("routes.bigGameBash2024")}
              element={<BigGameBash2024 />}
            />
            <Route
              path={t("routes.leapAndLoop2024")}
              element={<LeapAndLoot2024 />}
            />
            <Route path={t("routes.instawin")} element={<InstaWin2024 />} />
            <Route
              path={t("routes.weeklyJackpotParty")}
              element={<FeteJackpotHebdomadaire />}
            />
            <Route
              path={t("routes.bonVoyage2024")}
              element={<BonVoyage2024 />}
            />
            <Route
              path={t("routes.chaseTheAce2024")}
              element={<ChaseTheAce2024 />}
            />
            <Route
              path={t("routes.chineseNewYear2025")}
              element={<ChineseNewYear2025 />}
            />
            <Route
              path={t("routes.chineseNewYear2025Games")}
              element={<ChineseNewYear2025Games />}
            />
            <Route
              path={t("routes.chineseNewYear2025Promo")}
              element={<ChineseNewYear2025Promo />}
            />
            <Route
              path="/promotions/gaming-machines/gift-inventory"
              element={<ThursdayGiftInventory />}
            />
            <Route
              path="/promotions/gaming-machines/gift-inventory-tv"
              element={<ThursdayGiftInventoryTV />}
            />
            {/* poker */}
            <Route
              path={t("routes.pokerTableFilmingPolicy")}
              element={<PokerTableFilmingPolicy />}
            />
            <Route
              path={t("routes.badBeatJackpot")}
              element={<BadBeatJackpots />}
            />
            <Route
              path="/poker/bad-beat-jackpot-tv"
              element={<BadBeatJackpotsTv />}
            />
            <Route
              path="/poker/high-stakes-bad-beat-jackpot-tv"
              element={<HighStakesBBJTv />}
            />
            <Route
              path={t("routes.badBeatJackpotRules")}
              element={<BadBeatJackpotRules />}
            />
            <Route path={t("routes.highHands")} element={<HighHands />} />
            <Route
              path={t("routes.worldCupOfCards2021")}
              element={<WorldCupOfCards2021 />}
            />

            {/* POKER TOURNAMENT URLS WPT/WSOP */}
            <Route path="/poker/tournaments/:tournamentSlug" element={<TournamentDetails />}>
              <Route path=":tournamentTabSlug" element={<TournamentDetails />}>
                <Route path=":chipCountSlug" element={<TournamentDetails />} />
              </Route>
            </Route>
            <Route path="/fr/poker/tournois/:tournamentSlug" element={<TournamentDetails />}>
              <Route path=":tournamentTabSlug" element={<TournamentDetails />}>
                <Route path=":chipCountSlug" element={<TournamentDetails />} />
              </Route>
            </Route>

            {/* TEMP PAGE TO LIST TOURNAMENT URLS */}
            <Route
              path={t("routes.tournamentList")}
              element={<TournamentList />}
            />

            <Route
              path={t("routes.eatAndPlay")}
              element={<EatAndPlay />}
            />
            <Route
              path={t("routes.eatAndPlayTab")}
              element={<EatAndPlay />}
            />
            <Route path={t("routes.cashGames")} element={<CashGames />} />
            <Route
              path={t("routes.pastEvents")}
              element={<PastEvents />}
            />
            <Route
              path={t("routes.pastEventWinners")}
              element={<PastEventWinners />}
            />
            <Route path={t("routes.tlb")} element={<TournamentLeaderboard />} />
            <Route
              path={t("routes.monteCarloJackpots2024")}
              element={<MonterCarlo2024 />}
            />
            <Route
              path={t("routes.irishOpen2024")}
              element={<IrishOpen2024 />}
            />
            <Route
              path={t("routes.irishOpen2025")}
              element={<IrishOpen2025 />}
            />
            <Route
              path={t("routes.mysteryHighHand2024")}
              element={<MysteryHighHand />}
            />
            <Route
              path={t("routes.megaMysteryHighHand2024")}
              element={<MegaMysteryHighHand />}
            />
            <Route
              path={t("routes.mysteryHighHandOctober2024")}
              element={<MysteryHighHandOctober2024 />}
            />
            <Route
              path={t("routes.mysteryHighHandNovember2024")}
              element={<MysteryHighHandNovember2024 />}
            />
            <Route
              path={t("routes.highHandDecember2024")}
              element={<HighHandDecember2024 />}
            />
            <Route
              path={t("routes.highHandJanuary2025")}
              element={<HighHandJanuary2025 />}
            />
            <Route
              path={t("routes.chaseTheAce2024")}
              element={<ChaseTheAce2024 />}
            />
            <Route
              path={t("routes.pokerNewYearsEve2025")}
              element={<PokerNewYearsEve2025 />}
            />
            <Route
              path={t("routes.titanTuesday2025")}
              element={<TitanTuesday2025 />}
            />

            <Route path={t("routes.pokerPlayer")} element={<PokerPlayer />} />
            <Route exact="true" path={t("routes.poker")} element={<Poker />} />
            {/* confirmation page */}
            <Route
              path={t("routes.successfulPayment")}
              element={<SuccesfullPayment />}
            />
            <Route
              path={t("routes.requestPasswordSuccess")}
              element={<RequestPassword />}
            />
            <Route
              path={t("routes.unsubscribed")}
              element={<Unsubscribed />}
            />
            <Route
              path={t("routes.addExistingUserSuccess")}
              element={<ExistingUser />}
            />
            <Route
              path={t("routes.signUpExistingUser")}
              element={<SignUpExistingUser />}
            />
            <Route path={t("routes.addNewUserSuccess")} element={<NewUser />} />
            <Route
              path={t("routes.emailVerification")}
              element={<EmailConfirmation />}
            />
            <Route
              path={t("routes.resetPassword")}
              element={<ResetPassword />}
            />
            <Route
              path={t("routes.forgotPassword")}
              element={<ForgotPassword />}
            />
            {/* entertainment */}
            <Route
              path={t("routes.musicalMonday")}
              element={<MusicalMonday />}
            />
            <Route
              path={t("routes.girlsNightOut")}
              element={<GirlsNightOut />}
            />
            <Route
              path={t("routes.gameDay2024")}
              element={<GameDay2024 />}
            />
            <Route
              path={t("routes.hockeyNightAtTheRail2024")}
              element={<HockeyNightAtTheRail2024 />}
            />
            <Route
              path={t("routes.halloween2024")}
              element={<Halloween2024 />}
            />
            <Route
              path={t("routes.newYearsEve2025")}
              element={<NewYearsEve2025 />}
            />
            <Route
              path={t("routes.valentines2025")}
              element={<Valentines2025 />}
            />
            {/* blog */}
            <Route exact="true" path={t("routes.blog")} element={<Blog />} />
            <Route path={t("routes.blogBbjHits")} element={<BbjHits />} />
            <Route
              path={t("routes.blogKahnawakePowWow")}
              element={<KahnawakePowWow />}
            />
            <Route
              path={t("routes.blogAugustMillionFestival")}
              element={<AugustMillionFestival />}
            />
            <Route
              path={t("routes.blogDiscoverReasonsWsop")}
              element={<DiscoverReasonsWsop />}
            />
            {/* restaurants */}
            <Route path={t("routes.theRail")} element={<TheRail />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
          {!headerFooterExcludedPaths.includes(location.pathname) && <Footer />}
        </MainDiv>
      </Suspense>
    </I18nextProvider>
  );
}

const MainDiv = styled.div`
  min-height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  & > *:not(.navbar) {
    filter: ${({ blur }) => (blur ? "blur(2px) brightness(50%)" : "none")};
  }
`;

export default App;
