import React from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { useIsMdScreenUp } from "src/utils/windowUtils";

const PasswordTooltip = ({ children }) => {
  const { t } = useTranslation();

  const validations = ["uppercase", "lowercase", "number", "length"];

  const renderTooltip = (props) => (
    <CustomTooltip id="password-tooltip" {...props}>
      <ul>
        {validations.map((validation, index) => (
          <li key={index}>{t(`auth.passwordValidations.${validation}`)}</li>
        ))}
      </ul>
    </CustomTooltip>
  );

  return (
    <OverlayTrigger
      placement={useIsMdScreenUp() ? "right" : "bottom"}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Div>{children}</Div>
    </OverlayTrigger>
  );
};
const CustomTooltip = styled(Tooltip)`
  max-width: 300px;
  width: 300px;
  ul {
    text-align: left;
  }
`;
const Div = styled.div`
  display: flex;
  align-content: space-around;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  .form-link {
    cursor: default;
  }
  &:hover {
    .form-link {
      color: var(--white);
    }
  }
`;
export default PasswordTooltip;
