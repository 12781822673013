import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  // Dynamically generate content keys for looping
  const contents = Array.from({ length: 11 }, (_, index) => `content-${index + 1}`);

  return (
    <PrivacyPolicyDiv>
      <div>
        <h1>{t("privacy.title")}</h1>
        <span></span>
        <div>
          <ol>
            {contents.map((key) => {
              const content = t(`privacy.${key}`, { returnObjects: true }); // Fetch content dynamically
              return (
                <li key={key}>
                  <h3>{content.title}</h3>
                  <ReactMarkdown>{content.text}</ReactMarkdown>
                </li>
              );
            })}
          </ol>
          <p className="updated">{t("privacy.bottom-text")}</p>
        </div>
      </div>
    </PrivacyPolicyDiv>
  );
};

const PrivacyPolicyDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: var(--black);
  color: var(--white);

  & > div {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 140px 20px;

    & > h1 {
      text-align: center;
      font-weight: bold;
      margin: 0;
    }

    & > span {
      display: block;
      width: 100%;
      max-width: 180px;
      height: 2px;
      background-color: var(--red);
      margin: 10px auto;
    }

    & > div {
      margin-top: 20px;
      background: var(--lightblack);
      padding: 20px;
      ul {
        margin-bottom: 20px;
      }
      & > ol {
        padding-left: 20px;

        & > li {
          margin-bottom: 20px;

          & > h3 {
            font-weight: 600;
            margin-bottom: 10px;
          }

          & > div {
            margin-bottom: 10px;
          }
        }
      }

    }
  p.updated {
    margin-top: 50px;
    margin-bottom: 20px;
    padding-left: 20px;
    font-size: 0.8rem;
  }
  }
`;

export default PrivacyPolicy;
