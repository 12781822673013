import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import ReactMarkdown from "react-markdown";
import EventFinished from "src/components/EventFinished";
import TitleSection from "src/components/TitleSection/TitleSection";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { BiSolidDollarCircle } from "react-icons/bi";
import { LuAlarmClock } from "react-icons/lu";
import { Row, Col } from "react-bootstrap";
import WaitingListInfo from "src/components/Poker/WaitingListInfo";
import GameListLink from "src/components/Poker/GameListLink";

const TitanTuesday2025 = () => {
  const { i18n, t } = useTranslation();
  const lng = i18n.language;

  return (
    <Div>
      <EventFinished scheduleKeyName="titanTuesday2025" eventType="promotion"/>
      <Hero
        bgImage="promotions/poker/titan-tuesday-2025/TT-hero-image-2400x800px.jpg"
        image={`promotions/poker/titan-tuesday-2025/TT-logo-2400x2400px-${i18n.language}.png`}
        imageAlt="Titan Tuesday 2025 | Les mardis Titan"
      >
        <container-div>
          <TitleSection
            title={t("poker.titanTuesday2025.title")}
            className="pt-0 pb-0"
          />

          <ReactMarkdown className="hero-content">{t("poker.titanTuesday2025.subtitle")}</ReactMarkdown>
          <p>
            <strong>
            {t("poker.titanTuesday2025.time")}
            </strong>
          </p>

        </container-div>
      </Hero>

      <container-div>
        <div className="hero-box">
          <p className="mega-prizes">{t("poker.titanTuesday2025.payout")}
          </p>
          <p className="bonus">
            {t("poker.titanTuesday2025.joinUs")}{" "}
            {(() => {
              const today = new Date();
              if (today < new Date("2025-01-22")) {
                return t("poker.titanTuesday2025.tuesday_1");
                // January 21
              } else if (today < new Date("2025-02-19")) {
                return t("poker.titanTuesday2025.tuesday_2");
                // February 18
              } else {
                return t("poker.titanTuesday2025.tuesday_3");
                // March 18
              }
            })()}
          </p>
        </div>
        <ReactMarkdown>
          {t("poker.titanTuesday2025.content.intro1")}
        </ReactMarkdown>
        <ReactMarkdown>
          {t("poker.titanTuesday2025.content.intro2")}
        </ReactMarkdown>
        <Row className="periods pb-3">
          <Col>
            <LuAlarmClock />
            <TitleSection
              as={"h3"}
              title={t("poker.titanTuesday2025.content.qualifyingPeriods.title")}
              text={t("poker.titanTuesday2025.content.qualifyingPeriods.text")}
              className="py-0"
            />
          </Col>
          <Col>
            <BiSolidDollarCircle />
            <TitleSection
              as={"h3"}
              title={t("poker.titanTuesday2025.content.payoutPeriods.title")}
              text={t("poker.titanTuesday2025.content.payoutPeriods.text")}
              className="py-0"
            />
          </Col>
        </Row>
        <WaitingListInfo />
        <GameListLink />
      </container-div>

      <div className="rules">
        <container-div>
          <SubTitleSection as={"h2"} subTitle={t("poker.titanTuesday2025.content.rules.title")} className="pt-0" />
          <ReactMarkdown>
            {t("poker.titanTuesday2025.content.rules.content")}
          </ReactMarkdown>
        </container-div>
      </div>
    </Div>
  );
};
const Div = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;
  .hero_text_container {
    strong {
      color: #ff8f00;
    }
    h1 {
      font-weight: 900;
    }
    .hero-content {
      margin-top: 0.5rem;
      p {
        font-size: 26px;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 0;
    }
  }
  .hero-box {
    background-color: var(--red);
    margin: 10px auto 40px;
    padding: 15px;
    width: 100%;
    color: #fff;
    font-weight: 700;
    text-align: center;
    .bonus {
      margin-top: 0px;
      padding-bottom: 0px;
      line-height: 45px;
      font-size: 2em;
    }
    .draws {
      font-size: 18px;
      margin-top: -14px;
    }
    .mega-prizes {
      font-family: 'Poppins';
      background-color: #000;
      padding: 5px;
      font-size: 20px;
      font-weight: 700;
      display: block;
    }
  }

  p {
    color: #fff;
    text-align: center;
    strong {
      color: #ff8f00;
    }
  }

  div.need-to-know {
    padding: 60px 20px 0;
    width: 100%;
    max-width: 1440px;
    height: auto;
    margin: 0 auto;
    color: var(--white);
    h2 {
      font-weight: 700;
      font-size: 20px;
      margin-top: 0px;
      padding-bottom: 20px;
      text-transform: uppercase;
    }
  }
  div.periods {
    text-align: center;
    svg {
      margin: 20px 0 10px;
      color: var(--red);
      font-size: 40px;
    }
  }
  div.info-box {
    margin-top: 2rem;
    background-color: #333;
    padding: 20px;
    text-align: center;
    p {
      color: #aaa;
    }
  }
  div.rules {
    padding: 40px 20px;
    background-color: #111;
    margin-top: 40px;
    color: #999;
    h2 {
      font-size: 25px;
    }
    & > ol {
      margin-top: 0;
      margin-bottom: 10px;
      & > li {
        font-size: 16px;
        color: #999;
      }
    }
  }
`;
export default TitanTuesday2025;
