import { useReducer, useMemo, useContext, useEffect, useCallback } from "react";
import { useToast } from "../toast/toast.provider";
import { accountReducer } from "./account.reducer";
import { accountActionTypes as actions } from "./account.actions";
import { AccountState } from "./account.state";
import { show } from "../../api/accounts";
import { add_item, cart, remove_item } from "../../api/cart";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const ACCOUNT_STATE = {
  access_token: Cookies.get('access_token') || "",
  accountInfo: null,
  account: null,
  accountCart: [],
  tokenExpiry: false,
};

export const AccountStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, ACCOUNT_STATE);
  const { showToastFn } = useToast();
  const { t } = useTranslation();

  const value = useMemo(
    () => ({
      ...state,
      login: async (value) => {
        dispatch({ type: actions.LOGIN, payload: value.access_token });
        dispatch({ type: actions.ACCOUNT_CART, payload: value.access_token });
        showToastFn({
          show: true,
          title: t("toast.login.success.title"),
          message: t("toast.login.success.message"),
          variant: "success"
        });
        Cookies.set('access_token', value.access_token);
        Cookies.set('refresh_token', value.refresh_token);
      },
      logout: async () => {
        dispatch({ type: actions.LOGOUT, payload: null });
        dispatch({ type: actions.ACCOUNT_CART, payload: [] });
        showToastFn({
          show: true,
          title: t("toast.logout.success.title"),
          message: t("toast.logout.success.message"),
          variant: "success"
        });
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
      },
      accountInfoFn: (value) => {
        dispatch({ type: actions.ACCOUNT_INFO, payload: value });
      },
      accountCartFn: (value) => {
        dispatch({ type: actions.ACCOUNT_CART, payload: value });
      },
      accountCartUpdateFn: async (action, gui) => {
        if (action === "remove") {
          try {
            // Call API to remove item
            await remove_item(gui);
            // Refetch item list data to reflect changes
            fetchData();
            showToastFn({
              show: true,
              title: t("toast.cart.itemRemoved.success.title"),
              message: t("toast.cart.itemRemoved.success.message"),
              variant: "success"
            });
          } catch (error) {
            console.error("Error removing item:", error);
            showToastFn({
              show: true,
              title: t("toast.cart.itemRemoved.error.title"),
              message: t("toast.cart.itemRemoved.error.message"),
              variant: "danger"
            });
          }
        }
        if (action === "add") {
          try {
            // Call API to remove item
            await add_item(gui);
            // Refetch item list data to reflect changes
            fetchData();
            showToastFn({
              show: true,
              title: t("toast.cart.itemAdded.success.title"),
              message: t("toast.cart.itemAdded.success.message"),
              variant: "success"
            });
          } catch (error) {
            console.error("Error adding item:", error);
            showToastFn({
              show: true,
              title: t("toast.cart.itemAdded.error.title"),
              message: t("toast.cart.itemAdded.error.message"),
              variant: "danger"
            });
          }
        }
      },
      tokenExpiryFn: (value) => {
        dispatch({ type: actions.TOKEN_EXPIRY, payload: value });
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const data = await show();
        value.accountInfoFn(data);
      } catch (error) {
        console.error("Error fetching account information:", error);
        Cookies.remove("access_token"); // Remove token from local storage
        value.logout(); // Update token state
        // Implement additional error handling if needed
      }
    };

    // Fetch user account information only if access token exists
    if (state.access_token) {
      fetchAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.access_token]);

  const fetchData = useCallback(async () => {
    try {
      const response = await cart();
      if (response.data) {
        value.accountCartFn(response.data);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
      showToastFn({
        show: true,
        title: t("toast.cart.fetchCart.error.title"),
        message: t("toast.cart.fetchCart.error.message"),
        variant: "danger"
      });
    }
  }, [state.cart]);

  // Update local storage and fetch data initially
  useEffect(() => {
    fetchData();
  }, [state.access_token]);

  return (
    <AccountState.Provider value={value}>{children}</AccountState.Provider>
  );
};

export const useAccount = () => useContext(AccountState);
