import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { Row, Col, Spinner } from "react-bootstrap";
import PgCard from "src/components/Card/PgCard";
import { useErs } from "src/context/ers/ers.provider";
import { useTranslation } from "react-i18next";

const ThursdayGiftInventory = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useErs(); // Fetch API data
  const [loading, setLoading] = useState(true); // State for loading spinner
  const [error, setError] = useState(null); // State for error handling
  useEffect(() => {
    setLoading(true); // Set loading to true before fetching
    fetchData(`imagine-this-2025-prize-counter`)
      .then((response) => {
        setLoading(false); // Set loading to false after successful fetch
        setError(null); // Clear any previous error
      })
      .catch((err) => {
        setLoading(false); // Set loading to false on error
        setError(err.message || t("error.somethingWentWrong")); // Set error message
        console.error("Error fetching entertainment data:", err);
      });
  }, [fetchData]);

  const contentItems = data[`imagine-this-2025-prize-counter`];
  return (
    <>
      <Div>
        <Hero
          arrowBounce="true"
          bgImage="ers/imagine-this-2025-prize-counter/LP-Thursday-Gift-Inventory-Banner.jpg"
          buttonLink=""
          buttonText=""
          descriptionBgColor="none"
        />
        <container-div>
          <div className="grid-content">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">{t("loading")}</span>
                </Spinner>
              </div>
            ) : error ? (
              <div className="text-center text-danger">
                <p>
                  {t("error.somethingWentWrong")}: {error}
                </p>
              </div>
            ) : (
              <Row>
                {Object.values(contentItems)?.map((item, index) => (
                  <Col xs={12} md={6} lg={4} key={item?.nameen || index}>
                    {" "}
                    {/* Use item.id if available */}
                    <PgCard
                      image={
                        item?.count === 0
                          ? item?.imagepath.replace(/(\.[\w\d_-]+)$/i, "-NA$1")
                          : item?.imagepath
                      }
                      imageAlt={item?.nameen}
                      title={item?.namefr}
                      subtitle={item?.nameen}
                      highlightColor={item?.color}
                      highlightTextColor="#fff"
                      variant="portrait"
                      className="item-card"
                      style={{ color: item?.nameen ? "#fff" : "#000" }}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </container-div>
      </Div>
    </>
  );
};

const Div = styled.div`
  background: var(--black);
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--white);

  .hero_container {
    min-height: 500px;
    margin-bottom: 20px;
  }
  .hero_text_container {
    display: none;
  }
  .card-text {
    width: 100%;
    p {
      margin: 0;
    }
  }
  .card-body {
    padding: 10px;
  }
  .card-title {
    h3 {
      font-size: 16px;
    }
  }
  .item-card {
    /* max-height: 500px; */
    /* max-width: auto; */
    /* margin: 20px 10px; */
    margin-bottom: 20px;
    .card-image-top {
      aspect-ratio: 9 / 9;
      img {
        object-fit: contain;
      }
    }
    .card-text-wrapper {
      /* position: relative;
      width: 100%;
      top: 0; */
      .card-header {
        border-bottom: none;
      }
      &.portrait {
        top: unset !important;
        bottom: 20px !important;
        width: 100%;
      }
      .card-body {
        .cart-text {
          width: 100%;
          p {
            margin: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
export default ThursdayGiftInventory;
