import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const HashTabs = ({ defaultActiveKey, id, children, tabs }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  // Synchronize activeKey with the hash in the URL
  const syncHashWithActiveKey = useCallback(() => {
    const hash = location.hash.slice(1); // Get hash without "#"
    const index = tabs?.indexOf(hash);
    if (index !== -1) {
      setActiveIndex(index);
      setActiveKey(tabs[index]);
    }
  }, [tabs]);

  // Sync activeKey with the hash on initial load and hash changes
  useEffect(() => {
    syncHashWithActiveKey();
    window.addEventListener("hashchange", syncHashWithActiveKey);

    return () => {
      window.removeEventListener("hashchange", syncHashWithActiveKey);
    };
  }, [syncHashWithActiveKey]);

  // Update the activeKey when the language changes
  useEffect(() => {
    setActiveKey(tabs[activeIndex]);
    if (activeIndex !== 0) {
      const newHash = `#${tabs[activeIndex]}`;
      const newUrl = `${window.location.pathname}${window.location.search}${newHash}`;
      window.history.replaceState(null, "", newUrl);
    } else {
      const newUrl = `${window.location.pathname}${window.location.search}`;
      // window.history.replaceState(null, "", newUrl);
    }
  }, [i18n.language, tabs]);

  // Update the hash in the URL when activeKey changes
  useEffect(() => {
    if (activeIndex !== 0) {
      const newHash = `#${activeKey}`;
      const newUrl = `${window.location.pathname}${window.location.search}${newHash}`;
      window.history.replaceState(null, "", newUrl);
    }
  }, [activeKey, activeIndex]);

  return (
    <StyledTabs
      id={id}
      activeKey={activeKey}
      onSelect={(key) => {
        const index = tabs.indexOf(key);
        if (index !== -1) {
          setActiveIndex(index);
          setActiveKey(key);
          if (index !== 0) {
            navigate(`#${key}`);
          } else {
            window.history.replaceState(null, "", location.pathname);
          }
        }
      }}
      fill
    >
      {children}
    </StyledTabs>
  );
};

HashTabs.propTypes = {
  defaultActiveKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const StyledTabs = styled(Tabs)`
  position: relative;
  z-index: 2;
  margin-bottom: 0 !important;
  border-bottom-color: #ab7c1d;

  .nav-link {
    cursor: pointer !important;
    color: var(--mustard);
    border: 0px;

    &:hover,
    &.active {
      color: var(--black) !important;
      cursor: default;
      background-color: #d79c23;
    }
  }

  background-color: var(--lightblack);
  font-weight: 700;
`;

export default HashTabs;