import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Button from '../../components/Button/Button';
import TitleSection     from "src/components/TitleSection/TitleSection";
import styled from 'styled-components/macro';
import { toUrlFriendlyString } from '../../utils/formatters';
import ResponsiveImage from '../ImageWrapper/ResponsiveImage';


const HospitalityUnit = ({ name, imgSrc, imgAlt, logoSrc, logoAlt, title, text, link, buttons, imageSide, className, logoLink, reservationUrl }) => {
// console.log(imgSrc)
  return (
    <UnitContainer
     key={toUrlFriendlyString(name)}
     id={toUrlFriendlyString(name)}
     imageSide={imageSide}
     className={`unit_container ${className}`}>
      <ImgContainer className="hospitality-image">
        <UnitImg src={imgSrc} alt={`${imgAlt}`} />
      </ImgContainer>
      <DetailsContainer>
        <a
          href={logoLink || link}
          target={(logoLink || link).includes('https://') ? '_blank' : '_self'}
          rel={(logoLink || link).includes('https://') ? 'noopener noreferrer' : undefined}
        >
          <LogoImage src={logoSrc} alt={logoAlt} bgImage={imgSrc} className="unit-logo"/>
        </a>

        <TitleSection as={'h3'} title={title} text={text} />
        <ButtonContainer className="gx-3 g-3">
          {buttons && Object.keys(buttons).map((key) => (
            <Col key={buttons[key].link}>
              <Button
                href={buttons[key].link}
                label={buttons[key].text}
                fluid
              />
            </Col>
          ))}
        </ButtonContainer>
      </DetailsContainer>
    </UnitContainer>
  );
};

const UnitContainer = styled.div`
  p, h3 {
    color: white;
    text-align: center;
  }
  display: flex;
  flex-direction: column;
  margin: 2rem 0 5rem;
  @media (min-width: 992px) {
    flex-direction: ${({ imageSide }) => (imageSide === 'right' ? 'row-reverse' : 'row')};
    gap: 2rem;
  }
`;


const ImgContainer = styled.div`
  @media (min-width: 992px) {
    width: 50%;
  }
`;
const UnitImg = styled(ResponsiveImage)`
  max-height: 230px;
  width: 100%;
  object-fit: cover;
  @media (min-width: 992px) {
      width: auto;
      height: 100%;
      max-height: initial;
  }
`;

const DetailsContainer = styled.div`

  display: flex;
  flex-direction: column;
  h3 {
    font-size: 1.8rem;
    font-weight: 900;
  }
  p {
    font-size: 1rem!important;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
`;

const LogoImage = styled(ResponsiveImage)`
  max-width: 300px;
  width: 100%;
  max-height: 200px;
  height: auto;
  margin: -4rem auto 0;
  object-fit: contain;
  @media (min-width: 992px) {
    margin-top: 0;
  }
`;

const ButtonContainer = styled(Row)`
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
  a {
    margin: 0;
    white-space: nowrap;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export default HospitalityUnit;
