import moment from 'moment';
import scheduleConfig from 'src/config/scheduleConfig';

/**
 * Fetches the schedule dates (publish_at and unpublish_at) for a given key.
 *
 * @param {string} key - The key to look up in the scheduleConfig.
 * @returns {Object} - The schedule data for the key, or an empty object if not found.
 */
export const getScheduleDates = (key) => {
  return scheduleConfig[key] || {};
};

/**
 * Determines if an event card should render based on the event's publish and unpublish dates.
 * The event should render if the current time is between 5 AM on the publish date and 5 AM on the unpublish date,
 * or if no unpublish date is provided, it should render after the publish date.
 *
 * @param {Object} event - The event object containing publish and unpublish dates.
 * @param {string} event.publish_at - The publish date of the event (YYYY-MM-DD).
 * @param {string} [event.unpublish_at] - The unpublish date of the event (YYYY-MM-DD, optional).
 * @returns {boolean} - Returns true if the event should render, false otherwise.
 *
 * @example
 * // Example 1: Event between publish and unpublish date
 * const event1 = {
 *   publish_at: "2024-09-12",
 *   unpublish_at: "2024-09-19"
 * };
 * const shouldRender1 = shouldPublish(event1);
 * // Returns true if today's date is between 2024-09-12 and 2024-09-19.
 *
 * @example
 * // Example 2: Event with only publish date (no unpublish date)
 * const event2 = {
 *   publish_at: "2024-09-12"
 * };
 * const shouldRender2 = shouldPublish(event2);
 * // Returns true if today's date is after 2024-09-12.
 *
 * @example
 * // Example 3: Event before publish date
 * const event3 = {
 *   publish_at: "2024-09-20",
 *   unpublish_at: "2024-09-22"
 * };
 * const shouldRender3 = shouldPublish(event3);
 * // Returns false if today's date is before 2024-09-20.
 *
 * @example
 * // Example 4: Event before publish date
 * // Returns false if today's date is after unpublish_at.
 */
export const shouldPublish = (event) => {
  // Uncomment the line below to ignore publish dates and show all slides in development
  // if (process.env.NODE_ENV == 'development') return true

  const now = moment();
  const publish_at = event.publish_at ? moment(event.publish_at).set({ hour: 5, minute: 0 }) : null;
  const unpublish_at = event.unpublish_at ? moment(event.unpublish_at).set({ hour: 5, minute: 0 }) : null;

  if (!publish_at) return false; // If no publish date, don't render

  // Check if event is currently within publish and unpublish dates
  if (unpublish_at) {
    return now.isAfter(publish_at) && now.isBefore(unpublish_at);
  }

  // If there's no unpublish date, it should always render after publish date
  return now.isAfter(publish_at);
};

/**
 * Filters an array of links based on their publish/unpublish schedules.
 * Links with a `scheduleKeyName` are checked against their schedules.
 * Nested links are recursively filtered.
 * Links with no scheduleKeyName (permanent links) or that pass the shouldPublish() checks are kept.
 *
 * @param {Array} links - Array of navigation links to filter.
 * @returns {Array} - Filtered array of links.
 */
export const filterNavbarLinks = (links) => {
  return links.map((link) => {
    if (link.items) {
      // Recursively filter nested items
      return { ...link, items: filterNavbarLinks(link.items) };
    }

    if (link.scheduleKeyName) {
      // Get schedule dates for the link
      const schedule = getScheduleDates(link.scheduleKeyName);
      // console.log(`Checking schedule for ${link.title}:`, schedule);

      // Exclude the link if it shouldn't be shown
      if (!shouldPublish(schedule)) {
        // console.log(`Excluding link: ${link.title}`);
        return null;
      }
    }

    return link; // Keep the link if no scheduleKeyName or it passes the checks
  }).filter(Boolean); // Remove null entries
};
