import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { i18n } from "i18next";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const HighHands = () => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  const [viewBtn, setViewBtn] = useState(false);
  const schedule = t("poker.high-hands.schedule", { returnObjects: true });
  return (
    <PromoDetailDiv>
      <FirstDiv
        bgImage={useResponsiveImageUrl("promotions/daily-high-hands-bg.jpg")}
      >
        <div>
          <ResponsiveImage
            src={`carousel/high-hands-logo_${lng}.png`}
            alt="high-hands-logo"
          />
          <div>
            <h1>{t("poker.high-hands.header-title")}</h1>
            <h2>{t("poker.high-hands.header-text-1")}</h2>
            <ReactMarkdown>{t("poker.high-hands.header-text-2")}</ReactMarkdown>
            <ReactMarkdown>{t("poker.high-hands.header-text-3")}</ReactMarkdown>
          </div>
        </div>
      </FirstDiv>
      <SecondDiv>
        <div>
          <Table>
            <thead>
              <tr>
                <th colSpan="2">
                  <ReactMarkdown>
                    {t("poker.high-hands.table-title")}
                  </ReactMarkdown>
                </th>
              </tr>
              <tr>
                <th>{t("poker.high-hands.table-nav-1")}</th>
                <th>{t("poker.high-hands.table-nav-2")}</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((item, index) => (
                <tr key={index}>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p>{t("poker.high-hands.table-bottom-text")}</p>
          <button onClick={() => setViewBtn(!viewBtn)}>
            {t("poker.high-hands.gen-rul-btn")}
            <span></span>
          </button>
          <div className={viewBtn ? "ul-active" : undefined}>
            <ol>
              {Array.from({ length: 21 }, (_, i) => i + 1).map((number) => (
                <li key={number}>
                  <ReactMarkdown>
                    {t(`poker.high-hands.table-li-${number}`)}
                  </ReactMarkdown>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </SecondDiv>
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  background: #e6e6e6;
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
const FirstDiv = styled.div`
  padding: 130px 0px 60px;
  background: url(${(props) => props.bgImage}) 50% 30%;
  z-index: 1;
  position: relative;
  width: 100%;
  height: fit-content;
  &::after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
  & > div {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    height: fit-content;
    & > img:first-child {
      width: auto;
      margin: 0 auto;
      display: block;
      max-width: 700px;
      height: auto;
    }
    & > div:last-child {
      padding: 20px;
      background-color: rgba(20, 31, 41, 0.7);
      width: 100%;
      & > h1 {
        font-size: 32px;
        line-height: 35px;
        color: var(--white);
        text-align: center;
        font-weight: 700;
        margin: 0px;
        margin-bottom: 10px;
      }
      & > h2 {
        font-size: 23px;
        line-height: 1.35em;
        padding: 0 20% 20px;
        color: var(--white);
        text-align: center;
        font-weight: 700;
        margin: 0px;
      }
      & > p {
        margin: 0;
        margin-bottom: 15px;
        color: var(--white);
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        line-height: 25px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div {
      & > img:first-child {
        padding: 0 15px;
        max-width: 300px;
      }
      & > div:last-child {
        & > h1 {
          font-size: 28px;
        }
        & > h2 {
          padding: 0 5% 20px;
          line-height: 1.35em;
          font-size: 20px;
        }
      }
    }
  }
`;
const SecondDiv = styled.div`
  & > div {
    width: 100%;
    height: fit-content;
    max-width: 1440px;
    margin: 60px auto 40px auto;
    padding: 20px 15px;
    background: var(--white);
    & > p {
      color: #555;
      font-size: 14px;
    }
    & > button:nth-child(3) {
      width: 100%;
      max-width: 300px;
      border: none;
      height: 40px;
      background-color: #ddd;
      outline: 0;
      color: var(--black);
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-size: 14px;
      & > span {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    & > div:last-child {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      & > ol {
        padding-top: 20px;
        padding-left: 20px;
        margin: 0 0 20px 50px;
        & > li {
          padding: 3px;
          padding-bottom: 6px;
          font-size: 14px;
          color: #555;
        }
      }
      &.ul-active {
        max-height: 2500px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px;
    & > div {
      & > p {
        text-align: left;
      }
      & > div:last-child {
        & > ol {
          margin: 0 0 20px 5px;
          text-align: left;
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone and medium tablet styles */
    text-align: center;
  }
`;
const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin: 20px 0px 40px 0px;
  background: #fff;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
  & > thead {
    & > tr {
      & > th {
        border: 1px solid #ddd;
        border-bottom: none;
        padding: 8px;
        line-height: 1.42857143;
        text-align: center;
        background: #131619;
        color: #fff;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }
  }
  & > tbody {
    & > tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      & > td {
        text-align: center;
        border: 1px solid #ddd;
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        font-size: 14px;
        color: #333;
      }
    }
  }
`;
export default HighHands;
