// src/components/Toast/Toast.jsx
import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useToast } from '../../context/toast/toast.provider';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const ToastComponent = () => {
  const { toastData, hideToastFn } = useToast();

  return (
    <CustomToastContainer>
      {toastData.map(toast => (
       <Toast
          key={new Date().getTime() + Math.random()}
          show={toast.show}
          bg={toast.variant}
          autohide={toast.autohide !== undefined ? toast.autohide : true}
          delay={toast.delay || 2000}
          onClose={() => hideToastFn(toast.id)}
        >
          <Toast.Header>
            <strong className="me-auto">{toast.title}</strong>
            <button type="button" className="btn" aria-label="Close" onClick={() => hideToastFn(toast.id)}>
              <FaTimes />
            </button>


          </Toast.Header>
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      ))}
    </CustomToastContainer>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CustomToastContainer = styled(ToastContainer)`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0;
  width: 100%;
  /* max-width: 370px; */
  z-index: 9999;
  .toast {
    opacity: 0.97;
    border-radius: 0;
    width: 100%;
    margin: 0 0;
    border-width: 0;
    &.show {
      animation: ${fadeIn} 0.3s linear;
    }
    .toast-header {
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 0;
        svg {
          color: white;
        }
      }
      .btn-close {
        display: none;
      }
    }
    .toast-body {
      text-align: center;
    }
    &:hover {
      opacity: 1;
    }
    &.bg-success {
      .toast-header {
        background-color: #1a5327;
        color: #fff;
      }
      .toast-body {
        background-color: #eff5f1;
      }
    }
    &.bg-danger {
      .toast-header {
        background-color: #5b0e1b;
        color: #fff;
      }
      .toast-body {
        background-color: #f5efef;
      }
    }
    &.bg-warning {
      .toast-header {
        background-color: #4e3d05;
        color: #fff;
      }
      .toast-body {
        background-color: #f5f5ef;
      }
    }
    &.bg-info {
      .toast-header {
        background-color: #1e414c;
        color: #fff;
      }
      .toast-body {
        background-color: #eff3f5;
      }
    }
  }
`;

export default ToastComponent;