import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import Button from "../Button/Button";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const Hero = ({
  mainHeroText,
  image,
  title,
  titleAs: TitleTag = "h1", // Default to h1 if not provided
  description,
  bgImage,
  buttonSettings,
  descriptionBgColor,
  fluidImage,
  imageAlt,
  showArrow,
  arrowBounce,
  overlayVideoSrc,
  className,
  children,
  darkenBg
}) => (
  <HeroContainer bgImage={useResponsiveImageUrl(bgImage)} darkenBg={darkenBg} fluid={fluidImage} className={`hero_container ${fluidImage ? `fluid` : '' } ${className || ''}`}>
    {overlayVideoSrc && (
      <video autoPlay muted loop playsInline className="overlay-video">
        <source src={overlayVideoSrc} type="video/mp4" />
      </video>
    )}
    <HeroImageContainer className="hero_image_container">
      {mainHeroText && <MainHeroText className="main-hero-text">{mainHeroText}</MainHeroText>}
      {image && <HeroImage
        src={image}
        alt={imageAlt}
        fluid={fluidImage}
        className="hero_image"
        />
      }
    </HeroImageContainer>
    <HeroTextContainer
      bgColor={descriptionBgColor}
      className="hero_text_container"
    >
      {title && <TitleTag>{title}</TitleTag>}
      <HeroDescription>{description}</HeroDescription>
      {children}
      {buttonSettings && (
        <Button {...buttonSettings} className="hero_button" />
      )}
      {showArrow && (
        <HeroArrowContainer className={arrowBounce ? "bounce" : ""}>
          <FontAwesomeIcon icon={faArrowCircleDown} aria-hidden="true" />
        </HeroArrowContainer>
      )}
    </HeroTextContainer>
  </HeroContainer>
);

const HeroContainer = styled.div`
  background: url(${(props) => props.bgImage}) no-repeat;
  background-size: cover;
  background-position: center center;
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (props.fluid ? "0" : "100px")};
  position: relative;
  video.overlay-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: inline-block;
    opacity: 0.07;
    object-fit: cover;
  }
  ${(props) => props.darkenBg && css`
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, ${typeof props.darkenBg === 'boolean' ? 0.5 : props.darkenBg});
    }
  `}

  @media (max-width: 991px) {
    margin-top: 80px;
    padding-top: 0;
  }
`;

const HeroImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  z-index: 5;
`;

const HeroImage = styled(ResponsiveImage)`
  width: ${(props) => (props.fluid ? "100%" : "auto")};
  max-width: ${(props) => (props.fluid ? "initial" : "800px")};
  width: 100%;
  margin: 0 auto;
  display: block;
`;

const MainHeroText = styled(ReactMarkdown)`
  h1, h2 {
    font-weight: bolder;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    margin: 40px 0;
    padding: 4rem 1rem;
  }
  h1 {
    font-size: 4rem;
  }
  h2{
    font-size: 3rem;
  }
`;

const HeroTextContainer = styled.div`
  background-color: ${(props) => props.bgColor || "rgba(0, 0, 0, 0.5)"};
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px) brightness(80%) grayscale(30%);
  z-index: 5;

  h1, h2, h3, h4, p, > span:first-child {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: 1.25rem;
  }

  h1, > h2:first-child, > h3:first-child, > h4:first-child, > span:first-child {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  .subtitle-section {
    h2 {
      padding-bottom: 1rem;
      :after {
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
`;

const HeroDescription = styled(ReactMarkdown)`
  max-width: 1200px;
`;

const HeroArrowContainer = styled.div`
  font-size: 3em;
`;

export default Hero;
