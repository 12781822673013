import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaStar, FaList } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useHomepage } from "src/context/homepage/homepage.provider";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WaitingListInfo from "src/components/Poker/WaitingListInfo";

const LiveGames = () => {
  const { t } = useTranslation();
  const { liveGamesData } = useHomepage();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
        if (!liveGamesData || Object.keys(liveGamesData).length === 0) {
          setHasError(true);
        }
      }
    }, 4000); // 4 seconds timeout for example

    return () => clearTimeout(timeoutId);
  }, [isLoading, liveGamesData]);

  useEffect(() => {
    if (liveGamesData && Object.keys(liveGamesData).length > 0) {
      setIsLoading(false);
      setHasError(false);
    }
  }, [liveGamesData]);

  return (
    <container-wide>
      <LiveGamesDiv>
        <SubTitleSection subTitle={t("cash-games.header-title")} />
        <WaitingListInfo />

        <Row xs={1} md={2} className="g-4">
          <Col>
            <Table id="current-live-games">
              <thead>
                <tr>
                  <th colSpan="2">
                    <h2><FaStar /> {t("cash-games.live-current")}</h2>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading || hasError ? (
                  <tr>
                    <td colSpan="2">
                      {isLoading
                        ? t("cash-games.loading")
                        : t("cash-games.errorFetching")}
                    </td>
                  </tr>
                ) : (
                  liveGamesData?.map(
                    (game) =>
                      game.tables > 0 && (
                        <tr key={game.id}>
                          <td>{game.game_name}</td>
                          <td className="number-holder">
                            <span>{game.tables}</span>
                          </td>
                        </tr>
                      )
                  )
                )}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table id="waiting-lists">
              <thead>
                <tr>
                  <th colSpan="2">
                    <h2><FaList /> {t("cash-games.live-waiting")}</h2>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading || hasError ? (
                  <tr>
                    <td colSpan="2">
                      {isLoading
                        ? t("cash-games.loading")
                        : t("cash-games.errorFetching")}
                    </td>
                  </tr>
                ) : (
                  liveGamesData?.map(
                    (game) =>
                      game.waiting >= 0 && (
                        <tr key={game.id}>
                          <td>{game.game_name}</td>
                          <td className="number-holder">
                            <span>{game.waiting}</span>
                          </td>
                        </tr>
                      )
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </LiveGamesDiv>
    </container-wide>
  );
};

const LiveGamesDiv = styled.div`
  color: white;
  padding: 2rem 0 3rem;
`;

const Table = styled.table`
  width: 100%;
  th,
  td {
    border: 1px solid var(--rewards_grid);
    border-left-width: 0;
    border-right-width: 0;
    &:first-child {
      border-left-width: 1px;
    }
    &:last-child {
      border-right-width: 1px;
    }
    padding: 0.7rem;
  }
  thead {
    th {
      background: var(--red);
      h2 {
        font-size: 1.2rem;
        font-weight: 600;
      }
      svg {
        position: relative;
        bottom: 2px;
      }
    }
  }
  tbody {
    tr:hover td {
      background: var(--lightblack);
      transition: 0.3s all;
    }
    td {
      font-weight: 500;
      &.number-holder {
        text-align: right;
        > span {
          display: inline-block;
          font-weight: 700;
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          border-radius: 50%;
          text-align: center;
          background: var(--red);
          font-size: 1.2rem;
          text-indent: 0px;
        }
      }
    }
  }
`;

export default LiveGames;
