import { useEffect, useState } from "react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import frLocale from "i18n-iso-countries/langs/fr.json";
import provincesAndStates from "../../data/provincesAndStates.json";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { register } from "../../api/accounts";
import Button from "src/components/Button/Button";
import { StyledForm } from "../../GlobalStyle";
import styled from "styled-components";

import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import IdSection from "src/components/Auth/IdSection";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NewsletterSection from "src/components/NewsletterSection/NewsletterSection";

const RegisterForm = ({promoCode}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [formState, setFormState] = useState({});
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const language = i18n.language;
  const preferredLanguage = localStorage.getItem("language").toUpperCase();
  countries.registerLocale(enLocale);
  countries.registerLocale(frLocale);
  const countryObj = countries.getNames(language, {
    select: "official",
  });
  const countryArr = Object.entries(countryObj).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const specialCountries = [
    { label: language === "fr" ? "Canada" : "Canada", value: "CA" },
    {
      label: language === "fr" ? "États-Unis" : "United States",
      value: "US",
    },
  ];
  
  const allCountries = [
    ...specialCountries,
    ...countryArr.filter(
      (country) => country.value !== "CA" && country.value !== "US"
    ),
  ];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setField = (field, value) => {
    setFormState((prevForm) => ({ ...prevForm, [field]: value }));
  };

  const setSubmitting = (value) => {
    setIsSubmitting(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    const formData = new FormData(form);

    // Add form data to FormData object
    Object.keys(formState).forEach((key) => {
      formData.append(key, formState[key]);
    });
    if(promoCode) {
      formData.append("promo_code", promoCode);
    }
    const id_image = formData.get("id_image");
    if (id_image.size > 20 * 1024 * 1024) {
      setErrorMessage(
        i18n.language === "en"
          ? "The file size should be less than 4 MB"
          : "La taille du fichier doit être inférieure à 4 Mo"
      );
      return;
    }

    const fileExtension = id_image.name.split(".").pop().toLowerCase();
    const allowedExtensions = ["jpg", "jpeg", "png"];
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage(
        i18n.language === "en"
          ? "Please select a valid image file (JPG or PNG)"
          : "Veuillez sélectionner un fichier image valide (JPG ou PNG)"
      );
      return;
    }

    formData.append("preferred_language", preferredLanguage);

    if (isSubmitting) return;

    try {
      setSubmitting(true);
      setIsLoading(true);

      const userData = Object.fromEntries(formData.entries());
      const registrationResult = await register(userData);

      if (registrationResult.success) {
        console.log("User registered successfully");
        navigate(t("routes.addNewUserSuccess"));
      } else {
        setErrorMessage(
          i18n.language === "en"
            ? "Registration failed"
            : "L'enregistrement a échoué"
        );
      }
    } catch (error) {
      setErrorMessage(
        i18n.language === "en"
          ? "Error during registration"
          : "Erreur lors de l'inscription"
      );
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    setErrorMessage("");
  }, []);

  return (
    <StyledForm
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      invalid={errorMessage}
    >
      <SubTitleSection
        as={'h1'}
        subTitle={t("auth.register.createAccount")}
        text={t("auth.login.description")}
        className="py-0"
      />

      {promoCode &&
        <Row xs={1}>
          <Col>
            <FormGroup>
              <FormLabel htmlFor="promo_code">
                {t("auth.form.promoCode")}
              </FormLabel>
              <FormControl
                type="text"
                id="promo_code"
                name="promo_code"
                value={promoCode}
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {t("auth.form.firstName.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
          </Col>
        </Row>
      }
      <Row xs={1} md={2} className="g-3">
        <Col>
          <FormGroup>
            <FormLabel htmlFor="first_name">
              {t("auth.form.firstName.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="first_name"
              name="first_name"
              placeholder={t("auth.form.firstName.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.firstName.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormLabel htmlFor="last_name">
              {t("auth.form.lastName.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="last_name"
              name="last_name"
              placeholder={t("auth.form.lastName.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.lastName.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>

      <Row xs={1} className="g-3">
        <Col>
          <FormGroup>
            <FormLabel htmlFor="email">
              {t("auth.form.email.label")}
            </FormLabel>
            <FormControl
              type="email"
              id="email"
              name="email"
              placeholder={t("auth.form.email.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.email.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormLabel htmlFor="phone_number">
              {t("auth.form.phoneNumber.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="phone_number"
              name="phone_number"
              placeholder={t("auth.form.phoneNumber.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.phoneNumber.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>

      <Row xs={1} className="mt-4 g-3">
        <Col>
          <FormGroup>
            <FormLabel htmlFor="address1">
              {t("auth.form.address1.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="address1"
              name="address1"
              placeholder={t("auth.form.address1.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.address1.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormLabel htmlFor="address2">
              {t("auth.form.address2.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="address2"
              name="address2"
              placeholder={t("auth.form.address2.placeholder")}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="g-3">
        <Col md={6}>
          <FormGroup>
            <FormLabel htmlFor="city">
              {t("auth.form.city.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="city"
              name="city"
              placeholder={t("auth.form.city.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.city.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormLabel htmlFor="country">
              {t("auth.form.country.label")}
            </FormLabel>
            <FormControl
              as="select"
              id="country"
              name="country"
              placeholder={t("auth.form.country.placeholder")}
              required
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="">
                {t("auth.form.country.placeholder")}
              </option>
              {allCountries?.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </FormControl>
            <Form.Control.Feedback type="invalid">
              {t("auth.form.country.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>

      <Row className="g-3 mb-4">
        <Col md={6}>
          {["US", "CA", ""].includes(selectedCountry) ? (
            <FormGroup>
              <FormLabel htmlFor="state">
                {t("auth.form.state.label")}
              </FormLabel>
              <FormControl as="select" id="state" name="state" required>
                <option value="">
                  {t("auth.form.state.placeholder")}
                </option>
                {selectedCountry === "US"
                  ? provincesAndStates.states.map(
                      ({ en, fr, abbreviation }) => (
                        <option
                          key={abbreviation}
                          value={language === "fr" ? fr : en}
                        >
                          {language === "fr" ? fr : en}
                        </option>
                      )
                    )
                  : provincesAndStates.provinces.map(
                      ({ en, fr, abbreviation }) => (
                        <option
                          key={abbreviation}
                          value={language === "fr" ? fr : en}
                        >
                          {language === "fr" ? fr : en}
                        </option>
                      )
                    )}
              </FormControl>
              <Form.Control.Feedback type="invalid">
                {t("auth.form.state.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
          ) : (
            <FormGroup>
              <FormLabel htmlFor="state">
                {t("auth.form.state.label")}
              </FormLabel>
              <FormControl
                type="text"
                id="state"
                name="state"
                placeholder={t("auth.form.state.placeholder")}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("auth.form.state.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
          )}
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormLabel htmlFor="postal">
              {t("auth.form.postalCode.label")}
            </FormLabel>
            <FormControl
              type="text"
              id="postal"
              name="postal"
              placeholder={t("auth.form.postalCode.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.postalCode.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>

      <IdSection />

      <NewsletterSection setField={setField} />

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Button
        type="submit"
        disabled={isSubmitting}
        bgColor="var(--red)"
        color="var(--white)"
        hoverColor="var(--black)"
        hoverBgColor="var(--white)"
        fluid
        label={
          isLoading ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            t("auth.register.registerButton")
          )
        }
      />
    </StyledForm>
  );
};

const MaxWidthCol = styled(Col)`
  max-width: 500px;
`;

export default RegisterForm;
