import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FaTable } from "react-icons/fa6";
import Button from '../Button/Button';

const GameListLink = () => {
  const { t } = useTranslation();

  return (
    <Button
      href={t("routes.cashGames")}
      label={t("poker.mystery-high-hand.small-box-info-six")}
      className="cashGamesLink"
      fluid
    />
	);
};

const CashGamesLink = styled(Link)`
  background-color: var(--lightblack);
  padding: 1.2rem;
  text-align: center;
  margin: 0;
  display: block;
  svg {
    margin-right: 1rem;
  }
`;

export default GameListLink;