  // components/Navbar/SignIn.jsx
  import React, { useEffect } from "react";
  import { useLanguageChanger } from "../../middleware/useLanguageChanger";
  import { useAccount } from "src/context/account/account.provider";
  import { Link } from "react-router-dom";
  import { Nav } from 'react-bootstrap';
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faSignIn, faSignOut, faUserCircle, faGlobe, faLocationDot } from "@fortawesome/free-solid-svg-icons";
  import { FiPhone } from "react-icons/fi";
  import styled from "styled-components/macro";
  import { useTranslation } from "react-i18next";
  import { FaRegStar } from "react-icons/fa";
  import { getScheduleDates, shouldPublish } from 'src/utils/publishUtils';


  const SecondaryNav = ({
    closeAllMenus
  }) => {
    const changeLanguage = useLanguageChanger();
    const { access_token, logout } = useAccount();
    const { t, i18n } = useTranslation();

    // Fetch and check the schedule for the NYE link
    const nyeSchedule = getScheduleDates("newYearsEve2025");
    const showNyeLink = shouldPublish(nyeSchedule);

    useEffect(() => {
      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    return (
      <SecondaryNavDiv className="secondary-nav">
        
        {/* Conditionally render the NYE link */}
          {showNyeLink && (
            <Nav.Link
            as={Link}
            to={t("routes.newYearsEve2025")}
            onClick={closeAllMenus}
          >
            <FaRegStar className="mustard bump-up"/> {t("nav.nye2025")}
          </Nav.Link>
        )}

        <Nav.Link
          as={'a'}
          href="https://shop.playground.ca/"
          onClick={closeAllMenus}
          >
            {t("nav.shop")}
          </Nav.Link>

          <Nav.Link
            as={Link}
            to={t("routes.playersGuide")}
            onClick={closeAllMenus}
          >
            {t("footer.playersGuide")}
          </Nav.Link>

          <Nav.Link
            as={Link}
            to={t("routes.playgroundCares")}
            onClick={closeAllMenus}
          >
            {t("nav.playgroundCares")}
          </Nav.Link>


          <Nav.Link
            as={Link}
            to={t("routes.visitUs")}
            onClick={closeAllMenus}
            className="visit"
          >
            <FontAwesomeIcon icon={faLocationDot} aria-hidden="true" /> {t("nav.visitUs")}
          </Nav.Link>

          {access_token ? (
            <Nav.Link
              as={Link}
              to={t("routes.myAccount")}
              onClick={closeAllMenus}
            >
              <FontAwesomeIcon icon={faUserCircle} aria-hidden="true" /> {t("nav.myPG")}
            </Nav.Link>
          ) : (
            <Nav.Link
              as={Link}
              to={t("routes.login")}
              onClick={() => {
                closeAllMenus();
              }}
            >
              <FontAwesomeIcon icon={faSignIn} aria-hidden="true" /> {t("nav.signIn")}
            </Nav.Link>
        )}

        <button
          type="button"
          className="nav-link language"
          onClick={() => {
            changeLanguage(i18n.language === "en" ? "fr" : "en");
            // closeAllMenus();
          }}
        >
          <FontAwesomeIcon icon={faGlobe} aria-hidden="true" /> {i18n.language === "en" ? "Français" : "English"}
        </button>

        {access_token &&
          <Nav.Link
            as={Link}
            className="red logout"
            onClick={() => {
              logout();
              closeAllMenus();
            }}
          >
            <FontAwesomeIcon icon={faSignOut} aria-hidden="true" /> {t("button.sign-out")}
          </Nav.Link>
        }
        <AddressDiv>
          <p>
            <a href="tel:+18556857653"><FiPhone /> 1-855-685-7653</a>
          </p>
          <p>
            <Link to={`${t("routes.visitUs")}#${t("nav.map")}`} onClick={closeAllMenus}>
              <FontAwesomeIcon icon={faLocationDot} aria-hidden="true" /> {t("nav.viewMap")}
            </Link>
          </p>
          <p>1500 Unit C, Route 138,
            <br/>Kahnawake, QC J0L 1B0
          </p>
        </AddressDiv>

      </SecondaryNavDiv>
    );
  };

  const AddressDiv = styled.div`
    border-top: solid 2px var(--lightblack);
    background: rgba(0, 0, 0, 0.7);
    color: var(--white);
    padding-top: 1.5rem;
    a {
      color: var(--bluelinkdark);
      svg {
        position: relative;
        bottom: 2px;
      }
    }
  `;

  const SecondaryNavDiv = styled.main`
    border-top: solid 2px var(--lightblack);
    padding-top: .7rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
    .nav-link {
      svg {
        width: 1.2rem;
      }
      &.logout {
        border-top: solid 2px var(--lightblack);
        padding-top: .7rem;
        margin: 1rem 0;
      }
      &.visit {
        border-top: solid 2px var(--lightblack);
        padding-top: 1rem;
        margin-top: 1rem;
      }
    }
    @media (min-width: 768px) {
      display: none;
    }
  `;

  export default SecondaryNav;
