
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

const WaitingListInfo = () => {
  const { t } = useTranslation();

  return (
    <WaitingListDiv>
      <>
        <ReactMarkdown>{t("cash-games.live-text-1")}</ReactMarkdown>
        <p>
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => <span {...props} />,
            }}
          >
            {t("cash-games.live-text-2-1")}
          </ReactMarkdown>
          &nbsp;<nobr><a href="tel:+15146165176">514-616-5176</a></nobr>&nbsp;
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => <span {...props} />,
            }}
          >
            {t("cash-games.live-text-2-2")}
          </ReactMarkdown>
        </p>
        <ReactMarkdown>{t("cash-games.live-text-3")}</ReactMarkdown>
      </>
    </WaitingListDiv>
  );
};

const WaitingListDiv = styled.div`
  background-color: var(--lightblack);
  padding: 20px;
  text-align: center;
  margin: 1rem 0;
  p {
    color: var(--white);
  }
`;

export default WaitingListInfo;
