import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { FaArrowCircleDown } from "react-icons/fa";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const HappyBirthday = () => {
  const [viewBtn, setViewBtn] = useState(false);
  const { darkThemeFn } = useHomepage();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  useEffect(() => {
    darkThemeFn(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const responsiveBgImage = useResponsiveImageUrl(
    "promotions/happy-birthday-bg.jpg"
  );
  return (
    <PromoDetailDiv bgImage={responsiveBgImage}>
      <div>
        <div>
          {i18n.language === "en" ? (
            <ResponsiveImage
              src="promotions/happy-birthday.png"
              alt="happy-birthday"
            />
          ) : (
            <ResponsiveImage
              src="promotions/happy-birthday-fr.png"
              alt="happy-birthday-fr"
            />
          )}
        </div>
        <div>
          <h1>
            {i18n.language === "en"
              ? "Happy Birthday!"
              : "Joyeux Anniversaire!"}
          </h1>
          <h2>
            {i18n.language === "en"
              ? "You say it’s your birthday? Come celebrate at Playground and claim your gifts!"
              : "Pour que tous vos souhaits se réalisent, venez faire la fête au Playground!"}
          </h2>
          <FaArrowCircleDown />
        </div>
      </div>
      <div>
        <div>
          <p>
            {i18n.language === "en"
              ? "During your whole birthday month, you can drop by our cashier or go to the promotions tab on your favorite gaming machine and claim your gift(s). Also, if you do choose to party down at Playground on your special day, we will serve you cake and sparkling wine to help make your birthday extra-sweet!"
              : "Pendant tout le mois de ton anniversaire, tu peux passer à notre caisse ou aller à l'onglet des promotions sur ton machine de jeux préféré et réclamer ton ou tes cadeaux. De plus, si vous choisissez de faire la fête au Playground le jour de votre anniversaire, nous vous servirons un gâteau et du vin mousseux pour rendre votre anniversaire encore plus mémorable!"}
          </p>
          {i18n.language === "en" ? (
            <table>
              <thead>
                <tr>
                  <th>Tier</th>
                  <th>Free Play Amount</th>
                  <th>Rail Gift Card</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Silver</td>
                  <td>$5</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>Gold</td>
                  <td>$10</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>Red</td>
                  <td>$25</td>
                  <td>$25</td>
                </tr>
                <tr>
                  <td>Black</td>
                  <td>$100</td>
                  <td>$50</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Niveau</th>
                  <th>Montant en crédits-jeu</th>
                  <th>Carte cadeau pour le Rail</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Argent</td>
                  <td>5$</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>Or</td>
                  <td>10$</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>Rouge</td>
                  <td>25$</td>
                  <td>25$</td>
                </tr>
                <tr>
                  <td>Noir</td>
                  <td>100$</td>
                  <td>50$</td>
                </tr>
              </tbody>
            </table>
          )}
          <button onClick={() => setViewBtn(!viewBtn)}>
            {i18n.language === "en"
              ? "View Terms and Conditions"
              : "Conditions générales"}
            <span></span>
          </button>
          <div className={viewBtn === true ? "ul-active" : undefined}>
            {i18n.language === "en" ? (
              <ul>
                <li>This promotion is available to all Playground members.</li>
                <li>Offer is valid, one (1) time only per calendar year.</li>
                <li>Offer is valid during the members birthday month.</li>
                <li>The Rail gift cards will be available at the cashier.</li>
                <li>
                  Members will need to advise staff it is their actual birthday
                  to receive free cake and sparkling wine.
                </li>
                <li>
                  Free play vouchers are valid on all gaming machines that
                  accept free play.
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  Cette promotion est disponible pour tous les membres
                  Playground.
                </li>
                <li>
                  L'offre est valable une (1) seule fois par année civile.
                </li>
                <li>
                  L'offre est valable pendant le mois d'anniversaire du membre.
                </li>
                <li>Les cartes cadeaux Rail seront disponibles à la caisse.</li>
                <li>
                  Les membres devront informer le personnel qu'il s'agit de leur
                  véritable anniversaire pour recevoir un gâteau et un vin
                  mousseux gratuits.
                </li>
                <li>
                  Les bons en crédits-jeu sont valables sur tous les tables de
                  jeux qui acceptent les crédits-jeu.
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      
    </PromoDetailDiv>
  );
};
const bounce = keyframes`0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-25px);}
	60% {transform: translateY(-10px);}`;
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #e8ebef;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 20% 10% / 100% 100% no-repeat fixed;
    z-index: 0;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > div:first-child {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      & > img {
        margin: 0 auto;
        max-width: 800px;
        width: 100%;
        height: auto;
      }
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 40px 0px;
      & > h1 {
        color: var(--white);
        font-weight: 900;
      }
      & > h2 {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        height: 100%;
        font-size: 32px;
        line-height: 40px;
        color: #fff;
        font-weight: 700;
      }
      & > svg {
        animation: ${bounce} 2s ease infinite;
        width: 50px;
        height: 50px;
        margin-top: 20px;
        color: var(--white);
      }
    }
  }
  & > div:nth-child(2) {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 40px auto;
    & > div {
      padding: 40px 40px 0px 40px;
      background: #fff;
      & > p:first-child {
        font-size: 18px;
        text-align: left;
        padding: 0px 15px;
        color: #555;
        margin: 0 0 10px;
      }
      & > table:nth-child(2) {
        width: 100%;
        border: 1px solid #ddd;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 20px 0px 20px 0px;
        background: var(--white);
        & > thead {
          & > tr {
            text-align: center;
            background: #131619;
            color: #fff;
            border-radius: 0px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.5px;
            & > th {
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: middle;
              border: 1px solid #ddd;
              border-bottom: none;
            }
          }
        }
        & > tbody {
          & > tr {
            height: 40px;
            &:hover {
              background: #e8ebef;
            }
            & > td {
              border: 1px solid #ddd;
              font-weight: 300;
              text-align: center;
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: top;
              font-size: 14px;
            }
          }
        }
      }
      & > button:nth-child(3) {
        width: 100%;
        max-width: 300px;
        border: none;
        height: 40px;
        background-color: #ddd;
        outline: 0;
        color: var(--black);
        margin: 20px 0 40px 0;
        border-radius: 0;
        padding: 0;
        & > span {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 5px;
          vertical-align: middle;
          border-top: 4px dashed;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
      & > div:nth-child(4) {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        & > ul {
          padding-left: 20px;
          margin: 0 0 60px 50px;
          list-style-type: disc;
          & > li {
            padding: 3px;
            font-size: 14px;
            color: #555;
          }
        }
        &.ul-active {
          max-height: 350px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    & > div:first-child {
      & > div:first-child {
      }
      & > div:nth-child(2) {
        padding: 40px 10px;
        & > h2 {
          font-size: 26px;
          line-height: 38px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    & > div:nth-child(2) {
      & > div {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & > button:nth-child(3) {
          margin: 20px auto 20px 0;
        }
        & > div:nth-child(4) {
          & > ul {
            margin: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    & > div:first-child {
      background: url(${(props) => props.bgImage}) 20% 100% fixed no-repeat;
      & > div:first-child {
      }
    }
    & > div:nth-child(2) {
      & > div {
        & > button:nth-child(3) {
          margin: 20px auto 20px auto;
          margin-bottom: 0px;
        }
      }
    }
  }
`;
export default HappyBirthday;
