import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import Button from "src/components/Button/Button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAccount } from "src/context/account/account.provider";
import Cookies from "js-cookie";
import { refresh_token } from "src/api/auth";
import { useToast } from "src/context/toast/toast.provider";

const TokenExpiryModal = () => {
  const { tokenExpiry, tokenExpiryFn, logout } = useAccount();
  const { t } = useTranslation();
  const { showToastFn } = useToast();
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    let timer;
    if (tokenExpiry && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => Math.max(prev - 1, 0));
      }, 1000);
    } else if (countdown === 0 && tokenExpiry) {
      tokenExpiryFn(false); // Close modal
      logout();
    }

    return () => clearInterval(timer);
  }, [tokenExpiry, countdown]);

  const handleClose = async () => {
    try {
      const response = await refresh_token(Cookies.get("refresh_token"));
      tokenExpiryFn(false); // Close modal
      setCountdown(60); // Reset countdown
      if (response.success) {
        showToastFn({
          show: true,
          title: t("modal.tokenExpired.success.title"),
          message: t("modal.tokenExpired.success.message"),
          autohide: true,
          variant: "success",
        });
      } else {
        throw new Error("Failed to refresh token");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      tokenExpiryFn(false); // Close modal on error
      showToastFn({
        show: true,
        title: t("modal.tokenExpired.error.title"),
        message: t("modal.tokenExpired.error.message"),
        autohide: true,
        variant: "danger",
      });
    }
  };

  return (
    <StyledModal
      show={tokenExpiry}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      className="token-warning-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title>{t("modal.tokenExpired.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactMarkdown>
          {t("modal.tokenExpired.message", { seconds: countdown })}
        </ReactMarkdown>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          label={t("modal.tokenExpired.confirm")}
          bgColor="var(--red)"
          color="var(--white)"
          hoverColor="var(--white)"
          hoverBgColor="var(--black)"
        />
      </Modal.Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-header {
    background-color: var(--mustard);
    .modal-title {
      color: var(--lightblack);
      font-weight: 700;
      text-align: center;
    }
  }
  .modal-content,
  .modal-header {
    border-radius: 0;
  }
  .modal-footer {
    button {
      margin: 0;
    }
  }
`;

export default TokenExpiryModal;
