// This config is used by
//  - HeaderNav.jsx to determine is links should be shown.
//    (there are also conditional statements in the footer and secondary nav)
//  - Promotions.jx to determine if a promo card should be shown.
//  - EvenetFinished banner component on expired event/promo pages.
// This allows us to define a publish schedule in advance and have these components show/hide.

const scheduleConfig = {
  // Entertainment
  musicalMonday: {
    publish_at: "2024-01-01",
    unpublish_at: "2021-09-06"
  },
  girlsNightOut: {
    publish_at: "2024-01-01",
    unpublish_at: "2024-12-11"
  },
  newYearsEve2025: {
    // Don't delete this key,
    // it is used in Cypress tests
    // schedule-promo-config.cy.js
    publish_at: "2024-11-01",
    unpublish_at: "2025-01-01",
  },
  valentines2025: {
    publish_at: "2025-01-24",
    unpublish_at: "2025-02-15"
  },
  // Dining
  surfAndTurf2024: {
    publish_at: "2024-09-19"
  },
  playgroundBuffets: {
    publish_at: "2024-09-19"
  },
  get25PercentOff: {
    publish_at: "2024-09-19"
  },
  earlyBirdBreakfastSpecial: {
    publish_at: "2024-09-19"
  },
  classicAmericanBreakfast: {
    publish_at: "2024-09-19"
  },
  earlyBirdSpecial: {
    publish_at: "2024-09-19",
    unpublish_at: "2024-10-29"
  },
  // Poker
  mysteryHighHandOctober2024: {
    publish_at: "2024-01-01",
    unpublish_at: "2021-11-29"
  },
  highHandDec2024: {
    publish_at: "2024-11-22",
    unpublish_at: "2025-01-01"
  },
  highHandJanuary2025: {
    publish_at: "2024-11-22",
    unpublish_at: "2025-02-01"
  },
  anniversaryHighHandDec2024: {
    publish_at: "2024-11-22",
    unpublish_at: "2024-12-18"
  },
  irishOpen2025: {
    publish_at: "2024-12-18",
    unpublish_at: "2025-02-01"
  },
  pokerTlb: {
    // Don't delete this key,
    // it is used in Cypress tests
    // schedule-promo-config.cy.js
    publish_at: "2024-09-19"
  },
  badBeatJackpot: {
    publish_at: "2024-09-19"
  },
  chaseTheAce2024: {
    publish_at: "2024-09-19",
    unpublish_at: "2024-12-20",
  },
  pokerNewYearsEve2025: {
    // Don't delete this key,
    // it is used in Cypress tests
    // schedule-promo-config.cy.js
    publish_at: "2024-11-01",
    unpublish_at: "2025-01-01",
  },
  worldCupOfCards2025: {
    publish_at: "2024-01-01",
    unpublish_at: "2025-02-03",
  },
  wsopcPlaygroundSpring2025: {
    publish_at: "2025-01-21",
    unpublish_at: "2025-04-09",
  },
  titanTuesday2025: {
    publish_at: "2025-01-10",
  },
  // EGD
  chaseTheAce2024: {
    publish_at: "2024-09-19",
    unpublish_at: "2024-12-20"
  },
  chineseNewYear2025: {
    publish_at: "2025-01-03",
    unpublish_at: "2025-02-17"
  },
  weeklyJackpotParty: {
    publish_at: "2024-09-19"
  },
  instaWin: {
    publish_at: "2024-09-19"
  },
  boomingWednesday: {
    publish_at: "2024-09-19"
  },
  optInAndWin: {
    publish_at: "2024-09-19"
  },
  followForFreePlay: {
    publish_at: "2024-09-19"
  },
  happyBirthday: {
    publish_at: "2024-09-19"
  },
  welcomeBackBonus: {
    publish_at: "2024-09-19"
  }
};

export default scheduleConfig;
