import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Tabs, Tab, Row, Col, Table } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import EventFinished from "src/components/EventFinished";

const ChaseTheAce2024 = () => {
  const { i18n, t } = useTranslation();
  const lng = i18n.language;
  const responsiveImageUrl = useResponsiveImageUrl();

  return (
    <ChaseTheAceDiv>
      <EventFinished scheduleKeyName="chaseTheAce2024" eventType="promotion" />
      <Hero
        bgImage="promotions/gaming-machines/chase-the-ace-2024/CTA_BG.webp"
        descriptionBgColor="#3f05054d"
        image={`promotions/gaming-machines/chase-the-ace-2024/cta-logo-${i18n.language}.png`}
        imageAlt="Logo"
      >
        <h1>{t("chaseTheAce2024.title")}</h1>
        <div className="hero-box">
          <ReactMarkdown>{t("chaseTheAce2024.description")}</ReactMarkdown>
          <ReactMarkdown>{t("chaseTheAce2024.amount")}</ReactMarkdown>
        </div>
        <ReactMarkdown>{t("chaseTheAce2024.details.draws")}</ReactMarkdown>
        <ReactMarkdown>{t("chaseTheAce2024.details.location")}</ReactMarkdown>
        <ReactMarkdown>{t("chaseTheAce2024.details.winners")}</ReactMarkdown>
      </Hero>

      <IntroDiv>
        <container-div>
          <ReactMarkdown>{t("chaseTheAce2024.playground.floor")}</ReactMarkdown>
          <ReactMarkdown>{t("chaseTheAce2024.playground.entries")}</ReactMarkdown>
          <ReactMarkdown>{t("chaseTheAce2024.playground.earn")}</ReactMarkdown>
          <ReactMarkdown>{t("chaseTheAce2024.playground.draws")}</ReactMarkdown>
        </container-div>
      </IntroDiv>

      <ProgressiveDiv bgImage={useResponsiveImageUrl(
        "promotions/gaming-machines/chase-the-ace-2024/chase-the-ace-lightning.jpg"
      )}>
        <container-div>
          <h2>{t("chaseTheAce2024.progressive.title")}</h2>
          <Row xs={1} md={2} className="gy-5">
            <Col>
              <ReactMarkdown>{t("chaseTheAce2024.progressive.winners")}</ReactMarkdown>
              <ReactMarkdown>{t("chaseTheAce2024.progressive.draw")}</ReactMarkdown>
              <ReactMarkdown>{t("chaseTheAce2024.progressive.jackpot")}</ReactMarkdown>
            </Col>
            <Col>
              <ResponsiveImage src={`promotions/gaming-machines/chase-the-ace-2024/chase-the-ace-2024-lp-cards-${i18n.language}.png`} alt="Cards" />
            </Col>
          </Row>
        </container-div>
      </ProgressiveDiv>


      <RapidFire>
        <container-div>
          <h2>{t("chaseTheAce2024.rapidFire.title")}</h2>
          <ReactMarkdown>{t("chaseTheAce2024.rapidFire.draw")}</ReactMarkdown>
        </container-div>
      </RapidFire>

      <Rules>
        <container-div>
        {/* <RULES></RULES> */}
          <Tabs defaultActiveKey="game-description" id="chaseTheAceRulesTabs" fill >
            {/* Tab 1: Game Description */}
            <Tab eventKey="game-description" title={t("chaseTheAce2024.rules.tab1.title")}>
              <ReactMarkdown className="title">{t("chaseTheAce2024.rules.tab1.title")}</ReactMarkdown>
              <ReactMarkdown className="subtitle">{t("chaseTheAce2024.rules.tab1.chaseTheAce.subtitle")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.3")}</ReactMarkdown></li>
                <li>
                  <ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4")}</ReactMarkdown>
                  <ol type="a">
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4a")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4b")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4c")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4d")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4e")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4f")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4g")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4h")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4i")}</ReactMarkdown></li>
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.chaseTheAce.list.4j")}</ReactMarkdown></li>
                  </ol>
                </li>
              </ol>

              <ReactMarkdown className="subtitle">{t("chaseTheAce2024.rules.tab1.progressive.subtitle")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.1")}</ReactMarkdown></li>
                <li>
                  <ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.1a")}</ReactMarkdown>
                  <ol type="i">
                    <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.1ai")}</ReactMarkdown></li>
                  </ol>
                </li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.1b")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.1c")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.1d")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.3")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.3a")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.progressive.4")}</ReactMarkdown></li>
              </ol>

              <ReactMarkdown className="subtitle">{t("chaseTheAce2024.rules.tab1.rapidFire.subtitle")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.3")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.3a")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.3b")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.3c")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab1.rapidFire.3d")}</ReactMarkdown></li>
              </ol>
            </Tab>

            {/* Tab 2: How to qualify */}
            <Tab eventKey="how-to-qualify" title={t("chaseTheAce2024.rules.tab2.title")}>
              <ReactMarkdown className="title">{t("chaseTheAce2024.rules.tab2.title")}</ReactMarkdown>
              <ReactMarkdown className="subtitle">{t("chaseTheAce2024.rules.tab2.chaseTheAce.subtitle")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.3")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.4")}</ReactMarkdown></li>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.4-header-1")}</ReactMarkdown></th>
                      <th><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.4-header-2")}</ReactMarkdown></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.4-body-1")}</ReactMarkdown></td>
                      <td><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.4-body-2")}</ReactMarkdown></td>
                    </tr>
                  </tbody>
                </Table>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab2.chaseTheAce.5")}</ReactMarkdown></li>
              </ol>
            </Tab>

            {/* Tab 3: Draw Process */}
            <Tab eventKey="draw-process" title={t("chaseTheAce2024.rules.tab3.drawProcess.title")}>
            <ReactMarkdown className="title">{t("chaseTheAce2024.rules.tab3.drawProcess.title")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.2")}</ReactMarkdown></li>
                <ol type="a">
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.a")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.b")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.c")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.d")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.e")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.f")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.g")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.h")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.i")}</ReactMarkdown></li>            <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.j")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.k")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.drawProcess.l")}</ReactMarkdown></li>
                </ol>
              </ol>
              <ReactMarkdown className="subtitle">{t("chaseTheAce2024.rules.tab3.rapidFire.subtitle")}</ReactMarkdown>
              <ReactMarkdown>{t("chaseTheAce2024.rules.tab3.rapidFire.p")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.rapidFire.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.rapidFire.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.rapidFire.3")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.rapidFire.4")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab3.rapidFire.5")}</ReactMarkdown></li>
              </ol>
            </Tab>

            {/* Tab 4: Eligibility */}
            <Tab eventKey="eligibility" title={t("chaseTheAce2024.rules.tab4.title")}>
             <ReactMarkdown className="title">{t("chaseTheAce2024.rules.tab4.title")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab4.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab4.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab4.3")}</ReactMarkdown></li>
              </ol>
            </Tab>

            {/* Tab 5: General Rules */}
            <Tab eventKey="general-rules" title={t("chaseTheAce2024.rules.tab5.generalRules.title")}>
            <ReactMarkdown className="title">{t("chaseTheAce2024.rules.tab5.generalRules.title")}</ReactMarkdown>
              <ol>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab5.generalRules.1")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab5.generalRules.2")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab5.generalRules.3")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab5.generalRules.4")}</ReactMarkdown></li>
                <li><ReactMarkdown>{t("chaseTheAce2024.rules.tab5.generalRules.5")}</ReactMarkdown></li>
              </ol>
              <ReactMarkdown>{t("chaseTheAce2024.rules.tab5.generalRules.p")}</ReactMarkdown>
            </Tab>
          </Tabs>
        </container-div>
      </Rules>
    </ChaseTheAceDiv>
  );
};

const ChaseTheAceDiv = styled.div`
  background-color: var(--mutedwhite);
  color: var(--lightblack);
  text-align: center;
  .hero_text_container {
    font-weight: 700;
    h1 {
      font-weight: 900;
      font-size: 3rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .hero-content {
      margin-top: 1rem;
      font-size: 32px;
    }
    .hero-box {
      background-color: var(--red);
      padding: 15px;
      margin-bottom: 1rem;
      p {
        font-size: 35px;
        line-height: 45px;
        width: 100%;
        color: #fff;
        text-shadow: none;
        margin: 0;
        &:last-child {
          font-size: 50px;
          margin: 0.5rem 0;
        }
      }
    }
  }
  h2 {
    font-size: 2.5rem;
    margin: 1rem 0;
    text-transform: uppercase;
    font-weight: 900;
  }
`;

const IntroDiv = styled.div`
  padding: 2rem 0;
  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 1.5rem;;
  }
`;

const ProgressiveDiv = styled.div`
  padding: 2rem 0;
  background: var(--lightblack);
  color: var(--white);
  position: relative;
  &:before {
    content: "";
    background-image: url(${(props) => props.bgImage});
    background-position: center center;
    opacity: 0.4;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  p {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  .row {
    padding: 2rem 0;
  }
  .row, h2 {
    position: relative;
    z-index: 1;
  }
  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  img {
    max-width: 280px;
  }

`;

const RapidFire = styled.div`
  padding: 2rem 0;
`;

const Rules = styled.div`
  padding: 2rem 0;
  background: var(--lightblack);
  color: var(--white);
  text-align: left;
  .nav-tabs {
    margin-bottom: 0!important;
    .nav-link {
      color: #fff;
      border-bottom-color: #ab7c1d;
      &:hover {
        border-color: #ab7c1d;
        background-color: #d79c23;
      }
    }
    background-color: #232426;
    font-weight: 700;
    .active {
      color: #fff !important;
      cursor: default;
      background-color: #d79c23;
      border: 0px solid #ab7c1d;
    }
  }
  .tab-content {
    padding: 2rem;
    background: var(--white);
    color: var(--lightblack);
    .title p {
      font-size: 2rem;
      font-weight: 700;
    }
    .subtitle p {
      font-size: 1.5rem;
      font-weight: 700;
    }
    table {
      text-align: center;
      th {
        background-color: var(--lightblack);
        color: var(--white);
      }
      p { margin: 0; }
      margin: 1rem 0;
    }
  }
`;

export default ChaseTheAce2024;
