import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import TournamentHero from "src/components/Poker/Tournaments/TournamentHero";
import NavTabs from "src/components/Poker/Tournaments/NavTabs";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useTranslation } from "react-i18next";
import { getTournamentData } from "src/utils/tournamentUtils";
import TournamentContent from "./TournamentContent";
import { leaderboards } from "src/api/poker";
import CoverageAndResultsBanner from "src/components/Poker/Tournaments/CoverageAndResultsBanner";
import Page404 from "src/pages/Page404";
import Hero from "src/components/Hero/Hero";

const TournamentDetails = () => {
  const { tournamentsData, tournamentDataFn } = useHomepage();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { tournamentSlug, tournamentTabSlug } = useParams();
  const tabSlug = tournamentTabSlug || "schedule"; // Default to 'schedule'
  const [tournamentContent, setTournamentContent] = useState(null);
  const [tournamentTabs, setTournamentTabs] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const lng = i18n.language;
  const [isNotFound, setIsNotFound] = useState(false);

  // Fetch tournament data when slugs or language change
  useEffect(() => {
    const fetchTournamentData = async () => {
      try {
        const data = await getTournamentData(location, i18n);
        if (data?.tournamentData) {
          setTournamentContent(data?.tournamentData);
          setTournamentTabs(data?.tabsData);
        } else {
          setIsNotFound(true);
        }
        setTournamentTabs(data.tabsData);
        // Update global state with event info
        data?.tournamentData?.eventInfo
          ? tournamentDataFn(
              data?.tournamentData?.eventInfo?.startDate,
              data?.tournamentData?.eventInfo?.endDate,
              data?.tournamentData?.eventInfo?.key,
              lng
            )
          : tournamentDataFn([]);
      } catch (error) {
        console.error("Failed to load tournament content:", error);
        setIsNotFound(true);
      }
    };

    fetchTournamentData();
  }, [location, lng, tournamentContent]); // Remove `chipCountSlug` and `location` from dependencies

  // Fetch leaderboard data once when the tournament content is available
  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const tab = tournamentContent?.leaderboardTabs
          ? Object.values(tournamentContent.leaderboardTabs).find(
              (tab) => tab.link === location.pathname
            )
          : null;

        if (tab && tab.key) {
          const data = await leaderboards(tab.key);
          setLeaderboardData(data);
        }
      } catch (error) {
        console.error("Failed to fetch leaderboard data:", error);
      }
    };

    if (tournamentContent?.leaderboardTabs) {
      fetchLeaderboardData();
    }
  }, [tournamentContent, location.pathname]);

  if (!tournamentContent && !isNotFound) {
    return null;
  }
  if (isNotFound) {
    return <Page404 />;
  }
  const { hero, bgColor, dropDownBgColor, textColor, leaderboardTabs } =
    tournamentContent;
  return (
    <Div>
      <Hero 
        image={hero?.image}
        bgImage={hero?.bgImage}
        imageAlt={hero?.imageAlt}
        bgColor={bgColor || "#000"}
        description={hero?.description}
        descriptionBgColor={hero?.descriptionBgColor || "#00000080"}
        fluidImage={ hero?.image ? false : true }
      />
      {/* <TournamentHero
        image={hero?.image}
        imageAlt={hero?.imageAlt || "Tournament hero image"}
        bgColor={bgColor || "#000"}
        dropDownBgColor={dropDownBgColor || "#000"}
        logo={hero?.logo}
        fluidImage
      /> */}
      {tournamentContent?.content?.hotels && (
        <CoverageAndResultsBanner
          label={t("poker.tournaments.placeToStayBanner")}
          resultsLink={t("routes.hotels")}
          hotels={tournamentContent?.content?.hotels}
        />
      )}
      {tournamentTabs && (
        <NavTabs
          tabLinks={Object.values(tournamentTabs)?.map((tab) => ({
            label: tab?.label,
            link: tab?.link,
            type: tab?.type,
            options: tab?.options,
          }))}
          bgColor={bgColor ?? "#000"}
          textColor={textColor ?? "#fff"}
        />
      )}
      <TournamentContent
        tournamentContent={tournamentContent}
        tournamentsData={tournamentsData}
        leaderboardData={leaderboardData}
        leaderboardTabs={leaderboardTabs}
      />
    </Div>
  );
};

const Div = styled.div`
  --topspacing: 110px;
  --gradientheight: 40px;

  background-color: var(--white);
  text-align: center;
  overflow-x: hidden;
  height: auto;
  .hero_text_container {
    padding: 0;
  }
  .hero_container.fluid {
    min-height: 440px;
    @media (min-width: 768px) and (max-width: 991px){
      --topspacing: 40px;
      --gradientheight: 20px;
    }
    @media (min-width: 768px) {
      top: var(--topspacing);
      margin-bottom: var(--topspacing);
      /*
        This gives a black bar a the top, behind the nav
        and 40px of gradient overlying the banner image
      */
      &:before {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 71.43%,
          rgba(0, 0, 0, 0) 100%
        );
        content: "";
        height: calc(var(--topspacing) + var(--gradientheight));
        top: calc(-1 * var(--topspacing));
        position: relative;
      }
    }
  }
`;

export default TournamentDetails;
