import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { useErs } from "src/context/ers/ers.provider";
import { formatAmount } from "src/utils/formatters";
import ReactMarkdown from "react-markdown";
import TitleSection from "src/components/TitleSection/TitleSection";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import CashGamesInfo from "src/components/Poker/CashGamesInfo";
import EventFinished from "src/components/EventFinished";

const HighHandJanuary2025 = () => {
  const { i18n, t } = useTranslation();
  // const { data, fetchData } = useErs();
  const lng = i18n.language;
  const [key, setKey] = useState("highHand");
  const navigate = useNavigate();

  // useEffect(() => {
  //   fetchData("mystery-high-hand");
  // }, [fetchData, i18n.language]);

  // Function to add a dollar sign to the amount
  const addDollarSign = (amount) => formatAmount(amount, lng);

  // const mysteryHighHands = data["mystery-high-hand"] || [];
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      setKey("anniversary");
    } else {
      setKey("highHand");
    }
  }, [location.hash]);
  // const handleTabChange = (key) => {
  //   if (key === "highHand") {
  //     navigate(t("routes.highHandJanuary2025"));
  //   } else {
  //     navigate(t("routes."));
  //   }
  // };
  return (
    <MysteryHighHandDiv>
      <Hero
        bgImage="promotions/poker/high_hand_december2025/poker_carousel_HH_BG-v2.jpg"
        image={`promotions/poker/high_hand_december2025/home_carousel_HH_${i18n.language}.png`}
        imageAlt="High Hand January 2025"
      />
      <EventFinished scheduleKeyName="highHandJanuary2025" eventType="promotion" />
      <container-div>
        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => handleTabChange(k)}
          className="gap-2 mt-5"
          variant="pills"
        >
          <Tab
            eventKey="highHand"
            title={t("poker.mystery-high-hand-january-2025.highHand.tabLabel")}
            className="high-hand"
          > */}
        <div className="high-hand">
          <div>
            <TitleSection
              as={"h2"}
              title={t("poker.mystery-high-hand-january-2025.highHand.title")}
              text={t("poker.mystery-high-hand-january-2025.highHand.text")}
            />
            <div>
              <ReactMarkdown>
                {t("poker.mystery-high-hand-january-2025.highHand.smallInfo")}
              </ReactMarkdown>
              <CashGamesInfo />
            </div>
          </div>
          <div>
            <h4>
              {t(
                "poker.mystery-high-hand-january-2025.highHand.generalRules.title"
              )}
            </h4>
            <ReactMarkdown>
              {t(
                "poker.mystery-high-hand-january-2025.highHand.generalRules.list",
                {
                  returnObjects: false,
                }
              )}
            </ReactMarkdown>
          </div>
        </div>
        {/* </Tab> */}
        {/* <Tab
            eventKey="anniversary"
            title={t(
              "poker.mystery-high-hand-january-2025.anniversary.tabLabel"
            )}
            className="high-hand"
          >
            <div>
              <TitleSection
                as={"h2"}
                title={t(
                  "poker.mystery-high-hand-january-2025.anniversary.title"
                )}
                text={t(
                  "poker.mystery-high-hand-january-2025.anniversary.text"
                )}
              />
              <div>
                <ReactMarkdown>
                  {t(
                    "poker.mystery-high-hand-january-2025.anniversary.smallInfo"
                  )}
                </ReactMarkdown>
                <CashGamesInfo />
              </div>
            </div>
            <div>
              <h4>
                {t(
                  "poker.mystery-high-hand-january-2025.anniversary.generalRules.title"
                )}
              </h4>
              <ReactMarkdown>
                {t(
                  "poker.mystery-high-hand-january-2025.anniversary.generalRules.list",
                  {
                    returnObjects: false,
                  }
                )}
              </ReactMarkdown>
            </div>
          </Tab> */}
        {/* </Tabs> */}
      </container-div>
    </MysteryHighHandDiv>
  );
};
const MysteryHighHandDiv = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;
  .hero_text_container {
    display: none;
  }
  .high-hand {
    margin: 20px 0;
    & > div:first-child {
      width: 100%;
      max-width: 1440px;
      height: auto;
      margin: 0 auto;
      color: var(--white);
      & > h3 {
        font-weight: 700;
        font-size: 20px;
        margin-top: 0px;
        padding-bottom: 20px;
        text-transform: uppercase;
      }
      & > div {
        width: 100%;
        & > h4 {
          background: rgb(170, 11, 11);
          background: linear-gradient(
            45deg,
            rgba(170, 11, 11, 1) 0%,
            rgba(0, 0, 0, 1) 50%,
            rgba(170, 11, 11, 1) 100%
          );
          color: var(--white);
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          padding: 15px;
          max-width: 500px;
          font-size: 30px;
          margin: 20px auto;
          border-radius: 5px;
          line-height: 25px;
        }
        & > table {
          width: 100%;
          max-width: 100%;
          margin: 20px 0px 40px 0px;
          background: var(--black);
          color: var(--white);
          border: 1px solid var(--lightblack);
          border-spacing: 0;
          border-collapse: collapse;
          & > thead {
            & > tr {
              & > th {
                text-align: center;
                font-size: 20px;
                font-weight: 400;
                width: 50%;
                background-color: #333;
                border: 1px solid #222;
                padding: 8px;
                line-height: 1.42857143;
              }
            }
          }
          & > tbody {
            & > tr {
              & > td:first-child {
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                width: 50%;
                color: var(--white);
                background-color: #230202;
                border: 1px solid #222;
                padding: 8px;
                line-height: 1.42857143;
              }
              & > td:last-child {
                background-color: #aa0b0b;
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                width: 50%;
                color: #fff;
                border: 1px solid #222;
                padding: 8px;
                line-height: 1.42857143;
              }
            }
          }
        }
        & > p {
          margin: 0 0 10px;
          font-size: 18px;
          color: #aaa;
          text-align: center;
        }
      }
    }
    & > div:nth-child(2) {
      background-color: #111;
      width: 100%;
      max-width: 1440px;
      margin: 40px auto 0 auto;
      padding: 2rem 1rem;
      & > h4 {
        font-weight: 700;
        color: #999;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        font-size: 30px;
        margin: 5px 0 10px 0;
      }
      & > ul {
        margin-top: 0;
        margin-bottom: 10px;
        list-style: decimal;
        & > li {
          font-size: 16px;
          color: #999;
          ul {
            list-style: lower-alpha;
            ul {
              list-style: lower-roman;
            }
          }
        }
      }
    }
  }
  .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 1rem;
    background-color: ${(props) =>
      props.bgColor || "var(--mutedred)"} !important;
    color: ${(props) => props.textColor || "white"};
    border-right: 1px solid
      ${(props) => props.borderColor || "var(--lightblack)"};
    border-bottom: 1px solid
      ${(props) => props.borderColor || "var(--lightblack)"};
    &:hover,
    &.active,
    &:focus {
      background-color: ${(props) => props.active || "var(--red)"} !important;
      color: ${(props) => props.textColor || "var(--white)"};
    }
  }
  .nav-pills {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .nav-link {
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    .nav-pills {
      grid-template-columns: 1fr;
    }
    .high-hand {
      & > div:first-child {
        & > img {
          width: 100%;
        }
        & > div {
          & > h2 {
            padding: 10px;
            font-size: 20px;
            line-height: 1.35em;
          }
        }
      }
    }
  }
`;
export default HighHandJanuary2025;
