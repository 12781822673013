import { useEffect } from "react";
import { useAccount } from "../context/account/account.provider";
import Cookies from "js-cookie";

const useCheckTokenExpiration = () => {
  const { logout, tokenExpiryFn, tokenExpiry } = useAccount();

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const access_token = Cookies.get("access_token");

      // Test modal (comment out when not testing)
      // const isTestingModal = true; // Set to false in production
      // if (isTestingModal && access_token) {
      //   const timeLeft = 15;
      //   console.log("Testing modal trigger with simulated timeLeft:", timeLeft);
      //   if (timeLeft <= 15 && !tokenExpiry) {
      //     tokenExpiryFn(true);
      //   }
      //   return;
      // }

      if (!access_token) return;

      try {
        const payload = JSON.parse(atob(access_token.split(".")[1]));
        const expirationTime = payload.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now(); // Current time in milliseconds
        const timeLeft = Math.floor((expirationTime - currentTime) / 1000); // Time left in seconds

        if (timeLeft > 0 && timeLeft <= 125) {
          if (timeLeft <= 65) {
            if (!tokenExpiry) {
              tokenExpiryFn(true); // Show modal when less than 65 seconds
            }
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        logout();
      }
    };

    checkTokenExpiration();

    const tokenCheckInterval = setInterval(checkTokenExpiration, 60000); // Check every 1 minute

    return () => {
      clearInterval(tokenCheckInterval); // Cleanup interval on unmount
    };
  }, [tokenExpiry]);

  return null; // Hooks don't render anything
};

export default useCheckTokenExpiration;
