import styled, { css } from 'styled-components';
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Button from "src/components/Button/Button";
import EventFinished from "src/components/EventFinished";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import RegisterForm from "src/components/Auth/RegisterForm";
import SignInSection from "src/components/Auth/SignInSection";
import RegisterFAQs from "src/pages/auth/RegisterFAQs";
import AuthServiceInfo from "src/components/AuthServiceInfo";
import HospitalityUnit from "src/components/DiningAndLounges/HospitalityUnit";
import DiningCarousel from "src/components/Restaurants/DiningCarousel";
import React from "react";

const ChineseNewYear2025Promo = () => {
  const { t, i18n } = useTranslation();
  const { promoCode } = useParams(); // Extract `:promoCode` dynamic parameter
  const validCodes = ["cnywc", "cnyrb", "cnymch"]
  const validatedPromoCode = promoCode && validCodes.includes(promoCode) ? promoCode : null;

  const restaurantSlides = [
    {
      id: 9,
      title: "DD",
      image: "promotions/cny-2025/drunken-dragon-overhead.jpg",
        url: t("diningAndLounges.dining.drunkenDragon.link"),
    },
    {
      id: 10,
      title: "ETC",
      image: "promotions/cny-2025/etc-tomahawk.jpg",
        url: t("diningAndLounges.dining.etc.link"),
    },
    {
      id: 11,
      title: "slide1",
      image: "restaurants/slide1.jpg",
        url: t("diningAndLounges.dining.drunkenDragon.link"),
    },
    {
      id: 33,
      title: "slide3",
      image: "restaurants/slide3.jpg",
        url: t("diningAndLounges.lounges.babel.link"),
    },
    {
      id: 44,
      title: "slide4",
      image: "restaurants/slide4.jpg",
        url: t("diningAndLounges.dining.etc.link"),
    },
    {
      id: 66,
      title: "slide6",
      image: "restaurants/slide6.jpg",
        url: t("diningAndLounges.dining.etc.link"),
    },
    // {
    //   id: 77,
    //   title: "slide7",
    //   image: "restaurants/slide1.jpg",
    //     url: t("diningAndLounges.dining.drunkenDragon.link"),
    // },
    // {
    //   id: 99,
    //   title: "slide9",
    //   image: "restaurants/slide3.jpg",
    //     url: t("diningAndLounges.lounges.babel.link"),
    // },
    // {
    //   id: 111,
    //   title: "slide10",
    //   image: "restaurants/slide4.jpg",
    //     url: t("diningAndLounges.dining.etc.link"),
    // },
  ];

  return (
    <Div>
      <Hero
        bgImage="promotions/cny-2025/2560x1440_CNY_Carousels-Bg.jpg"
        image={`promotions/cny-2025/1600x800_CNY_Hero-Carousel_Logo_CN.png`}
      />
      <EventFinished
        scheduleKeyName="chineseNewYear2025"
        eventType="promotion"
      />

      <MainContentDiv
        // bgImage={useResponsiveImageUrl(
        //   "entertainment/variety/new-years-eve-2025/nye-2024-background.jpg"
        // )}
      >
        <container-div>
          <HeaderBanner
            bgImage={useResponsiveImageUrl(
              "promotions/cny-2025/promo-code/BG_Pattern.jpg"
            )}>
            <div className="inner">
                <ReactMarkdown>
                  {t("chineseNewYear2025.promoCode.introBanner.h1")}
                </ReactMarkdown>
              <Button
                label={t("chineseNewYear2025.promoCode.introBanner.buttonLabel")}
                width='xwide'
                href="#registration"
              />
            </div>
          </HeaderBanner>
          <IntroContainer
            className="padded"
            bgImage={useResponsiveImageUrl(
              "promotions/cny-2025/promo-code/BG_Snack_2.png"
            )}>
            <Row className="g-5 d-reverse mb-4">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  className="radius-mask"
                  src={"promotions/cny-2025/promo-code/PG-Building.jpg"}
                  alt={t(
                    "chineseNewYear2025.promoCode.pgBuilding.alt"
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t("chineseNewYear2025.promoCode.title")}
                  text={t("chineseNewYear2025.promoCode.subtitle.text")}
                  borderColor="#c54132"
                  dark={false}
                  className="celebrate py-0"
                />
              </Col>
            </Row>

            <Row className="g-4 mb-0">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  className="radius-mask"
                  src={"promotions/cny-2025/1600x1000_lion-dance.jpg"}
                  alt={t(
                    "chineseNewYear2025.promoCode.lionDance.alt"
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t("chineseNewYear2025.promoCode.lionDance.title")}
                  text={t("chineseNewYear2025.promoCode.lionDance.subtitle")}
                  dark={false}
                  className="cny-special-title py-0"
                />
                <p>
                  {t("chineseNewYear2025.promoCode.lionDance.description")}
                </p>
                <Announcement>
                  <ReactMarkdown>
                  {t("chineseNewYear2025.promoCode.lionDance.dates")}
                  </ReactMarkdown>
                  <ReactMarkdown>
                  {t("chineseNewYear2025.promoCode.lionDance.times")}
                  </ReactMarkdown>
                </Announcement>
                <p>
                  {t("chineseNewYear2025.promoCode.lionDance.details")}
                </p>
              </Col>
            </Row>

            {/* <Hero
              bgImage="promotions/cny-2025/low-angle-asian-paper-lanterns.jpg"
              buttonSettings={null}
              darkenBg={0.5}
              description="Some text about what the promo code will do?"
              descriptionBgColor="#20010559"
              mainHeroText={`Code automatically applied on registration \n## ${validatedPromoCode}`}
              className="inline-promo-code-hero mb-5"
              overlayVideoSrc="/videos/bbj-overlay.mp4"
            />
             */}
            <Divider>
              <ResponsiveImage
                src={"promotions/cny-2025/promo-code/Divider.png"}
              />
            </Divider>
          </IntroContainer>

          <PlainContainer className="padded extra-top">
            <SubTitleSection
              subTitle={t("chineseNewYear2025.promoCode.liveMusic.title")}
              text={t("chineseNewYear2025.promoCode.liveMusic.subtitle")}
              className="cny-special-title text-center"
              dark={false}
            />

            <Row>
              <Col xs={0} sm={2}></Col>
              <Col xs={12} sm={8}>
                <p className="text-center">
                  {t("chineseNewYear2025.promoCode.liveMusic.description")}
                </p>
              </Col>
              <Col xs={0} sm={2}></Col>
            </Row>
            <Row xs={1} sm={2} className="band pt-4">
              <Col>
                <ResponsiveImage
                  className="radius-mask"
                  src={"promotions/cny-2025/promo-code/Black-Currant.jpg"}
                  alt={t("chineseNewYear2025.promoCode.liveMusic.schedule.blackCurrant.title")}
                />
                <h3>{t("chineseNewYear2025.promoCode.liveMusic.schedule.blackCurrant.title")}</h3>
                <Announcement className="text-center">
                  <p>{t("chineseNewYear2025.promoCode.liveMusic.schedule.blackCurrant.dates")}</p>
                  <p>{t("chineseNewYear2025.promoCode.liveMusic.schedule.blackCurrant.times")}</p>
                </Announcement>
                <ReactMarkdown>{t("chineseNewYear2025.promoCode.liveMusic.schedule.blackCurrant.description")}</ReactMarkdown>
              </Col>
              <Col>
                <ResponsiveImage
                  className="radius-mask"
                  src={"promotions/cny-2025/promo-code/Mint-Night.jpg"}
                  alt={t("chineseNewYear2025.promoCode.liveMusic.schedule.blackCurrant.title")}
                />
                <h3>{t("chineseNewYear2025.promoCode.liveMusic.schedule.mintNight.title")}</h3>
                <Announcement className="text-center">
                  <p>{t("chineseNewYear2025.promoCode.liveMusic.schedule.mintNight.dates")}</p>
                  <p>{t("chineseNewYear2025.promoCode.liveMusic.schedule.mintNight.times")}</p>
                </Announcement>
                <ReactMarkdown>{t("chineseNewYear2025.promoCode.liveMusic.schedule.mintNight.description")}</ReactMarkdown>
              </Col>
            </Row>
            <Divider>
              <ResponsiveImage
                src={"promotions/cny-2025/promo-code/Divider.png"}
              />
            </Divider>
          </PlainContainer>

          <PlainContainer
            className="prizes padded extra-top"
            bgImage={useResponsiveImageUrl(
              "promotions/cny-2025/promo-code/BG_Pattern_03.jpg"
            )}
          >
            <Row>
              <Col xs={0} sm={2}></Col>
              <Col xs={12} sm={8}>
                <SubTitleSection
                  subTitle={t("chineseNewYear2025.promoCode.prizes.title")}
                  className="cny-special-title text-center"
                  dark={false}
                  />
                  <h4 className="text-center mb-4">
                    {t("chineseNewYear2025.promoCode.prizes.description")}
                  </h4>
              </Col>
              <Col xs={0} sm={2}></Col>
            </Row>

            <Announcement
              bgImage={useResponsiveImageUrl(
                "promotions/cny-2025/promo-code/BG_Pattern_02.png"
              )}
              className="text-center red-envelopes"
            >
              <Row className="d-reverse">
                <Col xs={12} sm={4}>
                  <ResponsiveImage
                    src="promotions/cny-2025/promo-code/Red_evelope.png"
                    alt={t("chineseNewYear2025.promoCode.prizes.redEnvelopes.title")}
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <SubTitleSection
                    subTitle={t("chineseNewYear2025.promoCode.prizes.redEnvelopes.title")}
                    className="cny-special-title"
                    dark={false}
                    />
                  <p>
                    {t("chineseNewYear2025.promoCode.prizes.redEnvelopes.description")}
                  </p>
                </Col>
              </Row>
            </Announcement>

            <Announcement
              bgImage={useResponsiveImageUrl(
                "promotions/cny-2025/promo-code/BG_Pattern.jpg"
              )}
              className="text-center prize-draw red-envelopes"
            >
              <SubTitleSection
                subTitle={t("chineseNewYear2025.promoCode.prizes.grandPrizeDraw.title")}
                text={t("chineseNewYear2025.promoCode.prizes.grandPrizeDraw.details")}
                className="cny-special-title"
              />
              <Row xs={2} className="prize-types">
                <Col>
                  <ReactMarkdown>{t("chineseNewYear2025.promoCode.prizes.grandPrizeDraw.prize1")}</ReactMarkdown>
                </Col>
                <Col>
                  <ReactMarkdown>{t("chineseNewYear2025.promoCode.prizes.grandPrizeDraw.prize2")}</ReactMarkdown>
                </Col>
              </Row>
              <ResponsiveImage
                src="promotions/cny-2025/promo-code/Coin.png"
              />
            </Announcement>

            <h3 className='prizes-cta text-center my-5'>
              {t("chineseNewYear2025.promoCode.prizes.cta")}
            </h3>

            <Announcement className='winners mb-5'>
              <ReactMarkdown className='text-center'>
                {t("chineseNewYear2025.promoCode.prizes.announcement")}
              </ReactMarkdown>
            </Announcement>

            <Divider>
              <ResponsiveImage
                src={"promotions/cny-2025/promo-code/Divider.png"}
              />
            </Divider>
          </PlainContainer>

          <PlainContainer
            className="registration padded extra-top" id="registration">
            <Row xs={1} md={2}>
              <Col className="p-0 reg-form-cont">
                <RegisterForm promoCode={validatedPromoCode} />
                <SignInSection />
              </Col>
              <Col className="px-2">
                <RegisterFAQs />
                <AuthServiceInfo />
              </Col>
            </Row>
            <Divider>
              <ResponsiveImage
                src={"promotions/cny-2025/promo-code/Divider.png"}
              />
            </Divider>
          </PlainContainer>

          <PlainContainer
            className="hospitality padded extra-top">
            <div className="radius-mask">
              <DiningCarousel slides={restaurantSlides} />
            </div>

            <SubTitleSection
              subTitle={t("chineseNewYear2025.promoCode.dining.title")}
              text={t("chineseNewYear2025.promoCode.dining.description")}
              className="cny-special-title text-center mt-2 pb-1"
              dark={false}
            />

            <HospitalityUnit
              key={t("diningAndLounges.dining.drunkenDragon.name")}
              name={t("diningAndLounges.dining.drunkenDragon.name")}
              imgSrc="promotions/cny-2025/promo-code/DD.jpg"
              imgAlt={t("diningAndLounges.dining.drunkenDragon.imgAlt")}
              logoSrc={t("diningAndLounges.dining.drunkenDragon.logoSrc")}
              logoAlt={t("diningAndLounges.dining.drunkenDragon.logoAlt")}
              title=""
              text={t("chineseNewYear2025.promoCode.dining.restaurants.drunkenDragon.description")}
              link={t("diningAndLounges.dining.drunkenDragon.link")}
              buttons={t("chineseNewYear2025.promoCode.dining.restaurants.drunkenDragon.buttons", {
                returnObjects: true,
              })}
              imageSide="left"
            />
            <HospitalityUnit
              key={t("diningAndLounges.dining.etc.name")}
              name={t("diningAndLounges.dining.etc.name")}
              imgSrc="promotions/cny-2025/promo-code/ETC.jpg"
              imgAlt={t("diningAndLounges.dining.etc.imgAlt")}
              logoSrc={t("diningAndLounges.dining.etc.logoSrc")}
              logoAlt={t("diningAndLounges.dining.etc.logoAlt")}
              title=""
              text={t("chineseNewYear2025.promoCode.dining.restaurants.etc.description")}
              link={t("diningAndLounges.dining.etc.link")}
              buttons={t("chineseNewYear2025.promoCode.dining.restaurants.etc.buttons", {
                returnObjects: true,
              })}
              imageSide="right"
            />

            {/* <HospitalityUnit
              key={t("diningAndLounges.lounges.babel.name")}
              name={t("diningAndLounges.lounges.babel.name")}
              imgSrc={t("diningAndLounges.lounges.babel.imgSrc")}
              imgAlt={t("diningAndLounges.lounges.babel.imgAlt")}
              logoSrc={t("diningAndLounges.lounges.babel.logoSrc")}
              logoAlt={t("diningAndLounges.lounges.babel.logoAlt")}
              title={t("diningAndLounges.lounges.babel.title")}
              text={t("chineseNewYear2025.promoCode.dining.restaurants.babel.description")}
              text={t("diningAndLounges.lounges.babel.text")}
              link={t("diningAndLounges.lounges.babel.link")}
              buttons={t("diningAndLounges.lounges.babel.buttons", {
                returnObjects: true,
              })}
              imageSide="left"
            /> */}


            <Row>
              <Col xs={0} sm={2}></Col>
              <Col xs={12} sm={8}>
                <p className="text-center mb-5">
                  {t("chineseNewYear2025.promoCode.dining.outro")}
                </p>
              </Col>
              <Col xs={0} sm={2}></Col>
            </Row>


            <ResponsiveImage
              className="radius-mask mb-5"
              src={`promotions/dining/surf-and-turf/Secondary-LP-Image-2400x1600-surf-turf-${i18n.language}.jpg`}
              alt={t("poker.pokerNewYearsEve2025.content.items.ring.title")}
            />

            <SubTitleSection
              subTitle={t("chineseNewYear2025.promoCode.bistroSpecial.title")}
              className="cny-special-title text-center"
              dark={false}
            />
            
            <p>
              {t("chineseNewYear2025.promoCode.bistroSpecial.description")}
            </p>

            <center-content>
              <Button
                label={t("chineseNewYear2025.promoCode.discover")}
                href={t("routes.surfAndTurf2024")}
                width='xwide'
              />
            </center-content>

            <Divider>
              <ResponsiveImage
                src={"promotions/cny-2025/promo-code/Divider.png"}
              />
            </Divider>
          </PlainContainer>

          <PlainContainer
            className="hospitality padded extra-top">


            <SubTitleSection
              subTitle={t("chineseNewYear2025.promoCode.conclusion.title")}
              className="cny-special-title text-center"
              dark={false}
            />
            <ReactMarkdown className="text-center mt-2 mb-5">
              {t("chineseNewYear2025.promoCode.conclusion.description")}
            </ReactMarkdown>

          </PlainContainer>
        </container-div>
      </MainContentDiv>
    </Div>
  );
};

const goldGradientTextOutline = css`
  background: linear-gradient(0deg, rgba(209,148,72,1) 0%, rgba(248,226,170,1) 100%);
  background-clip: text;
  -webkit-text-stroke: 8px transparent;
`;

const Div = styled.div`
  width: 100%;
  height: auto;
  .hero_text_container {
    display: none;
  }
  .title-section {
    p {
      padding: 0;
      font-size: 20px;

    }
  }
  .celebrate {
    h2 {
      text-transform: uppercase;
      font-size: 2.5rem;
      @media (min-width: 768px) {
        padding-right: 30px;
        line-height: 1.2;
      }
    }
  }
  h1, h2, h3 {
    :before {
      display: none!important;
    }
  }
  .cny-special-title {
    h2 {
      font-size: 2rem;
      font-weight: normal;
      &:after {
        display: none;
      }
    }
    p {
      margin-top: 0;
    }
  }
  button {
    border: solid 3px #e9c587
  }

  .prizes-cta {
    font-size: 2rem;
    text-transform: uppercase;
  }
  .winners {
    padding: 1rem;
    p {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 768px) {
    .d-reverse {
      flex-direction: row-reverse;
    }
  }
  .padded {
    padding: 1em 1em 0;
    @media (min-width: 768px) {
      padding: 2em 2em 0;
    }
    @media (min-width: 991px) {
      padding: 3em 3em 0;
    }
    &.extra-top {
      padding-top: calc(3rem + 30px)!important;
    }
  }
`;

const Announcement = styled.div`
  background-color: #bd3f30;
  background-image: ${(props) => `url(${props.bgImage})`};
  border-radius: 10px;
  color: #fff;
  padding: 0.5rem;
  margin: 1rem 0;
  p {
    padding: 0;
    margin: 0;
  }
  &.red-envelopes {
    color: black;
    padding: 1rem 1.5rem;
    .subtitle-section  {
      padding-top: 0;
    }
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
    img {
      padding: 1rem;
      max-width: 227px;
    }
    .col-12 {
      flex-direction: column;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &.prize-draw {
      position: relative;
      padding-bottom: 20%;
      overflow: hidden;
      h2 {
        ${goldGradientTextOutline}
        color: black;
        margin: 1rem;
      }
      .prize-types p {
        font-size: 50px;
        ${goldGradientTextOutline}
        line-height: 55px;
      }
      img {
        width: 110%;
        position: absolute;
        bottom: -5%;
        left: -5%;
        padding: 0;
        max-width: none;
      }
    }
  }
`;

const IntroContainer = styled.div`
  background-image: ${(props) => `url(${props.bgImage})`};
  background-color: #debc8b;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding-bottom: 0!important;
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin: 20px 0; Adjust spacing above and below the divider */
  bottom: -42px;
  width: 80%;
  left: 10%;
  z-index: 5;
  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 3px;
    background-color: #6c5938;
  }

  img {
    max-width: 82px;
    max-height: 81px;
    margin: 0 15px; /* Spacing between the image and the lines */
  }
`;

const PlainContainer = styled.div`
  position: relative;
  background-color: #e4c69a;
  background-image: ${(props) => `url(${props.bgImage})`};
  &.registration {
    input, select {
      border-color: #a56124;
    }
    a:not([type="button"]) {
      color: var(--bluelinklight)!important;
    }
    .accordion {
      border-radius: 20px;
      overflow: hidden;
    }
    .accordion-button,
    .accordion-body p {
      border: none;
      font-size: 0.8rem;
    }
  }
  &.hospitality {
    background-color: #debc8b;
    .unit_container {
      .title-section {
        h3, span {
          display: none;
        }
      }
      .unit-logo {
        margin-top: 1rem;
      }
    }
  }
  .hospitality-image {
    border-radius: 30px;
    overflow: hidden;
  }
`;

const HeaderBanner = styled.div`
  background: rgb(209,148,72);
  background: linear-gradient(0deg, rgba(209,148,72,1) 0%, rgba(248,226,170,1) 100%);
  padding: 8px;
  .inner {
      background-color: #b60000;
      background-image: ${(props) => `url(${props.bgImage})`};
      background-size: cover;

      color: var(--white);
      padding: 1.5rem 1.5rem 1rem;
      text-align: center;
    }
  h1 {
    font-size: 2rem;
    margin: 0;
    text-transform: uppercase;
    color: var(--white);
    strong {
      font-size: 2.5rem;
      white-space: nowrap;
      color: var(--black);
      padding: 0 0.5rem;
      ${goldGradientTextOutline}

      @media (max-width: 768px) {
        font-size: 2rem;
        padding-top: 0.5rem;
        display: inline-block;
      }
    }
  }
  a {
    border: solid 4px #d8a25a;
  }
`;

const MainContentDiv = styled.div`
  /* background: ${(props) => `url(${props.bgImage})`}; */
  background-color: #d16656;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: var(--lightblack);
  container-div {
    background: transparent;
    @media (max-width: 768px) {
      padding: 0;
    }
    > container-div {
      padding: 1em;
      background: #ffd289;
      @media (min-width: 768px) {
        padding: 2em;
      }
      @media (min-width: 991px) {
        padding: 3em;
      }
    }
  }

  .radius-mask {
    border-radius: 30px;
    overflow: hidden;
  }
  .reg-form-cont {
    background: transparent;
    form {
      background: #ffffff38;
      border-radius: 20px;
      /* background-color: #d16656;
      background-color: transparent; */
      border: 0;
      padding: 1rem;
      .row {
        width: 100%;
      }
      h1, h2, p {
        color: var(--lightblack);
      }
    }
  }
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  text-align: left;
  .unit_container {
    img.unit-logo {
      filter: brightness(0)
    }
    .title-section {
      h3, p {
        color: var(--lightblack);
      }
      a {
        color: var(--bluelinklight);
      }
    }
  }
  .inline-promo-code-hero {
    padding-top: 2rem;
    .main-hero-text {
      h2 {
        margin: 0;
        padding: 0.75rem 1rem 1.5rem;
      }
      p {
        margin: 0;
      }
    }
  }
`;

export default ChineseNewYear2025Promo;
